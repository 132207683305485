import React, { createContext, useState, useEffect, useCallback } from 'react';
import { JWT_TOKEN } from '../../../Constants';
import fetchAPI from '../../../services/fetchAPI';
import ErrorModal from '../components/error-modal';
import { ROUTES } from '../../../Routes';

export const UserContext = createContext([]);

let config = () => {
  return {
    headers: { Authorization: `Bearer ${JWT_TOKEN()}` }
  };
};

const UserContextProvider = props => {
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);

  const clearUser = useCallback(() => {
    setUser({});
  }, []);

  const getUser = useCallback(() => {
    getData().then(
      res => {
        setUser(res);
      },
      error => {
        setError(true);
      }
    );
  }, []);

  const state = { details: user, get: getUser, clear: clearUser };

  const getData = () => {
    return fetchAPI('/employee-overview', config());
  };

  useEffect(() => {
    if (JWT_TOKEN()) getUser();
  }, [getUser]);

  const handleCancel = () => {
    setError(false);
    localStorage.removeItem('token');
    window.location.href = ROUTES.LOGIN;
  };

  const handleOk = () => {
    setError(false);
    getUser();
  };

  return (
    <UserContext.Provider value={state}>
      <ErrorModal error={error} handleCancel={handleCancel} handleOk={handleOk}>
        Wygląda na to, że nie udało się połączyć z serwerem. Spróbuj ponownie,
        a&nbsp;jeśli&nbsp;problem dalej występuje, sprawdź swoje połączenie
        z&nbsp;Internetem.
      </ErrorModal>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;

    .cls-1 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const KnowledgeSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <path
                    className="cls-1"
                    d="M19,18H9a3,3,0,0,1-3-3V5A3,3,0,0,1,9,2H19a3,3,0,0,1,3,3V15A3,3,0,0,1,19,18ZM9,4A1,1,0,0,0,8,5V15a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1Z"
                />
                <path className="cls-1" d="M14,22H5a3,3,0,0,1-3-3V10a1,1,0,0,1,2,0v9a1,1,0,0,0,1,1h9a1,1,0,0,1,0,2Z" />
                <rect className="cls-2" fill="none" width="24" height="24" />
                <path className="cls-1" d="M17,9H11a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Z" />
                <path className="cls-1" d="M15,13H11a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z" />
            </g>
        </g>
    </svg>
)

const KnowledgeIcon = props => <SuperIcon component={KnowledgeSvg} {...props} />

export default KnowledgeIcon

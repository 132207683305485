import React, { useCallback, useContext, useState } from 'react'
import moment from 'moment'
import { TodayButton } from '../../Layout/Legend/CalendarNavigation/CalendarNavigation.components'
import CloseIcon from '../../../assets/icons/CloseIcon'
import { WorkLogsContext } from '../context/WorkLogsContext'
import ChevronRightIcon from '../../../assets/icons/ChevronRightIcon'
import ChevronLeftIcon from '../../../assets/icons/ChevronLeftIcon'
import CommentsContent from './CommentsContent/CommentsContent'
import { Dropdown, Menu } from 'antd'
import {
    ItemContent,
    CommentsWrapper,
    Project,
    ProjectColor,
    ProjectDropdownWrapper,
    DropdownIcon,
    DropdownSpan,
    MenuText,
    Header,
    HeaderButtons,
    StyledLabel,
    CloseButton,
    Navigation,
    PreviousDayButton,
    CurrentDateWrapper,
    DayName,
    DayNumber,
    NextDayButton,
} from './Comments.components'

const menuContent = [
    { name: 'Marketing', value: 'Marketing' },
    { name: 'Sales', value: 'Sales' },
    { name: 'HR', value: 'HR' },
    { name: 'Research i Rozwój', value: 'Research_i_Rozwój' },
]

const Comments = props => {
    const {
        commentsChanged,
        setShowComments,
        setCurrentDate,
        currentDate,
        currentMonthComments: logs,
        projectsForCurrentMonth: projects,
    } = useContext(WorkLogsContext)
    const [showDropdown, setDropdown] = useState(false)

    const handleVisibleChange = flag => {
        setDropdown(flag)
    }

    const goToToday = useCallback(() => {
        setCurrentDate(moment().format('YYYY-MM-DD'))
    }, [setCurrentDate])

    const goToPreviousDay = useCallback(() => {
        setCurrentDate(moment(currentDate).subtract(1, 'day').format('YYYY-MM-DD'))
    }, [currentDate, setCurrentDate])

    const goToNextDay = useCallback(() => {
        setCurrentDate(moment(currentDate).add(1, 'day').format('YYYY-MM-DD'))
    }, [currentDate, setCurrentDate])

    const closeDrawer = useCallback(() => {
        setShowComments(false)
    }, [setShowComments])

    const workLogsForDay = logs.filter(wl => wl.date === currentDate) // POSEGREGOWAĆ LISTĘ ŻEBY PROJECTID = 0 BYŁO JAKO PIERWSZE, O ILE ISTNIEJE
    const projectsForDay = projects.filter(p => p.employeeStartDate <= currentDate && p.employeeEndDate >= currentDate)

    const addTag = e => {
        const project = projectsForDay.find(p => p.name.toLowerCase() === 'inne')
        const currentLog = workLogsForDay.find(wl => wl.projectId === project.id)
        const commentWithTag = `${currentLog.description} ${e.target.value}`
        commentsChanged(currentLog ? currentLog.workedHours : 0.0, currentDate, project, commentWithTag.trimStart())
        setDropdown(false)
    }

    const menu = (
        <Menu>
            {menuContent.map(it => (
                <>
                    <ItemContent key={it.value} onClick={e => addTag(e)} value={`#${it.value}`}>
                        {it.name}
                    </ItemContent>
                </>
            ))}
        </Menu>
    )

    const currentDayComments = projectsForDay.map((p, i) => {
        const log = workLogsForDay.find(wl => wl.projectId === p.id && wl.date === currentDate)
        return (
            <CommentsWrapper>
                <Project>
                    <ProjectColor color={p.projectColor}></ProjectColor> {p.name}
                    {p.name.toLowerCase() === 'inne' && (
                        <ProjectDropdownWrapper>
                            <Dropdown
                                onVisibleChange={handleVisibleChange}
                                visible={showDropdown}
                                overlay={menu}
                                disabled={log ? false : true}
                                trigger={['click']}>
                                <DropdownSpan disabled={log ? false : true}>
                                    <MenuText>Wybierz kategorię</MenuText> <DropdownIcon />
                                </DropdownSpan>
                            </Dropdown>
                        </ProjectDropdownWrapper>
                    )}
                </Project>
                <CommentsContent index={i} selectedDate={currentDate} project={p} currentLog={log}></CommentsContent>
            </CommentsWrapper>
        )
    })

    return (
        <>
            <Header>
                <HeaderButtons>
                    <TodayButton onClick={goToToday} data-cy="today" type="link" tabIndex={-1}>
                        <StyledLabel>Dzisiaj</StyledLabel>
                    </TodayButton>
                    <CloseButton onClick={closeDrawer}>
                        <CloseIcon />
                    </CloseButton>
                </HeaderButtons>
                <Navigation>
                    <PreviousDayButton onClick={goToPreviousDay}>
                        <ChevronLeftIcon />
                    </PreviousDayButton>
                    <CurrentDateWrapper>
                        <DayNumber>{moment(currentDate).format('D')}</DayNumber>
                        <DayName>{moment(currentDate).format('dddd')}</DayName>
                    </CurrentDateWrapper>
                    <NextDayButton onClick={goToNextDay}>
                        <ChevronRightIcon />
                    </NextDayButton>
                </Navigation>
            </Header>
            {currentDayComments}
        </>
    )
}

export default Comments

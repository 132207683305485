import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;

    .cls-2 {
        fill: ${theme.colors.green};
    }
`

const SuccessIconSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="prefix__check_circle_outline-24px"
        width="24"
        height="24"
        viewBox="0 0 24 24">
        <path id="prefix__Path_337" className="cls-1" fill="none" d="M0 0h24v24H0z" data-name="Path 337" />
        <path
            id="prefix__Path_338"
            className="cls-2"
            d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8zm3.88-11.71L10 14.17l-1.88-1.88a1 1 0 0 0-1.41 1.41l2.59 2.59a1 1 0 0 0 1.41 0L17.3 9.7a1 1 0 0 0 0-1.41 1.008 1.008 0 0 0-1.42 0z"
            data-name="Path 338"
        />
    </svg>
)

const SuccessIcon = props => <SuperIcon component={SuccessIconSvg} {...props} />

export default SuccessIcon

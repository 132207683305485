import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    padding-right: 2px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const MenuSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" fill="none" width="24" height="24" />
                <path className="cls-2" d="M20,7H4A1,1,0,0,1,4,5H20a1,1,0,0,1,0,2Z" />
                <path className="cls-2" d="M20,19H4a1,1,0,0,1,0-2H20a1,1,0,0,1,0,2Z" />
                <path className="cls-2" d="M20,13H4a1,1,0,0,1,0-2H20a1,1,0,0,1,0,2Z" />
            </g>
        </g>
    </svg>
)

const MenuIcon = props => <SuperIcon component={MenuSvg} {...props} />

export default MenuIcon

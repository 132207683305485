import React, { useCallback, useEffect, useState } from 'react'
import LogInput from './LogInput/LogInput'
import moment from 'moment'
import '../LogsStyles.css'
import convertNumberToTime from '../../../shared/convertNumberToTime'
import Media from 'react-media'
//import CommentIcon from "../../assets/icons/CommentIcon";
import {
    InputsWrapper,
    MobileLogContentWrapper,
    ProjectNameWrapper,
    ProjectsWrapper,
    LogWrapper,
    Wrapper,
    DailySum,
    HoursWrapper,
} from './LogsInputBox.components'
import WorkLogsIcon from '../../../assets/icons/WorkLogsIcon'

const Inputs = ({ projects, openProjectDrawer, dateValue, logs, ...props }) => {
    let projectInputs
    let inputRefs = []

    const [greyNames, setGreyNames] = useState(false)

    useEffect(() => {
        if (moment(dateValue).isAfter(moment())) {
            setGreyNames(true)
        } else setGreyNames(false)
    }, [dateValue])

    const setRef = ref => {
        inputRefs.push(ref)
    }

    const focusInput = id => {
        if (inputRefs[id]) inputRefs[id].focus()
    }

    if (projects && logs) {
        projectInputs = projects.map((proj, i) => {
            const found = logs.find(wl => wl.projectId === proj.id && wl.date === dateValue)
            if (
                proj.id !== '0' &&
                (moment(dateValue).isAfter(moment(proj.employeeEndDate)) ||
                    moment(dateValue).isBefore(moment(proj.employeeStartDate)))
            ) {
                return <Media query="(max-width: 1199px)" render={() => <></>} />
            } else if (found && found.workedHours !== 0) {
                return (
                    <Media
                        queries={{
                            small: '(max-width: 599px)',
                            medium: '(min-width: 600px) and (max-width: 1199px)',
                            large: '(min-width: 1200px)',
                        }}>
                        {matches => (
                            <>
                                {(matches.small || matches.medium) && (
                                    <InputsWrapper>
                                        <MobileLogContentWrapper
                                            id={i}
                                            onClick={() => focusInput(i)}
                                            isHoliday={props.isHoliday}
                                            weekend={props.weekend || props.holiday}>
                                            <ProjectNameWrapper grey={greyNames}>{proj.name}</ProjectNameWrapper>
                                            <LogWrapper>
                                                <LogInput
                                                    type="number"
                                                    pattern="[0-9]*:?[0-9]*"
                                                    key={`${dateValue}-${proj.id}`}
                                                    borderColor={proj.projectColor}
                                                    bgColor={proj.projectColor}
                                                    value={convertNumberToTime(found.workedHours)}
                                                    date={dateValue}
                                                    project={proj}
                                                    disabled={props.isClosed}
                                                    ref={setRef}
                                                />
                                            </LogWrapper>
                                        </MobileLogContentWrapper>
                                    </InputsWrapper>
                                )}
                                {matches.large && (
                                    <LogInput
                                        type="number"
                                        pattern="[0-9]*:?[0-9]*"
                                        key={`${dateValue}-${proj.id}`}
                                        borderColor={proj.projectColor}
                                        bgColor={proj.projectColor}
                                        value={convertNumberToTime(found.workedHours)}
                                        date={dateValue}
                                        project={proj}
                                        disabled={props.isClosed}
                                    />
                                )}
                            </>
                        )}
                    </Media>
                )
            } else {
                return (
                    <Media
                        queries={{
                            small: '(max-width: 599px)',
                            medium: '(min-width: 600px) and (max-width: 1199px)',
                            large: '(min-width: 1200px)',
                        }}>
                        {matches => (
                            <>
                                {(matches.small || matches.medium) && (
                                    <InputsWrapper>
                                        <MobileLogContentWrapper
                                            id={i}
                                            onClick={() => focusInput(i)}
                                            isHoliday={props.isHoliday}
                                            weekend={props.weekend || props.holiday}>
                                            <ProjectNameWrapper grey={greyNames}>{proj.name}</ProjectNameWrapper>
                                            <LogWrapper>
                                                <LogInput
                                                    key={`${dateValue}-${proj.id}`}
                                                    borderColor={proj.projectColor}
                                                    bgColor={proj.projectColor}
                                                    value={''}
                                                    date={dateValue}
                                                    project={proj}
                                                    disabled={props.isClosed}
                                                    ref={setRef}
                                                />
                                            </LogWrapper>
                                        </MobileLogContentWrapper>
                                    </InputsWrapper>
                                )}
                                {matches.large && (
                                    <LogInput
                                        key={`${dateValue}-${proj.id}`}
                                        borderColor={proj.projectColor}
                                        bgColor={proj.projectColor}
                                        value={''}
                                        date={dateValue}
                                        project={proj}
                                        disabled={props.isClosed}></LogInput>
                                )}
                            </>
                        )}
                    </Media>
                )
            }
        })
    }

    const splicedProjects = useCallback(() => {
        const splicedArraysSize = 2
        const splicedArrays = []
        for (var i = 0; i < projectInputs.length; i += splicedArraysSize) {
            splicedArrays.push(projectInputs.slice(i, i + splicedArraysSize))
        }
        return splicedArrays
    }, [projectInputs])

    return (
        <Wrapper>
            <Media
                query="(max-width: 1199px)"
                render={() => (
                    <DailySum onClick={openProjectDrawer} hide={props.hide}>
                        <WorkLogsIcon color={props.sumColor} margin="0px 4px 0px 0px" width="18px" height="18px" />
                        <HoursWrapper color={props.sumColor}>{convertNumberToTime(props.hours)}h</HoursWrapper>
                        {/* <CommIcon></CommIcon><LogsCountWrapper>{logs.length}</LogsCountWrapper> */}
                    </DailySum>
                )}
            />
            {splicedProjects().map(splicedProjects => {
                return (
                    <ProjectsWrapper projectsNo={projects.length}>
                        {logs.length >= 0 && splicedProjects}
                    </ProjectsWrapper>
                )
            })}
        </Wrapper>
    )
}

export default Inputs

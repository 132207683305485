import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    padding-right: 2px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const CalendarSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" width="24" height="24" data-name="Layer 2" viewBox="0 0 24 24">
        <g id="Layer_2-2" data-name="Layer 2">
            <path id="Rectangle_437" d="M0 0h24v24H0z" class="cls-1" fill="none" data-name="Rectangle 437" />
            <path
                id="Path_215"
                d="M18 3h-1V2a1 1 0 1 0-2 0v1H9V2a1 1 0 1 0-2 0v1H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zm1 15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-7h14zm0-9H5V6a1 1 0 0 1 1-1h1v1a1 1 0 0 0 2 0V5h6v1a1 1 0 0 0 2 0V5h1a1 1 0 0 1 1 1z"
                class="cls-2"
                fill="#212529"
                data-name="Path 215"
            />
        </g>
    </svg>
)

const CalendarIcon = props => <SuperIcon component={CalendarSvg} {...props} />

export default CalendarIcon

import React from 'react'
import Icon from '@ant-design/icons'

const PhoneSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect fill="none" width="24" height="24" />
                <path
                    className="cls-2"
                    d="M18.4,21h-.25a17.1,17.1,0,0,1-7.39-2.62,16.82,16.82,0,0,1-5.13-5.12A17,17,0,0,1,3,5.86a2.6,2.6,0,0,1,.6-1.92A2.54,2.54,0,0,1,5.38,3H8.05a2.61,2.61,0,0,1,2.57,2.24,8.92,8.92,0,0,0,.51,2.05A2.6,2.6,0,0,1,10.54,10l-.47.46a12,12,0,0,0,3.42,3.41l.46-.47a2.62,2.62,0,0,1,2.76-.59,9,9,0,0,0,2,.51A2.6,2.6,0,0,1,21,16v2.4A2.61,2.61,0,0,1,18.4,21ZM8,5H5.61a.64.64,0,0,0-.47.22A.63.63,0,0,0,5,5.66a15,15,0,0,0,2.32,6.51,14.63,14.63,0,0,0,4.52,4.51A14.92,14.92,0,0,0,18.35,19h0a.62.62,0,0,0,.46-.18.58.58,0,0,0,.18-.43V16a.61.61,0,0,0-.52-.61A11.18,11.18,0,0,1,16,14.72a.62.62,0,0,0-.65.14l-1,1a1,1,0,0,1-1.2.16,13.85,13.85,0,0,1-5.2-5.19A1,1,0,0,1,8.1,9.64l1-1A.59.59,0,0,0,9.26,8a11.12,11.12,0,0,1-.62-2.48A.6.6,0,0,0,8,5Z"
                />
            </g>
        </g>
    </svg>
)

const PhoneIcon = props => <Icon className="icon" component={PhoneSvg} {...props} />

export default PhoneIcon

import { createGlobalStyle } from 'styled-components'
import theme from './components/Common/theme'

const GlobalStyles = createGlobalStyle`
  .nav-text {
    color: ${theme.colors.secondary} !important;
  }
  .ant-layout-sider-children, .ant-menu {
    background-color: ${theme.colors.background} !important;
  }
  .ant-menu-inline {
    border: none !important;
  }
  .ant-layout, .ant-layout-header {
    background-color: ${theme.colors.white} !important;
  }

  .ant-layout-header {
    border-bottom: ${document.body.scrollTop === 0 ? 'none' : `${theme.borders.thin} solid red`};
    padding: 0 24px !important;
    box-sizing: border-box;
  }
  
  .ant-menu-item-selected, .ant-menu-item:active {
    background-color: ${theme.colors.selected} !important;
    border-right: none !important;
  }
  
  .ant-menu-item-selected {
    i svg, span {
      color: ${theme.colors.blue} !important;
    }
    .cls-2 {
      fill: blue !important;
    }
    .cls-1-knowledge {
      fill: blue !important;
    }
  }

  .ant-menu-item:hover .ant-menu-inline, .ant-menu-item{
    width: 100% !important;
  }

  .ant-menu-item-selected {
    background-color: #e6f7ff;
  }

  .ant-menu-item {
    display:flex !important;
    height: 48px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    > a {
      display:flex !important;
      align-items: center !important;
    }
    .anticon {
      font-size: ${theme.fontSizes.big} !important;
      margin-right: 16px !important;
      svg {
        color: ${theme.colors.secondary};
      }
    }
    &::after {
      border-right: ${theme.borders.thin} solid ${theme.colors.disabled} !important;
    }
  }

  .ant-menu-submenu-title .anticon {
    margin-right: 16px !important;
  }

  .ant-submenu-collapse-icon {
    margin-left: 0px !important;
  }

  .ant-popover-content .ant-popover-arrow {
    visibility: hidden !important;
  }

  .ant-card .ant-card-body {
    padding: 0px !important;
  }

  .ant-btn-group {
    display: flex !important;
    margin-top: 1px;
  }

  .ant-switch-checked {
    background-color: ${theme.colors.blue} !important;
  }

  .ant-input-affix-wrapper-focused {
    border-color: ${theme.colors.blue} !important;
    background-color: ${theme.colors.white} !important;
    outline: none !important;
  }

  .ant-tooltip-placement-right {
    padding-top: 0px;
    left: 80px !important;
    padding-bottom: 20px !important;
}

  .notification-class {
    padding: 16px !important;
    /* White */
    background: ${theme.colors.white};
    right: 120px;
    /* Grey/300 */
    border: ${theme.borders.thin} solid ${theme.colors.border};
    box-sizing: border-box;

    /* Dialog Shadow */
    box-shadow: 0px 8px 16px rgba(32, 32, 35, 0.0784314);
    border-radius: 8px;
  }

  .notification-class > * {
    font-style: normal;
    font-weight: normal;
    font-size: ${theme.fontSizes.medium} !important;
    line-height: 20px;
    color: ${theme.fontSizes.secondary};
  }

  .ant-notification-notice-close {
    && {
      right: 16px;
    }
  }

  .ant-notification-notice-message { 
    margin-bottom: 16px !important;
  }

  .ant-dropdown {
    z-index: 2100 !important;
}

  ::selection {
    background: #d9d9d9 !important;
}
`

export default GlobalStyles

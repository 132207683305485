import {
    useState,
    useCallback,
  } from "react";
import moment from "moment";

const useCalendarNavigation = () => {
    const [dateValue, setDateValue] = useState(moment());
    
  
    const goForwardOneMonth = useCallback(() => {
      let oldDate = moment(dateValue);
      if (moment(oldDate).month() === 11) {
        let decemberSituation = moment(oldDate).add(1, "years");
        decemberSituation = moment(decemberSituation).month(0);
  
        setDateValue(decemberSituation);
        return;
      }
  
      oldDate = oldDate.add(1, "months");
      setDateValue(oldDate);
    }, [dateValue]);
  
    const goBackOneMonth = useCallback(() => {
      let oldDate = moment(dateValue);
      if (moment(oldDate).month() === 0) {
        let januarySituation = moment(oldDate).subtract(1, "years");
        januarySituation = moment(januarySituation).month(11);
  
        setDateValue(januarySituation);
        return;
      }
  
      oldDate = oldDate.subtract(1, "months");
      setDateValue(oldDate);
    }, [dateValue]);
  
    const changeDate = useCallback(val => {
      let newSelectedDate = moment(val);
      setDateValue(newSelectedDate);
    }, []);
  
    const goToToday = useCallback(() => {
      setDateValue(moment());
    }, []);
  
    return {
      dateValue,
      goForwardOneMonth,
      goBackOneMonth,
      changeDate,
      goToToday
    }
  }

  export default useCalendarNavigation;
import React from 'react'
import Inputs from './LogsInputBox/LogsInputBox'
import moment from 'moment'
import { DayNrMobile, DayNameMobile } from '../Common/components/calendar'
import { DayWrapperMobile, DayCellHeaderMobile } from './WorkLogs.components'

const WorkLogsMobile = props => {
    return (
        <DayWrapperMobile>
            <DayCellHeaderMobile
                holiday={props.holiday}
                onClick={() => {
                    console.log('OPEN')
                    props.openCommentDrawer()
                }}>
                <DayNrMobile holiday={props.holiday}>{props.data.dayNo}</DayNrMobile>
                <DayNameMobile holiday={props.holiday}>{moment(props.data.date).format('ddd')}</DayNameMobile>
            </DayCellHeaderMobile>
            <Inputs
                holiday={props.holiday}
                weekend={props.weekend}
                key={`input-boxes-${props.data.date}`}
                isClosed={props.isClosed}
                dateValue={props.data.date}
                projects={props.projects}
                isHoliday={props.isHoliday}
                logs={props.logs}
                index={props.index}
                hours={props.hours}
                sumColor={props.sumColor}
                hide={props.hide ? 'hidden' : 'visible'}
            />
        </DayWrapperMobile>
    )
}

export default WorkLogsMobile

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 18px;
    height: 18px;
    padding-right: 2px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.inactive)};
    }
`

const SearchSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" fill="none" />
                <path
                    className="cls-2"
                    d="M19.71,18.29l-4-4-.11-.1a7,7,0,1,0-1.41,1.41l.1.11,4,4a1,1,0,0,0,1.42,0A1,1,0,0,0,19.71,18.29ZM10,15a5,5,0,1,1,5-5A5,5,0,0,1,10,15Z"
                />
            </g>
        </g>
    </svg>
)

const SearchIcon = props => <SuperIcon component={SearchSvg} {...props} />

export default SearchIcon

import styled from 'styled-components'
import ChevronDownIcon from '../../../assets/icons/ChevronDownIcon'
import theme from '../../Common/theme'
import { CloseButtonMobile } from '../../Common/components/buttons'
import {
    PreviousMonthButton,
    NextMonthButton,
} from '../../Layout/Legend/CalendarNavigation/CalendarNavigation.components'

export const Header = styled.div`
    position: sticky;
    top: 0;
    background-color: ${theme.colors.white};
`
export const Navigation = styled.div`
    width: 100%;
    padding: 0px 24px !important;
    height: 135px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid ${theme.borders.thin} ${theme.colors.disabled};
`

export const CloseButton = styled(CloseButtonMobile)`
    && {
        margin: 0px;

        &:hover {
            background-color: transparent !important;
        }
    }
`

export const HeaderButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 64px;
    background-color: ${theme.colors.background};
    padding: 14px 24px !important;
`

export const PreviousDayButton = styled(PreviousMonthButton)`
    && {
        margin-right: 0px;
    }
`

export const NextDayButton = styled(NextMonthButton)``

export const CurrentDateWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 32px;
`

export const DayNumber = styled.span`
    font-size: ${theme.fontSizes.mobileDayNumber};
    color: ${theme.colors.blue};
    margin-bottom: -12px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -5px;
    text-align: center;
    color: ${theme.colors.blue};
    padding-right: 7px;
`

export const DayName = styled.span`
    text-transform: capitalize;
    color: ${theme.colors.inactive};
`

export const ProjectColor = styled.div`
    border-radius: 50%;
    background-color: ${props => props.color};
    border: ${theme.borders.thin} solid ${props => props.color};
    width: 8px;
    height: 8px;
    margin-right: 16px;
    margin-left: 24px;
`

export const Project = styled.div`
    font-size: ${theme.fontSizes.mediumBig};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${theme.colors.primary};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 16px;
`

export const ProjectDropdownWrapper = styled.div`
    width: 100%;
`

export const DropdownIcon = styled(ChevronDownIcon)`
    height: 24px;
    margin-right: 0px;
    > svg {
        padding-top: 2px;
    }
`

export const MenuText = styled.span`
    font-size: ${theme.fontSizes.medium};
`

export const DropdownSpan = styled.span`
    float: right;
    margin-right: 24px;
    cursor: pointer;
    user-select: none;

    &:hover {
        color: blue;
        ${props => props.disabled && `color: ${theme.colors.inactive}`}
        > i {
            color: blue !important;
            ${props => props.disabled && `color: ${theme.colors.inactive} !important`}
        }
    }
    > span > svg > g {
        ${props => props.disabled && `color: ${theme.colors.inactive} !important`}
    }
    ${props => props.disabled && 'cursor: default !important'}
    ${props => props.disabled && `color: ${theme.colors.inactive}`}
`

export const CommentsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &:last-child {
        margin-bottom: 24px;
    }
`

export const ItemContent = styled.option`
    height: 32px;
    width: 100%;
    padding: 8px 8px;
    cursor: pointer;
    &:hover {
        color: blue;
    }
`

export const StyledLabel = styled.span`
    color: ${theme.colors.primary};
`

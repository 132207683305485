import React, { useCallback } from 'react'
import styled from 'styled-components'
import theme from '../../Common/theme'
import ChevronDownIcon from '../../../assets/icons/ChevronDownIcon'
import CancelIcon from '../../../assets/icons/CancelIcon'
import FilterModal from './FilterModal'
import moment from 'moment'
import { endOfMonth, startOfMonth } from 'date-fns'

const Wrapper = styled.div`
    display: flex;
    position: relative;
`

const Input = styled.input`
    width: 352px;
    height: 48px;
    border-radius: 4px;
    border: solid ${theme.borders.thin} ${theme.colors.border};
    padding: 11px 16px 11px 16px;
    font-family: 'SofiaProRegular';
    font-size: ${theme.fontSizes.medium};
    line-height: 1.71;
    cursor: pointer;
    color: ${p => (p.active ? theme.colors.primary : theme.colors.inactive)};
    :focus {
        outline: 0px;
    }

    @media only screen and (max-width: 1179px) {
        width: 324px;
    }
`

export const IconWrapper = styled.div`
    position: absolute;
    left: 318px;
    top: 50%;
    width: 18px;
    height: 18px;
    transform: translateY(-50%);

    @media only screen and (max-width: 1179px) {
        left: 294px;
    }
`

const Filter = ({ value, onChange, opened, dateValue, setOpened }) => {
    const showFilter = useCallback(() => {
        setOpened(true)
    }, [setOpened])

    const hideFilter = useCallback(() => {
        setOpened(false)
    }, [setOpened])

    const clearValues = () => {
        onChange({ startDate: startOfMonth(new Date(dateValue)), endDate: endOfMonth(new Date(dateValue)) })
    }

    return (
        <Wrapper>
            <Input
                active={value}
                readOnly={true}
                placeholder={'Filtruj'}
                value={
                    value.startDate &&
                    value.endDate &&
                    (value.startDate.getFullYear() === new Date().getFullYear() - 5
                        ? 'Zawsze'
                        : `${moment(value.startDate).format('DD.MM.YYYY')} - ${moment(value.endDate).format(
                              'DD.MM.YYYY',
                          )}`)
                }
                onClick={opened ? hideFilter : showFilter}
            />
            <IconWrapper>
                {value.startDate && value.endDate && !opened ? (
                    <CancelIcon onClick={clearValues} />
                ) : (
                    <ChevronDownIcon onClick={opened ? hideFilter : showFilter} color={theme.colors.primary} />
                )}
            </IconWrapper>
            {opened && (
                <>
                    <div
                        style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }}
                        onClick={hideFilter}
                    />
                    <FilterModal opened={opened} onChange={onChange} value={value} />
                </>
            )}
        </Wrapper>
    )
}

export default Filter

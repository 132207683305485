import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 18px;
    height: 18px;

    .comment-button {
        fill: ${props => (props.color ? props.color : theme.colors.inactive)};
    }
`

const CommentIconSvg = props => (
    <svg xmlns="http://www.w3.org/2000/svg" id="prefix__create-24px" width="18" height="18" viewBox="0 0 18 18">
        <path id="prefix__Path_342" fill="none" d="M0 0h18v18H0z" data-name="Path 342" />
        <path
            id="prefix__Path_343"
            className="comment-button"
            d="M3 13.844v2.28a.371.371 0 0 0 .375.375h2.28a.352.352 0 0 0 .263-.112l8.19-8.187L11.3 5.392l-8.187 8.182a.368.368 0 0 0-.113.27zm13.282-7.815a.747.747 0 0 0 0-1.057l-1.755-1.755a.747.747 0 0 0-1.057 0L12.1 4.589 14.91 7.4l1.372-1.372z"
            data-name="Path 343"
            transform="translate(-.75 -.749)"
        />
    </svg>
)

const CommentIcon = props => <SuperIcon collapsed={props.collapsed} component={CommentIconSvg} {...props} />

export default CommentIcon

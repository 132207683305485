export const JWT_TOKEN = () => localStorage.getItem('token')

export const GETCONFIG = async () => {
    let config = {
        headers: { Authorization: `Bearer ${JWT_TOKEN()}` },
    }

    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/hr-config` || `${process.env.REACT_APP_API_URL}/hr-config`,
        config,
    )
    return response.json().catch(err => console.log(err))
}

export const BANK_HOLIDAYS = [
    '01.01.2020',
    '06.01.2020',
    '12.04.2020',
    '13.03.2020',
    '01.05.2020',
    '03.05.2020',
    '31.05.2020',
    '11.06.2020',
    '15.08.2020',
    '01.11.2020',
    '11.11.2020',
    '25.12.2020',
    '26.12.2020',
]

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 18px;
    height: 18px;
    object-fit: contain;

    .cls-1-right {
        fill: none;
    }
    .cls-2-right {
        fill: ${theme.colors.inactive};
    }
`

const ArrowRightSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1-right" fill="none" width="24" height="24" />
                <path
                    className="cls-2-right"
                    fill="#212529"
                    d="M19.71,12.71l-6,6a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L16.59,13H5a1,1,0,0,1,0-2H16.59l-4.3-4.29a1,1,0,0,1,1.42-1.42l6,6A1,1,0,0,1,19.71,12.71Z"
                />
            </g>
        </g>
    </svg>
)

const ArrowRightIcon = props => <SuperIcon component={ArrowRightSvg} {...props} />

export default ArrowRightIcon

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const LogoutSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" fill="none" width="24" height="24" />
                <path
                    className="cls-2"
                    d="M18,22H8a3,3,0,0,1-3-3V16a1,1,0,0,1,2,0v3a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1H8A1,1,0,0,0,7,5V8A1,1,0,0,1,5,8V5A3,3,0,0,1,8,2H18a3,3,0,0,1,3,3V19A3,3,0,0,1,18,22Z"
                />
                <path
                    className="cls-2"
                    d="M17,12a.69.69,0,0,1,0,.25.37.37,0,0,1,0,.14,1,1,0,0,1-.21.32l-4,4a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L13.59,13H4a1,1,0,0,1,0-2h9.59l-2.3-2.29a1,1,0,1,1,1.42-1.42l4,4a1,1,0,0,1,.21.32.37.37,0,0,1,0,.14A.69.69,0,0,1,17,12Z"
                />
            </g>
        </g>
    </svg>
)

const LogoutIcon = props => <SuperIcon className="icon" component={LogoutSvg} {...props} />

export default LogoutIcon

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: ${props => (props.width ? props.width : '24px')};
    height: ${props => (props.height ? props.height : '24px')};
    ${props => props.margin && `margin: ${props.margin};`}

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const WorkLogsSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" fill="none" width="24" height="24" />
                <path
                    className="cls-2"
                    d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22ZM12,4a8,8,0,1,0,8,8A8,8,0,0,0,12,4Z"
                />
                <path
                    className="cls-2"
                    d="M14,15a1,1,0,0,1-.71-.29l-2-2A1,1,0,0,1,11,12V7a1,1,0,0,1,2,0v4.59l1.71,1.7a1,1,0,0,1,0,1.42A1,1,0,0,1,14,15Z"
                />
            </g>
        </g>
    </svg>
)

const WorkLogsIcon = props => <SuperIcon className="icon" component={WorkLogsSvg} {...props} />

export default WorkLogsIcon

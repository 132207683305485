import React, { useContext } from 'react'
import { Avatar } from 'antd'
import 'antd/dist/antd.css'
import { UserContext } from '../../Common/contexts/userContext'
import createGravatar from '../../../shared/createGravatar'
import { AvatarWrapper, ImageWrapper, NameWrapper } from './Avatar.components'

const UserNameItem = ({ collapsed }) => {
    const user = useContext(UserContext)
    const gravatarUrl = createGravatar(user.details.email)
    return (
        <AvatarWrapper>
            <ImageWrapper>
                <a tabIndex={-1} href="https://pl.gravatar.com/" target="_blank" rel="noopener noreferrer">
                    <Avatar size={32} src={gravatarUrl}></Avatar>
                </a>
            </ImageWrapper>
            <NameWrapper collapsed={collapsed}>{`${user.details.firstName || ''} ${
                user.details.lastName || ''
            }`}</NameWrapper>
        </AvatarWrapper>
    )
}

export default UserNameItem

import styled from 'styled-components'
import theme from '../../Common/theme'

export const DashboardBox = styled.div`
    width: 512px;
    border-radius: 8px;
    border: solid ${theme.borders.thin} ${theme.colors.border};
    background-color: ${theme.colors.white};
    margin-bottom: 32px;
    padding: 32px;
    position: relative;

    @media (min-width: 600px) and (max-width: 1439px) {
        margin-bottom: 24px;
    }

    @media (min-width: 1280px) and (max-width: 1439px) {
        max-width: unset;
        width: 100%;
    }

    @media (min-width: 1200px) and (max-width: 1279px) {
        width: 100%;
    }

    @media (max-width: 599px) {
        width: 100%;
        margin-bottom: 16px;
        padding: 16px 16px;
    }
`

export const BoxTitle = styled.div`
    width: 100%;
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumBig};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${theme.colors.primary};
    margin-bottom: 32px;
`

export const TinyTitleDiv = styled.div`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumSmall};
    line-height: 1.67;
    height: 20px;
    text-align: left;
    color: ${theme.colors.inactive};
`

export const TinyTitleDivSpan = styled.span`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumSmall};
    line-height: 1.67;
    text-align: left;
    color: ${theme.colors.inactive};
`

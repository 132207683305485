import * as React from 'react'
import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import theme from '../../Common/theme'
import ChevronRightIcon from '../../../assets/icons/ChevronRightIcon'
import ChevronLeftIcon from '../../../assets/icons/ChevronLeftIcon'

const Wrapper = styled.div`
    justify-content: center;
    align-items: center;
    padding: 19px 32px 32px 32px;
`

const Header = styled.div`
    display: flex;
    font-family: 'SofiaProRegular';
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.primary};
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 27px;
`

const Button = styled.div`
    cursor: pointer;
`

const Body = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`

const ChevronWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 62px;
`

const Day = styled.div`
    width: 36px;
    height: 36px;
    font-size: ${p => (p.isNumber ? theme.colors.medium : theme.colors.mediumSmall)};
    color: ${p => (p.isNumber ? theme.colors.inactive : theme.colors.inactive)};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${props =>
        (props.startDate || props.endDate) &&
        css`
            border-radius: 50%;
            background-color: ${theme.colors.blue};
            color: ${theme.colors.white};
        `}
`

const DayWrapper = styled.div`
    ${props =>
        props.isBetweenDates &&
        css`
            background-color: ${theme.colors.hover};
        `}
    ${props =>
        props.isStartDate &&
        css`
            background-color: ${theme.colors.hover};
            border-bottom-left-radius: 50%;
            border-top-left-radius: 50%;
        `}
    ${props =>
        props.isEndDate &&
        css`
            background-color: ${theme.colors.hover};
            border-bottom-right-radius: 50%;
            border-top-right-radius: 50%;
        `}
`

const Calendar = ({ onSet, value }) => {
    const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    const DAYS_OF_THE_WEEK = ['Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob', 'Niedz']
    const MONTHS = [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień',
    ]

    const today = new Date()
    const [date, setDate] = useState(today)
    const [day, setDay] = useState(date.getDate())

    useEffect(() => {
        setDay(date.getDate())
        setMonth(date.getMonth())
        setYear(date.getFullYear())
        setStartDay(getStartDayOfMonth(date))
    }, [date])

    const getStartDayOfMonth = date => {
        let dayNo = new Date(date.getFullYear(), date.getMonth(), 1).getDay()
        if (dayNo === 0) return 7
        else return dayNo
    }

    const isLeapYear = year => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
    }

    const days = isLeapYear(date.getFullYear()) ? DAYS_LEAP : DAYS
    const [month, setMonth] = useState(date.getMonth())
    const [year, setYear] = useState(date.getFullYear())
    const [startDay, setStartDay] = useState(getStartDayOfMonth(date))
    const handleDateChange = (year, month, d) => {
        const clickedDate = new Date(year, month, d)
        if (value.startDate === undefined) {
            onSet({
                startDate: clickedDate,
                endDate: undefined,
            })
        } else if (value.endDate === undefined && clickedDate > value.startDate) {
            onSet({
                startDate: value.startDate,
                endDate: clickedDate,
            })
        } else if (value.endDate === undefined && clickedDate < value.startDate) {
            onSet({
                startDate: clickedDate,
                endDate: value.startDate,
            })
        } else {
            onSet({
                startDate: clickedDate,
                endDate: undefined,
            })
        }
    }

    return (
        <Wrapper>
            <Header>
                <div>
                    {MONTHS[month]} {year}
                </div>
                <ChevronWrapper>
                    <Button onClick={() => setDate(new Date(year, month - 1, day))}>
                        <ChevronLeftIcon width="18px" height="18px" />
                    </Button>
                    <Button onClick={() => setDate(new Date(year, month + 1, day))}>
                        <ChevronRightIcon width="18px" height="18px" />
                    </Button>
                </ChevronWrapper>
            </Header>
            <Body>
                {DAYS_OF_THE_WEEK.map(d => (
                    <Day key={d}>
                        <strong>{d}</strong>
                    </Day>
                ))}
                {Array(days[month] + (startDay - 1))
                    .fill(null)
                    .map((_, index) => {
                        //mam tablicę nulli, pomniejszoną o dzień tygodnia -1
                        const d = index - (startDay - 2)
                        const date = new Date(year, month, d)
                        const isStartDate = d > 0 && value.startDate && value.startDate.getTime() === date.getTime()
                        const isEndDate = d > 0 && value.endDate && value.endDate.getTime() === date.getTime()
                        const isBetweenDates =
                            d > 0 &&
                            value.startDate &&
                            value.endDate &&
                            value.startDate.getTime() < date.getTime() &&
                            date.getTime() < value.endDate.getTime()
                        return (
                            <DayWrapper
                                isStartDate={value.endDate && isStartDate}
                                isEndDate={value.startDate && isEndDate}
                                isBetweenDates={isBetweenDates}
                                key={date}>
                                <Day
                                    key={date}
                                    isNumber={true}
                                    startDate={isStartDate}
                                    endDate={isEndDate}
                                    onClick={() => d > 0 && handleDateChange(year, month, d)}>
                                    {d > 0 ? d : ''}
                                </Day>
                            </DayWrapper>
                        )
                    })}
            </Body>
        </Wrapper>
    )
}

export default Calendar

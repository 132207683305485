import React from 'react'
import styled from 'styled-components'
import { Slider } from 'antd'
import theme from '../Common/theme'
import { HeaderWrapper, Sum, OKSumWrapper } from '../Common/components/navbar'
import CalendarNavigation from '../Layout/Legend/CalendarNavigation/CalendarNavigation'
import { OKSystemContext } from './contexts/OKSystemContext'
import Media from 'react-media'
import {
    DayHeaderMobile,
    DayNrMobile as EntryCountMobile,
    DayNameMobile as EntryWordSpan,
} from '../Common/components/calendar'

const OKSum = styled(Sum)`
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 30px;
    &:first-of-type {
        padding-left: 0;
        border-right: ${theme.borders.medium} solid ${theme.colors.disabled};
    }
    @media (min-width: 768px) and (max-width: 1199px) {
        padding: 0 22px;
    }
`

const ProgressSlider = styled(Slider)`
    && {
        display: flex;
        flex: 1;
        margin: 25px 40px 25px 5px;
        width: 50%;

        &:hover {
            cursor: default;

            .ant-slider-track {
                background-color: ${theme.colors.blue};
            }
        }

        .ant-slider-dot {
            width: 16px;
            height: 16px;
            margin-top: -4px;
            border: ${theme.borders.thick} solid ${theme.colors.white};
            border-radius: 50%;
            display: block;

            background-color: ${props => (props.value >= 4 ? theme.colors.blue : theme.colors.inactive)};
            box-shadow: 0 0 0 1pt ${props => (props.value >= 4 ? theme.colors.blue : theme.colors.inactive)};

            &::after {
                content: 'Zniżka 25zł';
                background-color: ${theme.colors.secondary};
                color: ${theme.colors.white};
                display: inline-block;
                position: absolute;
                opacity: 0;
                top: 20px;
                left: 5px;
                width: 75px;
                pointer-events: none;
                height: 24px;
                font-size: ${theme.fontSizes.small};
                text-align: center;
                padding: 5px 10px;
                border-radius: 4px;
                transform: translateX(-50%);
                transition: opacity 0.2s ease-in-out;
                box-shadow: 0 2px 4px 0 rgba(32, 32, 35, 0.24);
            }
            &:hover {
                cursor: default;
                &::after {
                    opacity: 1;
                }
            }
        }

        .ant-slider-dot:last-child {
            background-color: ${props => (props.value >= 9 ? theme.colors.blue : theme.colors.inactive)};
            box-shadow: 0 0 0 1pt ${props => (props.value >= 9 ? theme.colors.blue : theme.colors.inactive)};
            &::after {
                content: 'Zniżka 50zł';
            }
        }

        .ant-slider-rail {
            background: linear-gradient(to right, #f8f9fa 1%, #acb5bd 0);
            background-size: 11.11% 100%;
            border-top-left-radius: 100px 70px;
            border-bottom-left-radius: 100px 70px;
        }

        .ant-slider-track {
            background-color: ${theme.colors.blue};
            border-top-left-radius: 100px 70px;
            border-bottom-left-radius: 100px 70px;
        }

        .ant-slider-handle {
            display: none;
        }
    }
`

const OKHeaderWrapperMobile = styled(HeaderWrapper)`
    && {
        width: 100%;

        @media (min-width: 600px) and (max-width: 1199px) {
            padding-left: 24px;
        }
    }
`

const OKSumMobile = styled(DayHeaderMobile)`
    && {
        margin-left: 12px;
        width: 42px;
        align-content: center;
    }
`

const marks = () => {
    return {
        4: '',
        9: '',
    }
}

const OKLegend = props => {
    const discount = () => {
        if (props.codesNumber > 3) {
            if (props.codesNumber > 8) {
                return 50
            } else {
                return 25 + 5 * (props.codesNumber - 4)
            }
        } else {
            return 0
        }
    }

    return (
        <Media
            queries={{
                small: '(max-width: 599px)',
                medium: '(min-width: 600px) and (max-width: 1199px)',
                large: '(min-width: 1200px)',
            }}>
            {matches => (
                <>
                    {matches.small && (
                        <OKHeaderWrapperMobile>
                            <OKSumMobile>
                                <EntryCountMobile data-cy="entries-count">{props.codesNumber}</EntryCountMobile>
                                <EntryWordSpan>Wejścia</EntryWordSpan>
                            </OKSumMobile>
                            <ProgressSlider
                                min={0}
                                max={9}
                                value={props.codesNumber}
                                marks={marks()}
                                step={1}
                                tipFormatter={null}
                            />
                        </OKHeaderWrapperMobile>
                    )}
                    {matches.medium && (
                        <OKHeaderWrapperMobile>
                            <OKSum>
                                <span>Ilość wejść</span>
                                <OKSumWrapper data-cy="entries-count">{props.codesNumber}</OKSumWrapper>
                            </OKSum>
                            <OKSum>
                                <span>Zniżka</span>
                                <OKSumWrapper color={theme.colors.blue}>{discount()}</OKSumWrapper>
                                <span>zł</span>
                            </OKSum>
                            <ProgressSlider
                                min={0}
                                max={9}
                                value={props.codesNumber}
                                marks={marks()}
                                step={1}
                                tipFormatter={null}
                            />
                        </OKHeaderWrapperMobile>
                    )}
                    {matches.large && (
                        <HeaderWrapper>
                            <OKSum>
                                <span>Ilość wejść</span>
                                <OKSumWrapper data-cy="entries-count">{props.codesNumber}</OKSumWrapper>
                            </OKSum>
                            <OKSum>
                                <span>Zniżka</span>
                                <OKSumWrapper color={theme.colors.blue}>{discount()}</OKSumWrapper>
                                <span>zł</span>
                            </OKSum>
                            <ProgressSlider
                                min={0}
                                max={9}
                                value={props.codesNumber}
                                marks={marks()}
                                step={1}
                                tipFormatter={null}
                            />
                            <CalendarNavigation context={OKSystemContext} />
                        </HeaderWrapper>
                    )}
                </>
            )}
        </Media>
    )
}

export default OKLegend

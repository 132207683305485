import React from 'react'
import styled from 'styled-components'
import LogoIcon from './assets/icons/LogoIcon'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const ScreenLoader = () => (
    <Wrapper>
        <LogoIcon />
    </Wrapper>
)

export default ScreenLoader

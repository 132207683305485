import styled from 'styled-components'
import { Input } from 'antd'
import theme from '../theme'

export const LogInputBase = styled.input`
    -moz-appearance: none;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    width: 26px;
    height: 26px;
    border-radius: 40px;
    font-family: SofiaProMedium !important;
    position: relative;
    outline: none;
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.white};
    transition: all 0.2s ease-in-out;

    &:hover {
        cursor: pointer;
        transform: scale(1.23);
    }

    &:focus {
        margin-left: 5px;
        margin-right: 5px;
        font-size: ${theme.fontSizes.mediumSmall};
        width: 58.5px;
        transform: scale(1.23);
        background: ${theme.colors.white};
        color: ${theme.colors.primary};
        cursor: text;
    }

    @media only screen and (max-width: 1199px) {
        transform: scale(1.23);
        font-size: ${theme.fontSizes.small};

        &:focus {
            font-size: ${theme.fontSizes.medium};
            width: 58.5px;
        }
    }
`

export const StyledInput = styled(Input)`
    && {
        width: 360px;
        height: 48px;
        margin-top: 8px;
        border-radius: 4px;
        border-color: ${props => (props.hasError ? theme.colors.error : theme.colors.border)};
        &:focus,
        &:hover {
            border-color: ${props => (props.hasError ? theme.colors.error : theme.colors.blue)};
            box-shadow: none;
        }

        @media all and (max-width: 599px) {
            width: 100%;
        }
    }
`

export const Link = styled.a`
    && {
        color: ${theme.colors.blue};
        &:hover,
        &:focus {
            color: ${theme.colors.blueHover};
        }
    }
`

export const Label = styled.label`
    && {
        font-size: ${theme.fontSizes.mediumSmall};
        line-height: 1.67;
        text-align: left;
        color: ${theme.colors.inactive};
        margin-bottom: 8px;
    }
`
export const InputPasswordContainer = styled.div`
    position: relative;
    &:hover input {
        border-color: ${theme.colors.blue} !important;
        box-shadow: none;
    }
`

export const StyledInputPassword = styled(Input)`
    && {
        width: 360px;
        height: 48px;
        margin-top: 8px;
        border-radius: 4px;
        border-color: ${props => (props.hasError ? theme.colors.error : theme.colors.border)};
        &:hover,
        &:focus {
            border-color: ${props => (props.hasError ? theme.colors.error : theme.colors.blue)} !important;
            box-shadow: none;
        }
        @media all and (max-width: 599px) {
            width: 100%;
        }
    }
`

export const IconPassword = styled.div`
    position: absolute;
    right: 22px;
    top: 10px;
    cursor: pointer;
    margin-top: 7px;

    div {
        width: 18px;
        height: 18px;
        background-size: contain;
    }
`

export const BasicFormInput = styled(Input)`
    && {
        &:hover,
        &:focus,
        &:active {
            input {
                border-color: ${theme.colors.border} !important;
                box-shadow: none;
            }
            button {
                border-color: ${theme.colors.border} !important;
                box-shadow: none;
            }
        }
        input {
            font-family: 'SofiaProRegular' !important;
            font-size: ${theme.fontSizes.medium};
            height: 36px;
            color: ${theme.colors.primary} !important;
        }
    }
`

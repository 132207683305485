import theme from '../Common/theme'
import CommentIcon from '../../assets/icons/CommentIcon'
import { Drawer } from 'antd'
import CalendarIcon from '../../assets/icons/CalendarIcon'
import styled from 'styled-components'
import { SpecialHeaderMobileButton } from '../Common/components/buttons'

export const CalendarIconMobile = styled(CalendarIcon)`
    && {
        width: 100%;
        height: 100%;
        & > svg {
            width: 24px;
            height: 24px;
        }

        .cls-1-search {
            fill: none;
        }
        .cls-2-search {
            fill: ${theme.colors.primary};
        }
    }
`

export const HeaderInfoWrapper = styled.div`
    text-transform: capitalize;
    font-family: SofiaProRegular;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const HeaderDate = styled.div`
    width: 122px;
    font-size: ${theme.fontSizes.mediumBig};
    line-height: 1.2;
    text-align: center;
    color: ${theme.colors.primary};
`

export const HeaderHourSum = styled.span`
    font-size: ${theme.fontSizes.medium};
    line-height: 1;
    text-align: center;
    color: ${theme.colors.inactive};
`

export const StyledDrawer = styled(Drawer)`
    && {
        z-index: 2000;

        > div > div > div {
            ::-webkit-scrollbar {
                display: none !important;
            }
        }

        > div > div > div > div {
            padding: 0px !important;
        }
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

export const MobileCommentIcon = styled(CommentIcon)`
    && {
        width: 100%;
        height: 100%;
        & > svg {
            width: 24px;
            height: 24px;
        }
        .comment-button {
            fill: black;
        }
    }
`

export const HeaderCommentButton = styled(SpecialHeaderMobileButton)`
    && {
        margin-top: 17px;
    }
`

import styled from 'styled-components'
import theme from '../Common/theme'

export const LoginPageContainer = styled.div`
    height: 100%;
    width: 100%;
    background: ${theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
`

export const LoginWrapper = styled.div`
    border: solid ${theme.borders.thin} ${theme.colors.border};
    border-radius: 9px;
    padding: 32px 32px 32px 32px;
    display: flex;
    flex-direction: column;

    @media all and (max-width: 599px) {
        border: none;
        width: 100%;
    }
`

export const PasswordInformation = styled.p`
    max-width: 320px;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '32px')};
    color: ${theme.colors.secondary};
`

export const ActionsContainer = styled.div`
    margin-top: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const StyledAlert = styled.div`
    width: 203px;
    height: 12px;
    font-size: ${theme.fontSizes.mediumSmall};
    line-height: 1.67;
    margin-top: -16px;
    text-align: left;
    color: ${theme.colors.error};
    visibility: ${props => (props.show ? 'visible' : 'hidden')};
    z-index: 200;
`

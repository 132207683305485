import React, { useContext } from 'react'
import { StyledHeader } from '../Common/components/header'
import Media from 'react-media'
import { UserContext } from '../Common/contexts/userContext'
import DashboardWages from './DashboardWages/DashboardWages'
import DashboardLeave from './DashboardLeave/DashboardLeave'
import {
    Layout,
    TitleWrapper,
    Content,
    DashboardHeader,
    DashboardSubHeader,
    DashboardContainer,
    BoxWrapper,
} from './DashboardPage.components'

const DashboardPage = props => {
    const user = useContext(UserContext)

    const toggleSidebar = data => {
        props.showSidebar(data)
    }

    return (
        <Layout>
            <Media
                queries={{
                    small: '(max-width: 599px)',
                    medium: '(min-width: 600px) and (max-width: 1199px)',
                }}>
                {matches => (
                    <>
                        {(matches.small || matches.medium) && (
                            <StyledHeader isEmployees={true} showSidebar={toggleSidebar}>
                                <TitleWrapper>Dashboard</TitleWrapper>
                            </StyledHeader>
                        )}
                    </>
                )}
            </Media>
            <Content>
                <DashboardHeader>Cześć {user.details.firstName || ''}!</DashboardHeader>
                <DashboardSubHeader>
                    Miej dobry dzień, pozostań nawodniony i pamiętaj, żeby regularnie uzupełniać godzinówki :)
                </DashboardSubHeader>
                <DashboardContainer>
                    <BoxWrapper>
                        <DashboardWages />
                        {/* <DashboardLeave /> */}
                    </BoxWrapper>
                    <BoxWrapper>
                        <DashboardLeave />
                        {/* <DashboardProjects /> */}
                        {/* <DashboardOKSystem /> */}
                    </BoxWrapper>
                </DashboardContainer>
            </Content>
        </Layout>
    )
}

export default DashboardPage

import React, { useState, useContext, useRef, useEffect, useCallback, useMemo } from 'react'
import { Route, useLocation, Link, Redirect } from 'react-router-dom'
import Media from 'react-media'
import AppRoutes from '../Menu/appRoutes'
import Logout from '../Menu/Logout/Logout'
import './Layouts.css'
import { ROUTES } from '../../Routes'
import WorklogsPage from '../WorkLogs/WorkLogsPage'
import WorkLogsContextProvider from '../WorkLogs/context/WorkLogsContext'
import OKPage from '../OKSystem/OKPage'
import OKSystemContextProvider from '../OKSystem/contexts/OKSystemContext'
import { UserContext } from '../Common/contexts/userContext'
import Employees from '../Employees/EmployeesPage'
import CloseIcon from '../../assets/icons/CloseIcon'
import Backdrop from '../../shared/Backdrop'
import RenderCalendar from './Calendar/RenderCalendar'
import DashboardPage from '../Dashboard/DashboardPage'
import useWindowSize from './hooks/useWindowSize'
import CollapseIcon from '../../assets/icons/CollapseIcon'
import {
    PageWrapper,
    SiderWrapper,
    ToggleMenuButton,
    CloseButtonMobile,
    SiderContentWrapper,
    LogoIconsWrapper,
} from './Layout.components'
import LogoIcon from '../../assets/icons/LogoIcon'
import ShortLogoIcon from '../../assets/icons/ShortLogoIcon'

const SideMenu = props => {
    const user = useContext(UserContext)
    const [collapsed, setCollapsed] = useState(true)
    const [scrollableMenu, setScrollableMenu] = useState(false)
    const defaultOpenKeys = useMemo(() => ['sub1'], [])
    const sideBarRef = useRef()
    const location = useLocation()
    const shouldDisplayWorklogs = location.pathname === ROUTES.WORKLOGS
    const shouldDisplayOKSystem = location.pathname === ROUTES.OKSYSTEM
    const shouldDisplayEmployees = location.pathname === ROUTES.EMPLOYEES
    const shouldDisplayDashboard = location.pathname === ROUTES.HOME

    const expand = () => {
        setCollapsed(false)
    }

    const collapse = () => {
        setCollapsed(true)
    }

    const toggleCollapse = () => {
        setCollapsed(!collapsed)
    }

    const [subMenuOpen, setSubMenuOpen] = useState(false)
    const [openKeys, setOpenKeys] = useState([])

    useEffect(() => {
        subMenuOpen ? setOpenKeys(defaultOpenKeys) : setOpenKeys([])
    }, [defaultOpenKeys, subMenuOpen])

    const handleSubmenuClick = useCallback(() => {
        setSubMenuOpen(s => !s)
    }, [setSubMenuOpen])

    const size = useWindowSize()
    useEffect(() => {
        if (size.width <= 1280) {
            collapse()
        }
        if (size.width > 1280) {
            expand()
        }
    }, [size])

    useEffect(() => {
        if (size.height < 850) {
            subMenuOpen ? setScrollableMenu(true) : setScrollableMenu(false)
        }
        if (size.height >= 850) {
            setScrollableMenu(false)
        }
        if (size.height < 560) {
            setScrollableMenu(true)
        }
    }, [size, subMenuOpen])

    return (
        <>
            <Route>
                {user.details.id && (
                    <PageWrapper>
                        <SiderWrapper
                            collapsible
                            collapsed={collapsed}
                            onCollapse={collapse}
                            scrollable={scrollableMenu}
                            trigger={null}
                            theme="light"
                            ref={sideBarRef}>
                            <SiderContentWrapper>
                                <Media
                                    query="(max-width: 1199px)"
                                    render={() => (
                                        <CloseButtonMobile onBlur={collapse} onClick={collapse}>
                                            <CloseIcon />
                                        </CloseButtonMobile>
                                    )}
                                />
                                <Link to={`${ROUTES.HOME}`}>
                                    <LogoIconsWrapper>
                                        {collapsed ? (
                                            <ShortLogoIcon
                                                collapsed={collapsed}
                                                height="40px"
                                                width="42px"
                                                margin="15px 0px 5px 15px"
                                            />
                                        ) : (
                                            <LogoIcon
                                                collapsed={collapsed}
                                                width="160px"
                                                height="40px"
                                                margin="15px 0px 0px 24px"
                                            />
                                        )}
                                    </LogoIconsWrapper>
                                </Link>

                                <Media
                                    query="(min-width: 1280px)"
                                    render={() => (
                                        <ToggleMenuButton onClick={toggleCollapse}>
                                            {collapsed ? (
                                                <CollapseIcon rotate={true} />
                                            ) : (
                                                <>
                                                    <CollapseIcon rotate={false} /> <span>Zwiń</span>
                                                </>
                                            )}
                                        </ToggleMenuButton>
                                    )}
                                />
                                <AppRoutes
                                    itemChosen={!collapse}
                                    collapsed={collapsed}
                                    subMenuOpen={subMenuOpen}
                                    handleSubmenuClick={handleSubmenuClick}
                                    openKeys={openKeys}
                                    defaultOpenKeys={defaultOpenKeys}
                                />
                            </SiderContentWrapper>
                            <Logout scrollable={scrollableMenu} />
                        </SiderWrapper>

                        <Media
                            query="(max-width: 1199px)"
                            render={() => <Backdrop show={!collapsed} clicked={collapse} />}
                        />
                        {shouldDisplayOKSystem && (
                            <OKSystemContextProvider>
                                <OKPage collapsed={collapsed} showSidebar={expand} />
                            </OKSystemContextProvider>
                        )}
                        {shouldDisplayWorklogs && (
                            <WorkLogsContextProvider>
                                <WorklogsPage collapsed={collapsed} sidebarVisible={!collapsed} showSidebar={expand}>
                                    <RenderCalendar {...props} />
                                </WorklogsPage>
                            </WorkLogsContextProvider>
                        )}
                        {shouldDisplayEmployees && <Employees showSidebar={expand} />}
                        {shouldDisplayDashboard && <DashboardPage showSidebar={expand} />}
                    </PageWrapper>
                )}
            </Route>
            <Route exact path="/" render={() => <Redirect to="/worklogs" />} />
        </>
    )
}

export default SideMenu

import styled from 'styled-components'
import { Alert } from 'antd'
import theme from '../theme'

export const StyledAlert = styled(Alert)`
    && {
        border-radius: 8px;
    }
`

export const ErrorMessage = styled.p`
    && {
        font-size: ${theme.fontSizes.mediumSmall};
        color: ${theme.colors.error};
        line-height: 30px;
    }
`

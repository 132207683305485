import { differenceInDays, addDays, isSameDay, isBefore } from "date-fns";

export const getHolidaysPeriods = (holidays) => {
  if (holidays.length === 1){
    return [{
      startDate: holidays[0].date,
      endDate: holidays[0].date,
      duration: 1,
    }]
  }

  const periods = [];

  // TODO check if sorting is needed
  const holidaysSorted = holidays.sort((a,b) => a.date - b.date)
  let periodStart = holidaysSorted[0]
  let periodEnd = holidaysSorted[0]

  for (let i = 1; i < holidays.length; i++){
    let newHoliday = holidays[i]
    const isNewEnd = isSameDay(addDays(periodEnd.date, 1), newHoliday.date)
    const isLast = i === holidays.length - 1

    if (isNewEnd){
      periodEnd = newHoliday
    }
    else {
      periods.push({
        startDate: periodStart.date,
        endDate: periodEnd.date,
        isPaid: periodStart.isPaid,
        duration: differenceInDays(periodEnd.date, periodStart.date) + 1,
      });
      periodStart = newHoliday
      periodEnd = newHoliday
    }

    if (isLast){
      periods.push({
        startDate: periodStart.date,
        endDate: periodEnd.date,
        duration: differenceInDays(periodEnd.date, periodStart.date) + 1,
      });
    }
  }
  return periods;
};

export const getHeldPeriods = (periods) => {
  return periods.filter((x) => isBefore(x.endDate, new Date()));
};
export const getFuturePeriods = (periods) => {
  return periods.filter((x) => isBefore(new Date(), x.endDate));
};

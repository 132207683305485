import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react'
import CloseIcon from '../../../assets/icons/CloseIcon'
import { WorkLogsContext } from '../context/WorkLogsContext'
//import { DatePicker } from "antd";
import Filter from '../Filter/Filter'
import convertNumberToTime from '../../../shared/convertNumberToTime'
import ColorPicker from '../../../hoc/ColorPicker'
import Loader from '../../Common/components/spinner'
import Media from 'react-media'
import theme from '../../Common/theme'
import ColorIcon from '../../../assets/icons/ColorIcon'
import { endOfMonth, startOfMonth } from 'date-fns'
import {
    Project,
    ProjectColor,
    ProjectCount,
    ProjectHourSum,
    ProjectName,
    ProjectsWrapper,
    LegendItem,
    LegendItemHours,
    ColorButton,
    ColorButtonContainer,
    Header,
    HeaderButtons,
    Navigation,
    CloseButton,
    DrawerTitleWrapper,
    Title,
    HourSumWrapper,
} from './Projects.components'
//const { RangePicker } = DatePicker;

const Projects = ({ totalHours, logs }, ...props) => {
    const { setShowProjects, sendColor, getProjectsDataByDate, getHolidays, holidayRecap, projectsByDate, dateValue } =
        useContext(WorkLogsContext)
    const [loading, setLoading] = useState(false)
    const [filterOpened, setFilterOpened] = useState(false)
    const [currentProject, setCurrentProject] = useState()
    const [currentProjectColor, setCurrentProjectColor] = useState({
        hex: '#000000',
        hsl: { h: 0, s: 0, l: 0 },
        hsv: { h: 0, s: 0, v: 0 },
    })
    const [visible, setVisible] = useState(false)

    const [filterValue, setFilterValue] = useState({
        startDate: startOfMonth(new Date(dateValue)),
        endDate: endOfMonth(new Date(dateValue)),
    })

    useMemo(() => {
        setFilterValue({ startDate: startOfMonth(new Date(dateValue)), endDate: endOfMonth(new Date(dateValue)) })
    }, [dateValue])

    useEffect(() => {
        const fetchData = async (startDate, endDate) => {
            setLoading(true)
            await getProjectsDataByDate(startDate, endDate)
            await getHolidays(startDate, endDate)
            setLoading(false)
        }
        if (filterValue.startDate && filterValue.endDate) fetchData(filterValue.startDate, filterValue.endDate)
        if (!filterValue.startDate && !filterValue.endDate) fetchData()
    }, [filterValue, dateValue, getProjectsDataByDate, getHolidays])

    const [topMargin, setTopMargin] = useState(178)
    useEffect(() => {
        if (currentProject) setCurrentProjectColor(currentProject.projectColor)
    }, [currentProject])

    const handleChangeComplete = value => {
        sendColor(value.hex, currentProject)
        // setCurrentProjectColor(value);
    }
    const handleChange = value => {
        setCurrentProjectColor(value)
    }

    const closePicker = () => {
        setVisible(false)
    }

    const handleClosePicker = useCallback(event => {
        if (event.keyCode === 27) setVisible(false)
    }, [])

    const closeDrawer = useCallback(() => {
        setShowProjects(false)
        setFilterValue({ startDate: '', endDate: '' })
    }, [setShowProjects])

    const openPicker = (project, i) => {
        setCurrentProject(project)
        setTopMargin(180 + i * 45)
        setVisible(true)
    }

    useMemo(() => {
        if (visible) {
            window.addEventListener('keydown', handleClosePicker)
        } else {
            window.removeEventListener('keydown', handleClosePicker)
        }

        return () => window.removeEventListener('keydown', handleClosePicker)
    }, [handleClosePicker, visible])

    const projectsToMap = projectsByDate.length > 0 ? projectsByDate : null
    const uniqueProjectsToMap =
        projectsToMap &&
        projectsToMap.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj['id']).indexOf(obj['id']) === pos
        })
    const projectsByDateTotalHours =
        uniqueProjectsToMap && uniqueProjectsToMap.reduce((sum, el) => (sum += el.workedHours), 0)

    const projectsList =
        uniqueProjectsToMap &&
        uniqueProjectsToMap.map((project, i) => {
            return (
                <Media
                    queries={{
                        small: '(max-width: 1179px)',
                        mediumAndLarge: '(min-width: 1180px)',
                    }}>
                    {matches => (
                        <>
                            {matches.small && (
                                <ProjectsWrapper>
                                    <Project>
                                        <ProjectColor color={project.projectColor} />
                                        <LegendItem>
                                            <ProjectName>{project.name}</ProjectName>
                                            <LegendItemHours>
                                                {convertNumberToTime(project?.workedHours)}h
                                            </LegendItemHours>
                                        </LegendItem>
                                    </Project>
                                </ProjectsWrapper>
                            )}
                            {matches.mediumAndLarge && (
                                <ProjectsWrapper>
                                    <Project>
                                        <ProjectColor color={project.projectColor} />
                                        <LegendItem>
                                            <ProjectName>{project.name}</ProjectName>
                                            <LegendItemHours className="hour-container">
                                                {convertNumberToTime(project?.workedHours)}h
                                            </LegendItemHours>
                                            <ColorButtonContainer>
                                                <ColorButton onClick={() => openPicker(project, i)}>
                                                    <ColorIcon />
                                                </ColorButton>
                                            </ColorButtonContainer>
                                        </LegendItem>
                                    </Project>
                                </ProjectsWrapper>
                            )}
                        </>
                    )}
                </Media>
            )
        })

    return (
        <>
            <Header>
                <HeaderButtons>
                    <div />
                    <CloseButton onClick={closeDrawer}>
                        <CloseIcon />
                    </CloseButton>
                </HeaderButtons>
                <Navigation>
                    <DrawerTitleWrapper>
                        <Title>Moje projekty</Title>
                        <ProjectCount>{uniqueProjectsToMap?.length}</ProjectCount>
                    </DrawerTitleWrapper>
                    <Filter
                        value={filterValue}
                        onChange={setFilterValue}
                        dateValue={dateValue}
                        opened={filterOpened}
                        setOpened={setFilterOpened}
                    />
                </Navigation>
                {!loading && projectsList && (
                    <ProjectHourSum>
                        Suma godzin:
                        <div style={{ color: theme.colors.blue }}>
                            <HourSumWrapper>
                                {convertNumberToTime(projectsByDateTotalHours + holidayRecap)}
                            </HourSumWrapper>
                            h
                        </div>
                    </ProjectHourSum>
                )}
            </Header>
            {visible && !loading && (
                <>
                    <div
                        style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }}
                        onClick={closePicker}
                    />
                    <ColorPicker
                        color={currentProjectColor}
                        topMargin={topMargin}
                        onChangeComplete={handleChangeComplete}
                        onChange={handleChange}></ColorPicker>
                </>
            )}
            {!loading && projectsList ? (
                <>
                    {projectsList}
                    <ProjectsWrapper>
                        <Project>
                            <ProjectColor color={theme.colors.white} />
                            <LegendItem>
                                <ProjectName>URLOP</ProjectName>
                                <LegendItemHours>{convertNumberToTime(holidayRecap)}h</LegendItemHours>
                            </LegendItem>
                        </Project>
                    </ProjectsWrapper>
                </>
            ) : (
                !filterOpened && <Loader isProjectLoader={true} />
            )}
        </>
    )
}

export default Projects

import React, { useCallback } from 'react'
import { Tooltip } from 'antd'
import Clipboard from 'react-clipboard.js'
import Media from 'react-media'
import {
    EmployeeWrapper,
    HeaderWrapper,
    Avatar,
    NameWrapper,
    InfoWrapper,
    EmailButton,
    EmailButtonIcon,
    PhoneButton,
    PhoneButtonIcon,
} from './Employee.components'

const Employee = ({ showModal, ...props }) => {
    const handleModal = useCallback(() => {
        showModal(true, props.phoneNumber)
    }, [showModal, props.phoneNumber])

    return (
        <EmployeeWrapper>
            <HeaderWrapper>
                <Avatar src={props.gravatar}></Avatar>
                <NameWrapper className="header">{props.fullName}</NameWrapper>
            </HeaderWrapper>

            <Media
                queries={{
                    small: '(max-width: 599px)',
                    medium: '(min-width: 600px) and (max-width: 1199px)',
                    large: '(min-width: 1200px)',
                }}>
                {matches => (
                    <>
                        {matches.small && (
                            <InfoWrapper>
                                <EmailButton>
                                    <a href={`mailto: ${props.email}`}>
                                        <EmailButtonIcon />
                                    </a>
                                </EmailButton>
                                <PhoneButton onClick={handleModal}>
                                    <PhoneButtonIcon />
                                </PhoneButton>
                            </InfoWrapper>
                        )}
                        {(matches.medium || matches.large) && (
                            <InfoWrapper>
                                <Clipboard
                                    component={EmailButton}
                                    data-clipboard-text={props.email}
                                    onClick={props.copied}>
                                    <Tooltip trigger="hover" mouseLeaveDelay={0} title={props.email}>
                                        <EmailButtonIcon />
                                    </Tooltip>
                                </Clipboard>
                                <PhoneButton onClick={handleModal}>
                                    <PhoneButtonIcon />
                                </PhoneButton>
                            </InfoWrapper>
                        )}
                    </>
                )}
            </Media>
        </EmployeeWrapper>
    )
}

export default Employee

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 18px;
    height: 18px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const ShowPwSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>show-pw</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect class="cls-1" fill="none" width="24" height="24" />
                <path
                    class="cls-2"
                    fill="#212529"
                    d="M12,16A4,4,0,0,1,9.17,9.17a4.1,4.1,0,0,1,5.66,0,4,4,0,0,1,0,5.66h0A4,4,0,0,1,12,16Zm0-6a2,2,0,0,0-1.41,3.41,2,2,0,0,0,2.82,0h0A2,2,0,0,0,12,10Z"
                />
                <path
                    class="cls-2"
                    fill="#212529"
                    d="M12,20A10.12,10.12,0,0,1,2,12.23a.9.9,0,0,1,0-.46A10.12,10.12,0,0,1,12,4a10.12,10.12,0,0,1,10,7.77.9.9,0,0,1,0,.46A10.12,10.12,0,0,1,12,20ZM4,12A8.29,8.29,0,0,0,20,12,8.29,8.29,0,0,0,4,12Z"
                />
                <path
                    class="cls-2"
                    fill="#212529"
                    d="M12,20A10.12,10.12,0,0,1,2,12.23a.9.9,0,0,1,0-.46A10.12,10.12,0,0,1,12,4a10.12,10.12,0,0,1,10,7.77.9.9,0,0,1,0,.46A10.12,10.12,0,0,1,12,20ZM4,12A8.29,8.29,0,0,0,20,12,8.29,8.29,0,0,0,4,12Z"
                />
            </g>
        </g>
    </svg>
)

const ShowPasswordIcon = props => <SuperIcon component={ShowPwSvg} {...props} />

export default ShowPasswordIcon

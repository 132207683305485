import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: ${props => (props.width ? props.width : '24px')};
    height: ${props => (props.height ? props.height : '24px')};

    padding-top: 2px;

    .cls-1 {
        fill: ${props => (props.highlight ? theme.colors.blue : theme.colors.primary)} !important;
    }
`

const LinkMenuSvg = () => (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            className="cls-1"
            d="M12 6V18.97L7.79 17.16L7 16.82L6.21 17.16L2 18.97V6H12ZM16 0H5.99C4.89 0 4 0.9 4 2H14C15.1 2 16 2.9 16 4V17L18 18V2C18 0.9 17.1 0 16 0ZM12 4H2C0.9 4 0 4.9 0 6V22L7 19L14 22V6C14 4.9 13.1 4 12 4Z"
        />
    </svg>
)

const LinkMenuIcon = props => <SuperIcon className="icon" component={LinkMenuSvg} {...props} />

export default LinkMenuIcon

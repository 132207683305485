import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Tooltip } from 'antd'
import moment from 'moment'
import { WorkLogsContext } from '../../context/WorkLogsContext'
import Media from 'react-media'
import { LogInput, LogInputWrapper } from './LogInput.components'

const Input = React.forwardRef((props, inputRef) => {
    const [worklogValue, setWorklogValue] = useState(props.value)
    const [shortValue, setShortValue] = useState(props.value)
    const [transparentizeValue, setTransparentizeValue] = useState({
        background: 0,
        border: 0,
    })
    const [isFocused, setIsFocused] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const { logsChanged, cancelSendAgainError, setCancelSendAgainError } = useContext(WorkLogsContext)

    useEffect(() => {
        if (props.disabled || moment(props.date).isAfter(moment())) {
            setTransparentizeValue({
                background: 0.76,
                border: props.value !== '' ? 1 : 0.76,
            })
        } else if (props.value === '') {
            setTransparentizeValue({
                background: 0.84,
                border: 0,
            })
        }
    }, [props.date, props.disabled, props.value, cancelSendAgainError])

    useEffect(() => {
        if (worklogValue === '') return

        if (props.disabled || moment(props.date).isAfter(moment())) {
            setTransparentizeValue({
                background: 0.76,
                border: isFocused ? 0 : 1,
            })
        } else {
            setTransparentizeValue({
                background: 0,
                border: 0,
            })
        }
    }, [worklogValue, props.date, isFocused, props.disabled])

    useEffect(() => {
        setWorklogValue(props.value)
    }, [props.value])

    useEffect(() => {
        if (cancelSendAgainError === true) {
            setWorklogValue(props.value)
            setCancelSendAgainError(false)
        }
    }, [cancelSendAgainError, setCancelSendAgainError, props.value])

    const validate = useCallback(val => {
        let stringVal = val.toString()
        const colonIndex = stringVal.indexOf(':')
        if (colonIndex < 0) return val
        const isEndingWithColon = stringVal.endsWith(':')
        if (isEndingWithColon) {
            const newValue = stringVal.replace(':', '')
            setWorklogValue(newValue)
            return newValue
        }
        const startsWithColon = stringVal.startsWith(':')
        if (startsWithColon) {
            const timeFormat = stringVal.match(/([\d]*):([\d]+)/)
            const newValue = `0:${timeFormat[2]}`
            setWorklogValue(newValue)
            return newValue
        }
        const parts = stringVal.split(':')
        const hours = parts[0]
        const minutes = parts[1]
        if (parts.length === 2 && minutes && hours && minutes.length === 1) {
            const newValue = `${hours}:${minutes.padStart(2, '0')}`
            setWorklogValue(newValue)
            return newValue
        }
        return val
    }, [])

    const addLog = (e, value, project) => {
        if (e.keyCode && e.keyCode !== 13) return
        if (e.keyCode && e.keyCode === 13) {
            e.target.blur()
            return
        }
        const newValue = validate(value)
        isChanged && logsChanged(newValue, props.date, project)
        e.target.blur()
        setIsChanged(false)
    }

    useEffect(() => {
        let stringVal = worklogValue.toString()
        const colonIndex = stringVal.indexOf(':')
        let displayValue = 0
        if (colonIndex > 0) {
            displayValue = stringVal.substring(0, colonIndex)
            displayValue += '+'
        } else {
            displayValue = stringVal
        }
        setShortValue(displayValue)
    }, [worklogValue])

    const changeWorklogValue = e => {
        setIsChanged(true)
        // regex łapie wszystkie przecinki, średniki i kropki, zamienia na dwukropki, gdyż system mamy godzinowy
        let value = e.target.value.replace(/\.s*|;s*|,s*/g, ':')
        let checkIfValid = /^\d*:?([0-5]?[0-9])?$/.test(value)
        if (checkIfValid === true) {
            setWorklogValue(value)
        }
    }

    return (
        <Media
            queries={{
                small: '(max-width: 1199px)',
                mediumAndLarge: '(min-width: 1200px)',
            }}>
            {matches => (
                <>
                    {matches.small && (
                        <LogInputWrapper>
                            <LogInput
                                type="text"
                                pattern="\d*"
                                inputMode="decimal"
                                borderColor={props.borderColor}
                                bgColor={props.bgColor}
                                key={`input-${props.date}`}
                                transparentizeBorder={transparentizeValue.border}
                                transparentizeBackground={transparentizeValue.background}
                                value={isFocused ? worklogValue : shortValue}
                                disabled={props.disabled}
                                onChange={e => changeWorklogValue(e)}
                                onFocus={() => {
                                    setIsFocused(true)
                                }}
                                onBlur={e => {
                                    addLog(e, worklogValue, props.project)
                                    setIsFocused(false)
                                }}
                                onKeyUp={e => addLog(e, worklogValue, props.project)}
                                placeholder="hh:mm"
                                data-cy="worklog-input"
                                ref={inputRef}
                            />
                        </LogInputWrapper>
                    )}
                    {matches.mediumAndLarge && (
                        <Tooltip trigger="hover" mouseLeaveDelay={0} title={props.project.name}>
                            <LogInputWrapper>
                                <LogInput
                                    borderColor={props.borderColor}
                                    bgColor={props.bgColor}
                                    key={`input-${props.date}`}
                                    transparentizeBorder={transparentizeValue.border}
                                    transparentizeBackground={transparentizeValue.background}
                                    value={isFocused ? worklogValue : shortValue}
                                    disabled={props.disabled}
                                    onChange={e => changeWorklogValue(e)}
                                    onFocus={() => {
                                        setIsFocused(true)
                                    }}
                                    onBlur={e => {
                                        addLog(e, worklogValue, props.project)
                                        setIsFocused(false)
                                    }}
                                    onKeyUp={e => addLog(e, worklogValue, props.project)}
                                    placeholder="hh:mm"
                                    data-cy="worklog-input"
                                />
                            </LogInputWrapper>
                        </Tooltip>
                    )}
                </>
            )}
        </Media>
    )
})

export default Input

import styled from 'styled-components'
import holidayPattern from '../../../assets/icons/holiday-pattern.svg'
import theme from '../../Common/theme'

export const ProjectsWrapper = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    justify-content: flex-start;

    @media only screen and (max-width: 1199px) {
        flex-wrap: wrap;
    }
`

export const Wrapper = styled.div`
    min-height: 30px;
    @media only screen and (max-width: 1199px) {
        min-height: 0px;
        width: 100%;
        margin-right: 16px;
    }
`

export const MobileLogContentWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    min-height: 48px;
    width: 100%;
    border-radius: 4px;
    background-color: ${theme.colors.white};
    margin-bottom: 8px;
    border: ${props => (props.weekend ? `solid ${theme.borders.thin} ${theme.colors.border}` : 'none')};
    background-color: ${props => (props.weekend ? theme.colors.athensGray : theme.colors.white)};
    background-image: ${props => (props.isHoliday ? `url(${holidayPattern})` : 'none')};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

export const ProjectNameWrapper = styled.div`
    width: 64%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${props => (props.grey ? theme.colors.gray : theme.colors.primary)};
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
`

export const LogWrapper = styled.div`
    width: 36%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const DailySum = styled.div`
    width: 100%;
    display: ${props => (props.hide === 'hidden' ? 'none' : 'flex')};
    flex-direction: row;
    height: 20px;
    justify-content: flex-start;
    margin-bottom: 8px;
`
export const InputsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

// export const CommIcon = styled(CommentIcon)`
//   width: 18px;
//   height: 18px;
//   margin-left: 16px;
//   margin-right: 4px;

//   .comment-button{
//     fill: #acb5bd;
//   }
// `

export const HoursWrapper = styled.div`
    font-size: ${theme.fontSizes.medium};
    color: ${props => props.color};
    margin-top: -1px;
`

// export const LogsCountWrapper = styled.div`
//   font-size: 14px;
//   color: #acb5bd;
//   margin-top: -3px;
// `

import React, { useState, useContext, useRef, useEffect } from 'react'
import { Formik } from 'formik'
import { Redirect } from 'react-router-dom'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import {
    StyledInput,
    Link,
    StyledInputPassword,
    Label,
    InputPasswordContainer,
    IconPassword,
} from '../Common/components/form'
import { ButtonPrimary } from '../Common/components/buttons'
import { LoginPageContainer, LoginWrapper, StyledAlert, ActionsContainer } from './Login.components'
import { login } from './services/useAuthorization'
import { ROUTES } from '../../Routes'
import { UserContext } from '../Common/contexts/userContext'
import HidePasswordIcon from '../../assets/icons/HidePasswordIcon'
import ShowPasswordIcon from '../../assets/icons/ShowPasswordIcon'
import LoginGorrionIcon from '../../assets/icons/LoginGorrionIcon'
import LogoIcon from '../../assets/icons/LogoIcon'

const Login = () => {
    const user = useContext(UserContext)
    const [credentials] = useState({
        username: '',
        password: '',
    })
    const [loading, setLoading] = useState(false)
    const [{ error, isAuthorized }, setStatus] = useState({
        error: false,
        isAuthorized: localStorage.getItem('token'),
    })

    const [showPasswordIcon, setShowPasswordIcon] = useState(false)

    const inputRef = useRef(null)

    const submitForm = async credentials => {
        setLoading(true)
        const validatedCredentials = {
            ...credentials,
            username:
                credentials.username.indexOf('@') > -1
                    ? credentials.username.substring(0, credentials.username.indexOf('@'))
                    : credentials.username,
        }

        return login(validatedCredentials).then(res => {
            if (!res.error) {
                localStorage.setItem('token', res.token)
                setStatus(() => ({
                    error: false,
                    isAuthorized: true,
                }))
                setLoading(false)
            } else {
                setStatus(() => ({
                    error: true,
                    isAuthorized: false,
                }))
                setLoading(false)
            }
        })
    }

    const disableError = () => {
        setStatus(() => ({
            error: false,
            isAuthorized: isAuthorized,
        }))
    }

    let redirect = null
    if (isAuthorized) {
        redirect = <Redirect to="/worklogs" />
        user.get()
    }

    useEffect(() => {
        inputRef.current.focus()
    }, [])

    return (
        <LoginPageContainer>
            {redirect}
            <LoginWrapper>
                <LogoIcon marginBottom="40px" />
                <Formik
                    initialValues={{
                        username: credentials.username || '',
                        password: credentials.password || '',
                    }}
                    onSubmit={async (values, actions) => {
                        await submitForm(values)
                    }}
                    validate={disableError}
                    render={props => (
                        <Form onSubmit={props.handleSubmit}>
                            <Label className="label">Nazwa użytkownika</Label>
                            <Form.Item>
                                <StyledInput
                                    value={props.values.username}
                                    onChange={props.handleChange}
                                    name="username"
                                    data-cy="email-input"
                                    hasError={error}
                                    ref={inputRef}></StyledInput>
                            </Form.Item>
                            <Label>Hasło</Label>
                            <Form.Item>
                                <InputPasswordContainer>
                                    <StyledInputPassword
                                        name="password"
                                        autoComplete="off"
                                        type={showPasswordIcon ? 'text' : 'password'}
                                        value={props.values.password}
                                        onChange={props.handleChange}
                                        hasError={error}
                                        data-cy="password-input"></StyledInputPassword>
                                    <IconPassword
                                        showPasswordIcon={showPasswordIcon}
                                        onClick={() => {
                                            setShowPasswordIcon(!showPasswordIcon)
                                        }}>
                                        {showPasswordIcon ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                                    </IconPassword>
                                </InputPasswordContainer>
                            </Form.Item>
                            <StyledAlert
                                data-cy="error-alert"
                                show={(props.touched.username || props.touched.password) && error}>
                                Niepoprawny adres e-mail lub hasło.
                            </StyledAlert>
                            <ActionsContainer>
                                <Link href={ROUTES.PASSWORD_LINK}>Zapomniałeś hasła?</Link>
                                <ButtonPrimary loading={loading} htmlType="submit" data-cy="submit-login">
                                    Zaloguj
                                </ButtonPrimary>
                            </ActionsContainer>
                        </Form>
                    )}
                />
            </LoginWrapper>
            <LoginGorrionIcon />
        </LoginPageContainer>
    )
}

export default Login

import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import birdShowAnimation from '../assets/animations/wrobelek-show.json'
import birdHideAnimation from '../assets/animations/wrobelek-hide.json'
import CloseIcon from '../assets/icons/CloseIcon'
import { notification } from 'antd'
import { UserContext } from '../components/Common/contexts/userContext'

const AnimationContent = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
`

const descriptions = [
    'Cześć! Dzisiaj będę Twoim coachem i chciałem tylko powiedzieć: Tak trzymaj!',
    'Hej to ja wróbel6k! Dużo pracujesz, patrząc po godzinach. Szacun.',
    'Mam nadzieję, że czasem odpoczywasz...',
    'Pamiętasz ten spinacz z windowsa? Ja jestem lepszy. Nie porównuj nas proszę...',
    'Wyszukałem "najlepsze cytaty o pracy" i dostałem coś takiego: Celem pracy jest zarabianie wolnego czasu. Brzmiało okej, ale ja się nie znam, wróblem jestem.',
    'A godzinki wpadają i wpadają i wpadają...',
    'Kiedyś przyszedł taki gość do apteki i poprosił o ten nowy lek na chciwość, a potem dodał: "Ale tak dużo dużo dużo!"',
    'Piątka skrzydłem!',
    'Godziny wpisane. Życzę Ci wspaniałej reszty dnia!',
    'A będzie ich tylko więcej...',
    'Zawsze miło jak ktoś taki jak Ty odwiedzi wieprzugę i wpisze godzinki. Aww',
]

const specialDescriptions = [
    'Cześć! Dzisiaj będę Twoim coachem i chciałem tylko powiedzieć: Będzie tylko gorzej!',
    'Każda praca hańbi.',
    'Sukces to wymówka ludzi, którzy nie mają motywacji by doświadczać porażek.',
    'Wygrana to taka gorsza przegrana.',
    'Nie bij rekordów, niech one biją Ciebie.',
    'Nigdy nie nastawiaj się na sukces.',
    'Nie ucz się na błędach.',
    'Zawsze bądź złej myśli.',
    'Rób rzeczy od A, do B.',
    'Nigdy nie próbuj. Niczego.',
    'Każdy dzień jest dobry, żeby się poddać.',
    'Uwierz w siebie, a niczego nie osiągniesz.',
    'Nie bądź pierwszy, bądź szesnasty',
    'Niech symbolem Twojego życia będzie zero.',
    'Wystarczy nie chcieć.',
    'Rozgość się w swojej strefie komfortu.',
    'Minimum to zbyt wiele.',
    'Nie liczy się to jak zaczynasz. Ani to jak kończysz.',
    'Nie sięgaj dalej niż po herbatę.',
    'Najgorsze przed Tobą.',
    'Gorrions send their regards',
    'Najlepsze za nami',
    'Ten, kto przeniósł górę, był bardzo zmęczony.',
    'Godziny wpisane. Reszta dnia może być tylko gorsza!',
]

const SparrowAnimation = ({ start, ...props }) => {
    const [showBirdStop, setShowBirdStop] = useState(true)
    const [showBirdPause, setShowBirdPause] = useState(false)
    const [hideBirdStop, setHideBirdStop] = useState(true)
    const [hideBirdShowAnimation, setHideBirdShowAnimation] = useState(true)
    const [hideBirdHideAnimation, setHideBirdHideAnimation] = useState(true)

    //const [toThrottle, setToThrottle] = useState(false)
    const user = useContext(UserContext)

    let prolongBirdVisible = 0

    function throttle(callback, limit) {
        var waiting = false // Initially, we're not waiting
        return function () {
            // We return a throttled function
            if (!waiting) {
                // If we're not waiting
                callback.apply(this, arguments) // Execute users function
                waiting = true // Prevent future invocations
                setTimeout(function () {
                    // After a period of time
                    waiting = false // And allow future invocations
                }, limit)
            }
        }
    }

    const openNotification = () => {
        notification.config({
            placement: 'bottomRight',
            bottom: 80,
            duration: 5,
            rtl: false,
        })
        notification.open({
            message: 'wróbel6k',
            description:
                user.details.phoneNumber === '504990734'
                    ? specialDescriptions[Math.floor(Math.random() * specialDescriptions.length)]
                    : descriptions[Math.floor(Math.random() * descriptions.length)],
            className: 'notification-class',
            closeIcon: <CloseIcon />,
            style: {
                width: 360,
            },
        })
    }

    const completeBirdShowAnimation = () => {
        openNotification()
        prolongBirdVisible += 1
        setShowBirdPause(true)
        startBirdHideAnimation()
    }

    const completeBirdHideAnimation = () => {
        setHideBirdHideAnimation(true)
        setHideBirdStop(true)
    }

    const startBirdShowAnimation = () => {
        setShowBirdStop(false)
        setShowBirdPause(false)
        setHideBirdShowAnimation(false)
    }

    const startBirdHideAnimation = () => {
        setTimeout(() => {
            if (prolongBirdVisible > 1) {
                prolongBirdVisible -= 1
                return
            }

            prolongBirdVisible = 0
            setShowBirdPause(true)
            setShowBirdStop(true)
            setHideBirdShowAnimation(true)
            setHideBirdStop(false)
            setHideBirdHideAnimation(false)
        }, 5000)
    }

    useEffect(() => {
        if (start) return throttle(startBirdShowAnimation, 10000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start])

    const showBirdOptions = {
        loop: false,
        autoplay: false,
        animationData: birdShowAnimation,
        rendererSettings: {
            clearCanvas: true,
        },
    }

    const hideBirdOptions = {
        loop: false,
        autoplay: false,
        animationData: birdHideAnimation,
        rendererSettings: {
            clearCanvas: true,
        },
    }

    return (
        <>
            <AnimationContent hidden={hideBirdShowAnimation}>
                <Lottie
                    options={showBirdOptions}
                    height={180}
                    width={180}
                    isStopped={showBirdStop}
                    isPaused={showBirdPause}
                    eventListeners={[
                        {
                            eventName: 'complete',
                            callback: () => completeBirdShowAnimation(),
                        },
                    ]}
                    isClickToPauseDisabled={true}
                />
            </AnimationContent>
            <AnimationContent hidden={hideBirdHideAnimation}>
                <Lottie
                    options={hideBirdOptions}
                    height={180}
                    width={180}
                    isStopped={hideBirdStop}
                    eventListeners={[
                        {
                            eventName: 'complete',
                            callback: () => completeBirdHideAnimation(),
                        },
                    ]}
                    isClickToPauseDisabled={true}
                />
            </AnimationContent>
        </>
    )
}

export default SparrowAnimation

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    padding-right: 2px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const CloseSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" fill="none" width="24" height="24" />
                <path
                    className="cls-2"
                    d="M18.66,17a1.19,1.19,0,0,1,0,1.66,1.21,1.21,0,0,1-.83.34,1.23,1.23,0,0,1-.83-.34l-5-5-5,5a1.23,1.23,0,0,1-.83.34,1.21,1.21,0,0,1-.83-.34,1.19,1.19,0,0,1,0-1.66l5-5-5-5A1.17,1.17,0,0,1,7,5.34l5,5,5-5A1.17,1.17,0,0,1,18.66,7l-5,5Z"
                />
            </g>
        </g>
    </svg>
)

const CloseIcon = props => <SuperIcon component={CloseSvg} {...props} />

export default CloseIcon

import theme from '../../../Common/theme'
import styled, { css } from 'styled-components'
import { Button, DatePicker } from 'antd'

export const TodayButton = styled(Button)`
    && {
        cursor: ${props => (props.ispending ? 'not-allowed' : 'pointer')};
        margin-right: 16px;
        width: 74px;
        height: 36px;
        border-radius: 4px !important;
        border: solid ${theme.borders.thin} ${theme.colors.border};
        background-color: ${theme.colors.white};
        color: ${theme.colors.primary};
        &::after {
            animation-duration: 0s !important;
        }
        &:hover,
        &:focus,
        &:active {
            border-color: ${props => (!props.ispending ? theme.colors.border : theme.colors.disabled)};
            background-color: ${props => (!props.ispending ? theme.colors.athensGray : theme.colors.disabled)};
        }
    }
`

export const StyledLabel = styled.span`
    color: ${theme.colors.primary};
`

export const changeMonthButtonBase = css`
    && {
        cursor: ${props => (props.ispending ? 'not-allowed' : 'pointer')};
        width: 36px;
        height: 36px;
        border: none;
        background-color: ${theme.colors.white};
        box-shadow: none;
        padding: 0px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50% !important;

        &::after {
            animation-duration: 0s !important;
        }
        &:focus,
        &:hover,
        &:active {
            border-radius: 50% !important;
            background-color: ${theme.colors.hover};
            border-color: ${props => (!props.ispending ? theme.colors.hover : theme.colors.disabled)};

            color: ${props => (!props.ispending ? theme.colors.primary : theme.colors.primary)};
        }
    }
`
export const PreviousMonthButton = styled(Button)`
    ${props => changeMonthButtonBase}
    && {
        margin-right: 8px;
    }
`

export const NextMonthButton = styled(Button)`
    ${props => changeMonthButtonBase}
`

export const PickDate = styled(DatePicker)`
    && {
        cursor: ${props => (props.ispending ? 'not-allowed' : 'pointer')};
        width: 164px;
        height: 36px;
        background-color: ${theme.colors.white};
        margin-left: 16px !important;
        border-radius: 4px !important;

        &:focus,
        &:active {
            border-color: ${props => (!props.ispending ? theme.colors.border : theme.colors.disabled)} !important;
            box-shadow: none !important;
            background-color: ${props => (!props.ispending ? theme.colors.athensGray : theme.colors.disabled)};
        }

        &:hover {
            border-color: ${theme.colors.border} !important;
            box-shadow: none !important;
            background-color: ${props => (!props.ispending ? theme.colors.athensGray : theme.colors.disabled)};
            color: ${theme.colors.primary};
        }

        div > input {
            cursor: ${props => (props.ispending ? 'not-allowed' : 'pointer')};
            max-width: 168px;
            height: 36px;
            padding-top: 4px;
            color: ${theme.colors.primary} !important;
            text-transform: capitalize;
            font-size: ${theme.fontSizes.medium};
        }

        div > i {
            color: ${theme.colors.disabled};
        }
    }
`

export const NavigationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 352px;
`

import React, { useState } from 'react'
import { Formik } from 'formik'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { StyledInput, Label } from '../Common/components/form'
import { ButtonPrimary } from '../Common/components/buttons'
import { LoginPageContainer, LoginWrapper, PasswordInformation } from './Login.components'
import { AlignRightContainer } from '../Common/components/layout'
import { ErrorMessage } from '../Common/components/alert'
import { H2 } from '../Common/components/typography'
import { sendPasswordResetLink } from './services/useAuthorization'
import LogoIcon from '../../assets/icons/LogoIcon'

const views = {
    LINK: 'SEND_LINK',
    SUCCESS: 'LINK_SENT',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
}
const PasswordLink = props => {
    const [email] = useState('')

    const [isEmailValid, setIsEmailValid] = useState(true)

    const [currentView, setCurrentView] = useState(
        props.location.search === '?token-expired' ? views.TOKEN_EXPIRED : views.LINK,
    )

    const validateEmail = email => {
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
        const emailvalidationResult = emailRegex.test(email)
        setIsEmailValid(emailvalidationResult)
        return emailvalidationResult
    }
    const sendEmail = async credentials => {
        if (validateEmail(credentials.email)) {
            sendPasswordResetLink({ email: credentials.email }).then(
                res => {
                    setCurrentView(views.SUCCESS)
                },
                error => {
                    setCurrentView(views.SUCCESS)
                },
            )
        }
    }
    return (
        <LoginPageContainer>
            <LoginWrapper>
                <LogoIcon marginBottom="40px" />
                <H2>{currentView !== views.TOKEN_EXPIRED ? 'Odzyskiwanie hasła' : 'Mamy problem'}</H2>
                {currentView !== views.SUCCESS ? (
                    <>
                        <PasswordInformation>
                            {currentView === views.LINK
                                ? `Wpisz swój adres e-mail. Wyślemy na niego link
                                    do ustawienia nowego hasła.`
                                : `Wygląda na to, że Twój token wygasł lub jest nieprawidłowy. Będziemy musieli wysłać 
                                    go ponownie.`}
                        </PasswordInformation>
                        <Formik
                            initialValues={{
                                email: email || '',
                            }}
                            onSubmit={async (values, actions) => {
                                await sendEmail(values)
                            }}
                            render={props => (
                                <Form onSubmit={props.handleSubmit}>
                                    <Label className="label">Adres e-mail</Label>
                                    <Form.Item>
                                        <StyledInput
                                            value={props.values.email}
                                            onChange={props.handleChange}
                                            onKeyUp={() => {
                                                if (!isEmailValid) validateEmail(props.values.email)
                                            }}
                                            name="email"
                                            hasError={!isEmailValid}
                                        />
                                        <ErrorMessage>
                                            {isEmailValid ? '' : 'Niepoprawny format adresu e-mail'}
                                        </ErrorMessage>
                                    </Form.Item>
                                    <AlignRightContainer marginTop={isEmailValid ? '64px' : '34px'}>
                                        <ButtonPrimary htmlType="submit">
                                            {currentView === views.LINK ? 'Odzyskaj' : 'Wyślij ponownie'}
                                        </ButtonPrimary>
                                    </AlignRightContainer>
                                </Form>
                            )}
                        />
                    </>
                ) : (
                    <PasswordInformation marginBottom={'0px'}>
                        Wysłaliśmy link z&nbsp;odzyskiwaniem hasła na&nbsp;podany adres&nbsp;e-mail. Sprawdź swoją
                        pocztę!
                    </PasswordInformation>
                )}
            </LoginWrapper>
        </LoginPageContainer>
    )
}

export default PasswordLink

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    padding-right: 2px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const HomeSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" fill="none" width="24" height="24" />
                <path
                    className="cls-2"
                    d="M11,22A9,9,0,0,1,11,4a1,1,0,0,1,1,1v7h7a1,1,0,0,1,1,1A9,9,0,0,1,11,22ZM10,6.07A7,7,0,1,0,17.93,14H11a1,1,0,0,1-1-1Z"
                />
                <path
                    className="cls-2"
                    d="M21,11H14a1,1,0,0,1-1-1V3a1,1,0,0,1,1-1,8,8,0,0,1,8,8A1,1,0,0,1,21,11ZM15,9h4.92A6,6,0,0,0,15,4.08Z"
                />
            </g>
        </g>
    </svg>
)

const HomeIcon = props => <SuperIcon component={HomeSvg} {...props} />

export default HomeIcon

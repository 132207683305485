import WorkLogsIcon from '../../../assets/icons/WorkLogsIcon'
import { Button } from 'antd'
import theme from '../../Common/theme'
import styled from 'styled-components'

export const HeaderWrapper = styled.div`
    font-size: ${theme.fontSizes.medium};
    height: 100%;
    color: ${theme.colors.inactive};
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-flow: row nowrap;
`

export const HourSumWrapper = styled.span`
    padding-left: 8px;
    padding-top: 5px;
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.big};
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 36px;

    @media only screen and (max-width: 1199px) {
        padding-left: 16px !important;
    }
`

export const Hour = styled.span`
    color: ${theme.colors.inactive};
    font-size: ${theme.fontSizes.medium};
    font-weight: 400;
    line-height: 20px;
    padding-top: 8px;
`

export const HourSumAfterSlash = styled.span`
    padding-left: 8px;
    padding-top: 8px;
    color: ${theme.colors.inactive};
    font-size: ${theme.fontSizes.medium};
    font-weight: 400;
    line-height: 20px;
`

export const Sum = styled.div`
    vertical-align: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    z-index: -1;
`

// export const ProjColor = styled.div`
//   border-radius: 50%;
//   background-color: ${(props) => props.bgColor};
//   border: 1px solid ${(props) => props.bdColor};
//   min-width: 8px;
//   min-height: 8px;
//   margin-right: 8px;
// `;

export const ProjectButton = styled(Button)`
    && {
        cursor: pointer;
        margin-right: 16px;
        height: 36px;
        border-radius: 4px !important;
        border: solid ${theme.borders.thin} ${theme.colors.border};
        background-color: ${theme.colors.white};
        color: ${theme.colors.disabled};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 10.5px;
        &::after {
            animation-duration: 0s !important;
        }
        &:hover,
        &:focus,
        &:active {
            border-color: ${theme.colors.border};
            background-color: ${theme.colors.athensGray};
            color: ${theme.colors.primary};
        }
    }
`

export const SumWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    opacity: 100%;
    animation: fadeIn 1.5s;
    z-index: 0;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

export const HourSumIcon = styled(WorkLogsIcon)`
    .cls-2 {
        fill: ${theme.colors.primary};
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

export const StyledLabel = styled.span`
    color: ${theme.colors.primary};
    padding-top: 2px;
    margin-left: 10px !important;
`

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'

const SuperIcon = styled(Icon)`
    height: ${props => (props.height ? props.height : '16px')};
    width: ${props => (props.width ? props.width : '16px')};
`

const FridayStarsSvg = () => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5459 5.33367L14.0726 4.16701L15.2393 3.64034C15.4993 3.52034 15.4993 3.15367 15.2393 3.03367L14.0726 2.50701L13.5459 1.33367C13.426 1.07367 13.0593 1.07367 12.9393 1.33367L12.4126 2.50034L11.2393 3.02701C10.9793 3.14701 10.9793 3.51367 11.2393 3.63367L12.406 4.16034L12.9326 5.33367C13.0526 5.59367 13.426 5.59367 13.5459 5.33367ZM8.23928 6.33367L7.17928 4.00034C6.94595 3.48034 6.19928 3.48034 5.96595 4.00034L4.90595 6.33367L2.57262 7.39367C2.05262 7.63367 2.05262 8.37367 2.57262 8.607L4.90595 9.667L5.96595 12.0003C6.20595 12.5203 6.94595 12.5203 7.17928 12.0003L8.23928 9.667L10.5726 8.607C11.0926 8.367 11.0926 7.627 10.5726 7.39367L8.23928 6.33367ZM12.9326 10.667L12.406 11.8337L11.2393 12.3603C10.9793 12.4803 10.9793 12.847 11.2393 12.967L12.406 13.4937L12.9326 14.667C13.0526 14.927 13.4193 14.927 13.5393 14.667L14.066 13.5003L15.2393 12.9737C15.4993 12.8537 15.4993 12.487 15.2393 12.367L14.0726 11.8403L13.5459 10.667C13.426 10.407 13.0526 10.407 12.9326 10.667Z"
            fill="#FEC707"
        />
    </svg>
)

const FridayStarsIcon = props => <SuperIcon component={FridayStarsSvg} {...props} />

export default FridayStarsIcon

import fetchAPI from '../../../services/fetchAPI'

const config = credentials => {
    return {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials, null, 2),
    }
}

export const login = async credentials => {
    const res = await fetch(
        process.env.REACT_APP_LOGIN_API_URL || process.env.REACT_APP_LOGIN_API_URL,
        config(credentials),
    )
    return res.json()
}

export const sendPasswordResetLink = async email => {
    return fetchAPI('/employees/send-password-reset-email', config(email))
}

export const resetPassword = async data => {
    return fetchAPI('/employees/change-password', config(data))
}

import React from 'react'
import { CustomPicker, CirclePicker } from 'react-color'
import { Saturation, Hue, EditableInput } from 'react-color/lib/components/common'
import { Card } from 'antd'
import styled from 'styled-components'
import theme from '../components/Common/theme'

const inputStyles = {
    input: {
        border: `solid ${theme.borders.thin} ${theme.colors.border}`,
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        outline: 'none',
        width: '166px',
        height: '32px',
        paddingLeft: '8px',
        color: `${theme.colors.primary}`,
    },
}

const Prefix = styled.div`
    width: 32px;
    height: 32px;
    border: solid ${theme.borders.thin} ${theme.colors.border};
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-right: none;
    background-color: ${theme.colors.background};
    text-align: center;
    padding-top: 4px;
    font-size: ${theme.fontSizes.mediumBig};
    color: ${theme.colors.inactive};
    font-family: 'SofiaProRegular';
    user-select: none;
`

const CustomPointer = styled.div.attrs(props => ({
    style: {
        backgroundColor: props.bgColor,
    },
}))`
    width: 24px;
    height: 24px;
    box-shadow: 0 2px 4px 0 rgba(33, 36, 41, 0.08);
    border: solid ${theme.borders.medium} ${theme.colors.white};
    border-radius: 50%;
    transform: translate(-12px, -12px);
    cursor: pointer;
`

const CustomSlider = styled.div.attrs(props => ({
    style: {
        backgroundColor: props.bgColor,
    },
}))`
    width: 16px;
    height: 16px;
    box-shadow: 0 2px 4px 0 rgba(33, 36, 41, 0.08);
    border: solid ${theme.borders.medium} ${theme.colors.white};
    position: relative;
    border-radius: 50%;
    transform: translate(-8px, -4px);
    cursor: pointer;
`

const SaturationWrapper = styled.div`
    height: 150px;
    position: relative;
    border-radius: 4px !important;

    & > div {
        border-radius: 4px !important;
        & > .saturation-white {
            border-radius: 4px !important;
            & > .saturation-black {
                border-radius: 4px !important;
            }
        }
    }
`

const HueWrapper = styled.div`
    height: 8px;
    position: relative;
    margin-top: 16px;

    & > div {
        border-radius: 4px !important;

        & > .hue-horizontal {
            border-radius: 4px !important;
        }
    }
`
const colors = [
    theme.colors.yellow,
    theme.colors.pink,
    theme.colors.blue,
    theme.colors.green,
    theme.colors.red,
    theme.colors.purple,
    theme.colors.primary,
    theme.colors.plum,
    theme.colors.disabled,
    theme.colors.monaLisa,
    theme.colors.cashmere,
    theme.colors.persianGreen,
    theme.colors.deepSeaGreen,
    theme.colors.minsk,
    theme.colors.ceriseRed,
]

const ColorPicker = props => {
    const Pointer = () => {
        return <CustomPointer bgColor={props.hex}></CustomPointer>
    }
    const Slider = () => {
        return <CustomSlider bgColor={props.hex}></CustomSlider>
    }

    return (
        <Card
            style={{
                width: '216px',
                position: 'fixed',
                padding: '8px',
                right: '89px',
                height: '332px',
                top: `${props.topMargin}px`,
            }}>
            <SaturationWrapper>
                <Saturation
                    {...props}
                    pointer={Pointer}
                    onChange={props.onChange}
                    onChangeComplete={props.onChangeComplete}
                />
            </SaturationWrapper>
            <HueWrapper>
                <Hue {...props} pointer={Slider} onChange={props.onChange} />
            </HueWrapper>
            <div
                style={{
                    height: '32px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginTop: '16px',
                }}>
                <Prefix>#</Prefix>
                <EditableInput
                    {...props}
                    style={inputStyles}
                    value={props.hex.substring(1)}
                    onChangeComplete={props.onChangeComplete}
                />
            </div>
            <div
                style={{
                    height: '32px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginTop: '16px',
                }}>
                <CirclePicker
                    {...props}
                    circleSize={16}
                    colors={colors}
                    circleSpacing={12}
                    onChangeComplete={props.onChangeComplete}></CirclePicker>
            </div>
        </Card>
    )
}

export default CustomPicker(ColorPicker)

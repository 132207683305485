import React, { useContext, useState } from 'react'
import OKLegend from './OKLegend'
import RenderCalendar from '../Layout/Calendar/RenderCalendar'
import { ContentWrapper, LayoutWrapper, StyledHeader, HeaderWrapper } from '../Common/components/header'
import { OKSystemContext } from './contexts/OKSystemContext'
import Media from 'react-media'
import styled from 'styled-components'
import { SpecialHeaderMobileButton, GoToTopButton } from '../Common/components/buttons'
import CalendarIcon from '../../assets/icons/CalendarIcon'
import CalendarNavigationMobile from '../Layout/Legend/CalendarNavigation/mobile/CalendarNavigationMobile'
import moment from 'moment'
import useIntersect from '../Layout/hooks/useIntersect'
import Backdrop from '../../shared/Backdrop'
import ArrowUpIcon from '../../assets/icons/ArrowUpIcon'
import theme from '../Common/theme'

const CalendarIconMobile = styled(CalendarIcon)`
    && {
        width: 100%;
        height: 100%;
        & > svg {
            width: 24px;
            height: 24px;
        }

        .cls-1-search {
            fill: none;
        }
        .cls-2-search {
            fill: ${theme.colors.primary};
        }
    }
`

const HeaderInfoWrapper = styled.div`
    text-transform: capitalize;
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumBig};
    color: ${theme.colors.primary};
`
const HeaderLegendWrapper = styled(HeaderWrapper)`
    && {
        margin-top: 64px;
        padding-top: ${props => (props.ratio !== 1 ? theme.borders.thin : '0px')} !important;
        border-bottom: ${props =>
            props.ratio !== 1 ? `${theme.borders.thin} solid ${theme.colors.disabled}` : 'none'} !important;
    }
`
const OKContentWrapper = styled(ContentWrapper)`
    && {
        margin-top: 128px;
    }
`

const OKPage = props => {
    const { codes, dateValue } = useContext(OKSystemContext)
    const [ref, entry] = useIntersect({ threshold: 0 })
    const [showPicker, setShowPicker] = useState(false)

    const toggleSidebar = data => {
        props.showSidebar(data)
    }

    const togglePicker = data => {
        setShowPicker(!showPicker)
    }
    const goTop = () => {
        document.getElementById('root').scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <Media
            queries={{
                small: '(max-width: 599px)',
                medium: '(min-width: 600px) and (max-width: 1199px)',
                large: '(min-width: 1200px)',
            }}>
            {matches => (
                <>
                    {(matches.small || matches.medium) && (
                        <LayoutWrapper>
                            <StyledHeader mode="horizontal" showSidebar={toggleSidebar} ratio={entry.intersectionRatio}>
                                <HeaderInfoWrapper>{moment(dateValue).format('MMMM YYYY')}</HeaderInfoWrapper>
                                <SpecialHeaderMobileButton onClick={() => togglePicker()}>
                                    <CalendarIconMobile />
                                </SpecialHeaderMobileButton>
                            </StyledHeader>
                            <span ref={ref} ratio={entry.intersectionRatio}></span>
                            <HeaderLegendWrapper ratio={entry.intersectionRatio}>
                                <OKLegend codesNumber={codes.length} />
                            </HeaderLegendWrapper>
                            <Media
                                query="(max-width: 1199px)"
                                render={() => <Backdrop show={showPicker} clicked={() => togglePicker()} />}
                            />
                            <OKContentWrapper>
                                <CalendarNavigationMobile context={OKSystemContext} shouldOpen={showPicker} />
                                <RenderCalendar selectedDate={dateValue} codes={codes} />
                            </OKContentWrapper>
                            <GoToTopButton ratio={entry.intersectionRatio} onClick={goTop}>
                                <ArrowUpIcon />
                            </GoToTopButton>
                        </LayoutWrapper>
                    )}
                    {matches.large && (
                        <LayoutWrapper>
                            <StyledHeader
                                collapsed={props.collapsed}
                                mode="horizontal"
                                showSidebar={toggleSidebar}
                                ratio={entry.intersectionRatio}>
                                <OKLegend codesNumber={codes.length} />
                            </StyledHeader>
                            <span ref={ref} ratio={entry.intersectionRatio}></span>
                            <ContentWrapper>
                                <RenderCalendar selectedDate={dateValue} codes={codes} />
                            </ContentWrapper>
                        </LayoutWrapper>
                    )}
                </>
            )}
        </Media>
    )
}

export default OKPage

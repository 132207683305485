import { Layout } from 'antd'
import styled from 'styled-components'
import React from 'react'
import Media from 'react-media'
import MenuIcon from '../../../assets/icons/MenuIcon'
import { ButtonBlank } from './buttons'
import theme from '../../Common/theme'

const { Header, Content } = Layout

export const HeaderWrapper = styled(Header)`
    && {
        padding-left: 24px;
        left: ${props => (props.collapsed ? '70px' : '256px')};
        transition: left 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
        position: fixed;
        right: 0;
        background-color: ${theme.colors.background};
        z-index: 89;
        line-height: 63px;
        padding-bottom: 2px;
        border-bottom: ${props =>
            props.ratio !== 1 ? `${theme.borders.thin} solid ${theme.colors.border} !important` : 'none'};
        height: ${props => (props.ratio !== 1 ? `65px !important` : '64px')};
        text-align: center;

        @media only screen and (max-width: 1199px) {
            height: 64px !important;
            display: flex;
            width: 100%;
            left: 0;
            justify-content: space-between;
            align-items: flex-start;
            border-bottom: ${props =>
                props.employees ? `${theme.borders.thin} solid ${theme.colors.disabled};` : 'none'} !important;
            margin-left: 0px;
            padding: 0px !important;
        }
    }
`

const ShowMenuButton = styled(ButtonBlank)`
    && {
        padding: 0px;
        margin-top: 16px;
        margin-left: 16px;
        display: ${props => (props.showburger ? 'none' : 'block')};
    }
`

export const StyledHeader = props => {
    const toggleSidebar = () => {
        props.showSidebar(true)
    }

    return (
        <HeaderWrapper
            collapsed={props.collapsed}
            mode="horizontal"
            employees={props.isEmployees ? 1 : 0}
            ratio={props.ratio}>
            <Media
                query="(max-width: 1199px)"
                render={() => (
                    <ShowMenuButton showburger={props.isSearch ? 1 : 0} onClick={toggleSidebar}>
                        <MenuIcon />
                    </ShowMenuButton>
                )}
            />
            {props.children}
        </HeaderWrapper>
    )
}

export const ContentWrapper = styled(Content)`
    && {
        border-radius: 8px;
        border: ${theme.borders.thin} solid ${theme.colors.border} !important;
        background-color: ${theme.colors.white};
        margin-top: 64px;
        min-height: 280px;
        overflow: initial;

        @media only screen and (max-width: 1199px) {
            background-color: ${theme.colors.background};
            margin-top: 64px;
            width: 100%;
            border: none !important;

            & > div:last-child {
                margin-bottom: 64px;
            }
        }
        @media (min-width: 600px) and (max-width: 1199px) {
            display: flex;
            justify-content: center;
        }
        @media (min-width: 768px) and (max-width: 1199px) {
            padding-left: 160px;
            padding-right: 160px;
        }
    }
`

export const LayoutWrapper = styled(Layout)`
    && {
        background-color: ${theme.colors.background};
        height: 100%;
        min-height: 100vh;
        padding: 0px 24px 24px 24px;
        @media only screen and (max-width: 1199px) {
            padding: 0px;
            width: 100%;
        }

        @media (min-width: 1200px) and (max-width: 1440px) {
            padding: 0px 16px 16px 16px;
        }
    }
`

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'

const SuperIcon = styled(Icon)`
    padding-top: 8px;
`

const TourSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17 16H3C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.316071 14.5587 0 13.7957 0 13V3.00002C0 2.20437 0.316071 1.44131 0.87868 0.878696C1.44129 0.316087 2.20435 1.67143e-05 3 1.67143e-05H8C8.13161 -0.000744179 8.26207 0.0244809 8.38391 0.0742455C8.50574 0.12401 8.61656 0.197335 8.71 0.290017L10.41 2.00002H17C17.7956 2.00002 18.5587 2.31609 19.1213 2.8787C19.6839 3.44131 20 4.20437 20 5.00002V13C20 13.7957 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16ZM3 2.00002C2.73478 2.00002 2.48043 2.10537 2.29289 2.29291C2.10536 2.48045 2 2.7348 2 3.00002V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8947 2.73478 14 3 14H17C17.2652 14 17.5196 13.8947 17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13V5.00002C18 4.7348 17.8946 4.48045 17.7071 4.29291C17.5196 4.10537 17.2652 4.00002 17 4.00002H10C9.86839 4.00078 9.73793 3.97555 9.61609 3.92579C9.49426 3.87602 9.38344 3.8027 9.29 3.71002L7.59 2.00002H3Z"
            fill="#212529"
        />
    </svg>
)

const TourIcon = props => <SuperIcon className="icon" component={TourSvg} {...props} />

export default TourIcon

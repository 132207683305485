const theme = {
    colors: {
        yellow: '#fec700',
        pink: '#fec6dd',
        blue: '#002cc1',
        blueHover: '#0028b0',
        green: '#00a41e',
        purple: '#7d68ff',
        black: '#000000',
        white: '#ffffff',
        red: '#fe6500',
        error: '#ff0100',
        plum: '#9e69af',
        monaLisa: '#fe938c',
        cashmere: '#e6b89c',
        persianGreen: '#00a6a6',
        deepSeaGreen: '#084c61',
        minsk: '#3d348b',
        ceriseRed: '#e83f6f',

        athensGray: '#f1f3f5',
        gray: '#868e96',
        magnolia: '#faf9ff',
        quarterPearlLusta: '#fffdf5',
        tutu: '#fffdfe',
        narvik: '#f5fcf6',
        alabaster: '#fafafa',

        primary: '#212529',
        secondary: '#495057',
        inactive: '#acb5bd',
        disabled: '#f7f8fe',
        hover: '#eeeff1',
        background: '#f8f9fa',
        selected: '#ebeefb',
        border: '#dde2e5',
    },
    fontSizes: {
        tiny: '8px',
        small: '10px',
        mediumSmall: '12px',
        medium: '14px',
        mediumBig: '16px',
        big: '24px',
        large: '32px',
        huge: '34px',
        mobileDayNumber: '60px',
    },
    borders: {
        thin: '1px',
        medium: '2px',
        mediumThick: '3px',
        thick: '4px',
        extraThick: '8px',
    },
}

export default theme

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    //padding-right: 2px;
    ${props => props.rotate && 'transform: rotate(180deg);'}

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
    .cls-3 {
        fill: ${props => (props.color ? props.color : theme.colors.inactive)};
    }
`

const CollapseSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" fill="none" width="24" height="24" />
                <path
                    className="cls-2"
                    d="M19,11H7.41l2.3-2.29A1,1,0,1,0,8.29,7.29l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L7.41,13H19a1,1,0,0,0,0-2Z"
                />
                <path className="cls-3" d="M13,8h6a1,1,0,0,0,0-2H13a1,1,0,0,0,0,2Z" />
                <path className="cls-3" d="M19,16H13a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" />
            </g>
        </g>
    </svg>
)

const CollapseIcon = props => <SuperIcon component={CollapseSvg} {...props} />

export default CollapseIcon

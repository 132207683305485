import React, { useContext, useMemo, useState } from 'react'
import Legend from '../Layout/Legend/Legend'
import { ContentWrapper, LayoutWrapper, StyledHeader } from '../Common/components/header'

import Media from 'react-media'
import { SpecialHeaderMobileButton, GoToTopButton } from '../Common/components/buttons'
import { WorkLogsContext } from './context/WorkLogsContext'
import moment from 'moment'
import useIntersect from '../Layout/hooks/useIntersect'
import Backdrop from '../../shared/Backdrop'
import CalendarNavigationMobile from '../Layout/Legend/CalendarNavigation/mobile/CalendarNavigationMobile'
import ArrowUpIcon from '../../assets/icons/ArrowUpIcon'
import convertNumberToTime from '../../shared/convertNumberToTime'
import Comments from './Comments/Comments'
import Projects from './Projects/Projects'
import countWeekdaysUntilToday from './utils/countWeekdaysUntilToday'
import useConfig from '../Layout/hooks/useConfig'
import SparrowAnimation from '../../shared/SparrowAnimation'
import {
    HeaderCommentButton,
    HeaderDate,
    HeaderHourSum,
    HeaderInfoWrapper,
    ButtonContainer,
    MobileCommentIcon,
    CalendarIconMobile,
    StyledDrawer,
} from './WorkLogsPage.components'

const WorkLogsPage = props => {
    const [ref, entry] = useIntersect({ threshold: 0 })
    const [showPicker, setShowPicker] = useState(false)
    const { bankHolidays } = useConfig()
    const {
        dateValue,
        worklogsForCurrentMonth: workLogs,
        showComments,
        setShowComments,
        setCurrentDate,
        showProjects,
        setShowProjects,
        currentDate,
        log,
        pending,
        holidays,
    } = useContext(WorkLogsContext)
    const start = useMemo(() => {
        const currentDayLogs = workLogs.filter(l => l.date === log?.date).map(val => val.workedHours)
        let worked = currentDayLogs.reduce((prev, curr) => prev + curr, 0)

        return pending && worked >= 8
    }, [workLogs, pending, log])

    const toggleSidebar = data => {
        props.showSidebar(data)
    }
    const togglePicker = data => {
        setShowPicker(!showPicker)
    }
    const goTop = () => {
        document.getElementById('root').scrollTo({ top: 0, behavior: 'smooth' })
    }

    const totalHoursWorkedInMonth = workLogs.reduce((pv, cv) => (pv += parseFloat(cv.workedHours, 10)), 0)
    const filteredHolidays = [...holidays].filter(h => h.isPaid)
    const totalHolidayHoursTaken = filteredHolidays.reduce((pv, cv) => (pv += parseFloat(cv.hours, 10)), 0)
    const totalPossibleHoursUntilNow = useMemo(
        () =>
            countWeekdaysUntilToday(
                dateValue,
                bankHolidays?.filter(it => moment(it).isSame(dateValue, 'month')),
            ),
        [dateValue, bankHolidays],
    )
    const onCloseComments = () => {
        setShowComments(false)
    }

    const onCloseProjects = () => {
        setShowProjects(false)
    }

    const openProjectDrawer = () => {
        setShowProjects(true)
    }

    const openCommentDrawer = () => {
        setShowComments(true)
        setCurrentDate(moment(currentDate).format('YYYY-MM-DD'))
    }

    return (
        <Media
            queries={{
                small: '(max-width: 599px)',
                medium: '(min-width: 600px) and (max-width: 1199px)',
                large: '(min-width: 1200px)',
            }}>
            {matches => (
                <>
                    {(matches.small || matches.medium) && (
                        <LayoutWrapper>
                            <span ref={ref}></span>
                            <StyledHeader
                                mode="horizontal"
                                showSidebar={toggleSidebar}
                                worklogs={true}
                                isEmployees={true}>
                                <HeaderInfoWrapper onClick={openProjectDrawer}>
                                    <HeaderDate>{moment(dateValue).format('MMMM YYYY')}</HeaderDate>
                                    <HeaderHourSum>
                                        Suma: {convertNumberToTime(totalHoursWorkedInMonth + totalHolidayHoursTaken)} /{' '}
                                        {totalPossibleHoursUntilNow}h
                                    </HeaderHourSum>
                                </HeaderInfoWrapper>
                                <ButtonContainer>
                                    <HeaderCommentButton onClick={openCommentDrawer}>
                                        <MobileCommentIcon />
                                    </HeaderCommentButton>
                                    <SpecialHeaderMobileButton onClick={() => togglePicker()}>
                                        <CalendarIconMobile />
                                    </SpecialHeaderMobileButton>
                                </ButtonContainer>
                            </StyledHeader>
                            <Media
                                query="(max-width: 1199px)"
                                render={() => <Backdrop show={showPicker} clicked={() => togglePicker()} />}
                            />
                            <ContentWrapper>
                                <CalendarNavigationMobile context={WorkLogsContext} shouldOpen={showPicker} />
                                {props.children}
                            </ContentWrapper>
                            <GoToTopButton
                                ratio={entry.intersectionRatio}
                                sidebarShown={props.sidebarVisible}
                                onClick={goTop}>
                                <ArrowUpIcon />
                            </GoToTopButton>
                            <StyledDrawer
                                placement="right"
                                onClose={onCloseComments}
                                closable={false}
                                visible={showComments}
                                width={matches.small ? '100%' : '400px'}>
                                <Comments date={currentDate}></Comments>
                            </StyledDrawer>
                            <StyledDrawer
                                placement="right"
                                onClose={onCloseProjects}
                                closable={false}
                                visible={showProjects}
                                width={matches.small ? '100%' : '400px'}>
                                <Projects date={currentDate}></Projects>
                            </StyledDrawer>
                        </LayoutWrapper>
                    )}
                    {matches.large && (
                        <LayoutWrapper>
                            <span ref={ref}></span>
                            <StyledHeader
                                collapsed={props.collapsed}
                                mode="horizontal"
                                showSidebar={toggleSidebar}
                                ratio={entry.intersectionRatio}>
                                <Legend totalPossibleHoursUntilNow={totalPossibleHoursUntilNow} />
                            </StyledHeader>
                            <ContentWrapper>{props.children}</ContentWrapper>
                            <StyledDrawer
                                placement="right"
                                onClose={onCloseComments}
                                closable={false}
                                visible={showComments}
                                width={400}>
                                <Comments date={currentDate}></Comments>
                            </StyledDrawer>
                            <StyledDrawer
                                placement="right"
                                onClose={onCloseProjects}
                                closable={false}
                                visible={showProjects}
                                width={400}>
                                <Projects
                                    date={currentDate}
                                    totalHours={totalHoursWorkedInMonth}
                                    logs={workLogs}></Projects>
                            </StyledDrawer>
                            <SparrowAnimation start={start} />
                        </LayoutWrapper>
                    )}
                </>
            )}
        </Media>
    )
}

export default WorkLogsPage

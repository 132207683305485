import React, { useState, useEffect, useContext, useRef } from 'react'
import { WorkLogsContext } from '../../context/WorkLogsContext'
import { CommentInput, StyledAlert } from './CommentsContent.components'
import theme from '../../../Common/theme'

const CommentsContent = ({ currentLog, selectedDate, project, index }, ...props) => {
    const { commentsChanged, showComments } = useContext(WorkLogsContext)
    const ref = useRef(null)
    const [isChanged, setIsChanged] = useState(false)
    const [comment, setComment] = useState(currentLog ? currentLog.description : '')
    const [color, setColor] = useState(theme.colors.inactive)

    useEffect(() => {
        setComment(currentLog ? currentLog.description : '')
    }, [currentLog])

    useEffect(() => {
        if (comment !== '') setColor(theme.colors.primary)
    }, [comment])

    const changeComment = e => {
        setIsChanged(true)
        setComment(e.target.value)
    }

    const addComment = (e, value, project) => {
        if (e.keyCode && e.keyCode !== 13) return
        if (e.keyCode && e.keyCode === 13) {
            e.target.blur()
            return
        }
        isChanged && commentsChanged(currentLog ? currentLog.workedHours : 0.0, selectedDate, project, comment)
        e.target.blur()
        setIsChanged(false)
    }

    useEffect(() => {
        if (index === 0 && ref.current && showComments) {
            setTimeout(() => {
                ref.current.focus()
            }, 200)
        }
    }, [index, selectedDate, showComments])
    return (
        <>
            <CommentInput
                onBlur={e => {
                    addComment(e, comment, project)
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault()
                        addComment(e, comment, project)
                    }
                }}
                onChange={changeComment}
                value={comment}
                error={project.name.toLowerCase() === 'inne' && !comment && currentLog}
                placeholder="Dodaj opis"
                disabled={currentLog ? false : true}
                ref={ref}
                key={project.id}
                colored={color}></CommentInput>
            <StyledAlert data-cy="error-alert" show={project.name.toLowerCase() === 'inne' && !comment && currentLog}>
                Wpisanie komentarza w "Inne" jest obowiązkowe.
            </StyledAlert>
        </>
    )
}

export default CommentsContent

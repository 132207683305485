import React, { useCallback, useContext, useState, useEffect } from 'react'
import { Tabs } from 'antd'
import { JWT_TOKEN } from '../../../Constants'
import fetchAPI from '../../../services/fetchAPI'
import { UserContext } from '../../Common/contexts/userContext'
import { getYear } from 'date-fns'
import { getFuturePeriods, getHeldPeriods, getHolidaysPeriods } from './DashboardLeave.helpers'
import {
    Result,
    LeaveWrapper,
    LeaveBoxTitle,
    DaysOffThatWereTaken,
    DaysTakenWrapper,
    NumberOfLeaveTaken,
    OutOfNumberOfDays,
    TabsWrapper,
    NoPlannedOrTakenLeaveWrapper,
    PlannedOrTakenLeaveWrapper,
} from './DashboardLeave.components'

const { TabPane } = Tabs

const DashboardLeave = props => {
    const [daysOffTaken, setDaysOffTaken] = useState(0)
    const [currentYearPaidHolidays, setCurrentYearPaidHolidays] = useState([])
    const [currentYearUnpaidHolidays, setCurrentYearUnpaidHolidays] = useState([])
    const [plannedPaid, setPlannedPaid] = useState([])
    const [plannedUnpaid, setPlannedUnpaid] = useState([])
    const [heldPaid, setHeldPaid] = useState([])
    const [heldUnpaid, setHeldUnpaid] = useState([])

    let config = () => {
        return {
            headers: { Authorization: `Bearer ${JWT_TOKEN()}` },
        }
    }
    const user = useContext(UserContext)

    const getHolidaysData = useCallback(async () => {
        try {
            const currentYear = getYear(new Date())
            const res = await fetchAPI(
                `/employees/${user.details.id}/holidays?startDate=01.01.${currentYear}&endDate=31.12.${currentYear}`,
                config(),
            )
            if (res.errors) {
                alert(res.errors[0].msg)
            } else if (res.status === 404) {
                setCurrentYearPaidHolidays([])
                setCurrentYearUnpaidHolidays([])
            } else {
                const holidays = res.map(x => {
                    return { ...x, date: new Date(x.date) }
                })
                setCurrentYearPaidHolidays(holidays.filter(x => x.isPaid === true))
                setCurrentYearUnpaidHolidays(holidays.filter(x => x.isPaid === false))
                setDaysOffTaken(holidays.filter(x => x.isPaid === true).length)
            }
        } catch (error) {
            console.error('ERR', error)
        }
    }, [user.details.id])

    useEffect(() => {
        getHolidaysData()
    }, [getHolidaysData])

    useEffect(() => {
        if (currentYearPaidHolidays.length > 0) {
            const periods = getHolidaysPeriods(currentYearPaidHolidays)
            setHeldPaid(getHeldPeriods(periods))
            setPlannedPaid(getFuturePeriods(periods))
        }
        if (currentYearUnpaidHolidays.length > 0) {
            const periods = getHolidaysPeriods(currentYearUnpaidHolidays)
            setHeldUnpaid(getHeldPeriods(periods))
            setPlannedUnpaid(getFuturePeriods(periods))
        }
    }, [currentYearPaidHolidays, currentYearUnpaidHolidays])

    return (
        <LeaveWrapper>
            <LeaveBoxTitle>Urlopy</LeaveBoxTitle>
            <DaysOffThatWereTaken>Wykorzystane dni urlopu</DaysOffThatWereTaken>
            <DaysTakenWrapper>
                <NumberOfLeaveTaken>{daysOffTaken}</NumberOfLeaveTaken>
                <OutOfNumberOfDays>/18</OutOfNumberOfDays>
            </DaysTakenWrapper>
            <TabsWrapper defaultActiveKey="planned">
                <TabPane tab="Zaplanowane" key="planned">
                    {plannedPaid.length === 0 && plannedUnpaid.length === 0 && (
                        <NoPlannedOrTakenLeaveWrapper>
                            Nie masz jeszcze zaplanowanego urlopu.
                        </NoPlannedOrTakenLeaveWrapper>
                    )}
                    {plannedPaid.length > 0 && (
                        <>
                            <PlannedOrTakenLeaveWrapper>Zaplanowany urlop płatny</PlannedOrTakenLeaveWrapper>
                            {plannedPaid.map((x, index) => (
                                <Result key={index} period={x} />
                            ))}
                        </>
                    )}
                    {plannedUnpaid.length > 0 && (
                        <>
                            <PlannedOrTakenLeaveWrapper>Zaplanowany urlop bezpłatny</PlannedOrTakenLeaveWrapper>
                            {plannedUnpaid.map((x, index) => (
                                <Result key={index} period={x} />
                            ))}
                        </>
                    )}
                </TabPane>
                <TabPane tab="Odbyte" key="taken">
                    {heldPaid.length === 0 && heldUnpaid.length === 0 && (
                        <NoPlannedOrTakenLeaveWrapper>Nie odbyłeś jeszcze urlopu.</NoPlannedOrTakenLeaveWrapper>
                    )}
                    {heldPaid.length > 0 && (
                        <>
                            <PlannedOrTakenLeaveWrapper>Odbyty urlop płatny</PlannedOrTakenLeaveWrapper>
                            {heldPaid.map((x, index) => (
                                <Result key={index} period={x} />
                            ))}
                        </>
                    )}
                    {heldUnpaid.length > 0 && (
                        <>
                            <PlannedOrTakenLeaveWrapper>Odbyty urlop bezpłatny</PlannedOrTakenLeaveWrapper>
                            {heldUnpaid.map((x, index) => (
                                <Result key={index} period={x} />
                            ))}
                        </>
                    )}
                </TabPane>
            </TabsWrapper>
        </LeaveWrapper>
    )
}

export default DashboardLeave

import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { JWT_TOKEN } from '../../../Constants'
import moment from 'moment'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { UserContext } from '../../Common/contexts/userContext'
import fetchAPI from '../../../services/fetchAPI'
import useCalendarNavigation from '../../../services/useCalendarNavigation'
import ErrorModal from '../../Common/components/error-modal'
import Loader from '../../Common/components/spinner'
import convertTimeToNumber from '../../../shared/convertTimeToNumber'
import styled from 'styled-components'

export const WorkLogsContext = createContext({})

const Content = styled.div`
    flex: 1;
    // @media only screen and (min-width: 1199px) {
    //     margin-left: 256px !important;
    // }
`

let config = () => {
    return {
        headers: { Authorization: `Bearer ${JWT_TOKEN()}` },
    }
}

let configPOST = body => {
    return {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JWT_TOKEN()}`,
        },
        body: JSON.stringify(body),
    }
}

let configPUT = body => {
    return {
        method: 'put',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JWT_TOKEN()}`,
        },
        body: JSON.stringify(body),
    }
}

const WorkLogsContextProvider = props => {
    const { dateValue, goForwardOneMonth, goBackOneMonth, changeDate, goToToday } = useCalendarNavigation()
    const [newLogs, setNewLogs] = useState([])
    const [currentMonthComments, setCurrentMonthComments] = useState([])
    const [commentProjects, setCommentProjects] = useState([])
    const [currentMonthLogs, setCurrentMonthLogs] = useState([])
    const [oldLogs, setOldLogs] = useState([])
    const [pendingLogs, setPendingLogs] = useState([])
    const [projects, setProjects] = useState([])
    const [projectsByDate, setProjectsByDate] = useState([])
    const [showComments, setShowComments] = useState(false)
    const [showProjects, setShowProjects] = useState(false)
    const [currentDate, setCurrentDate] = useState(dateValue)
    const [holidays, setHolidays] = useState([])
    const [holidayRecap, setHolidayRecap] = useState([])
    const [pending, setPending] = useState(false)
    const [loading, setLoading] = useState(true)
    const [sendAgainError, setSendAgainError] = useState('')
    const [cancelSendAgainError, setCancelSendAgainError] = useState(false)
    const [invalidValueError, setInvalidValueError] = useState(false)
    const [pageChanged, setPageChanged] = useState(false)
    const [log, setLog] = useState()
    const user = useContext(UserContext)

    const projectsForCurrentMonth = useMemo(() => {
        return projects?.filter(project => {
            return (
                !project.employeeEndDate ||
                moment(dateValue).isBetween(
                    moment(project.employeeStartDate),
                    moment(project.employeeEndDate),
                    'month',
                    [],
                )
            )
        })
    }, [dateValue, projects])

    const commentProjectsForCurrentMonth = useMemo(() => {
        return commentProjects?.filter(project => {
            return (
                !project.employeeEndDate ||
                moment(currentDate).isBetween(
                    moment(project.employeeStartDate),
                    moment(project.employeeEndDate),
                    'month',
                    [],
                )
            )
        })
    }, [currentDate, commentProjects])

    const sendOrFetchAgain = async () => {
        const currentMonth = moment(currentDate).format('YYYY-MM')
        if (sendAgainError === 'ADD') {
            setSendAgainError('')
            setPending(true)
            sendLogs(currentMonth, newLogs)
        }
        if (sendAgainError === 'FETCH') {
            getLogsData()
        }
    }

    const addLog = async (log, date, project) => {
        setPageChanged(true)
        log = convertTimeToNumber(log)
        let otherChanged = false
        if (project.name === 'Inne' && !isNaN(log) && log !== 0) {
            otherChanged = true
            setCurrentDate(date)
        }
        if (isNaN(log)) log = 0.0
        let isCorrect = log / 16 <= 1
        if (isCorrect === false) {
            setCancelSendAgainError(true)
            setInvalidValueError(true)
            return
        }
        setPending(true)
        const currentMonth = moment(date).format('YYYY-MM')
        let logsFromMonth = pendingLogs || []
        let currentDayLog = ''
        const found = logsFromMonth.find(wl => wl.date === date && wl.projectId === project.id)
        if (found) currentDayLog = found.description
        logsFromMonth = logsFromMonth.filter(el => el.date !== date || el.projectId !== project.id)
        let data = {
            date: date,
            description: currentDayLog,
            workedHours: parseFloat(log),
            projectId: project.id,
        }
        logsFromMonth.push(data)
        setPendingLogs(logsFromMonth)
        setCurrentMonthComments(logsFromMonth)
        setOldLogs(currentMonthLogs)
        setNewLogs(logsFromMonth)
        sendLogs(currentMonth, logsFromMonth, otherChanged)
    }

    const logsChanged = (log, date, project, comment) => {
        addLog(log, date, project, comment)

        // set current log for animation to now when to trigger
        setLog({ log, date })
    }

    const getProjectsData = useCallback(async () => {
        await fetchAPI(`/orders-overview`, config()).then(
            res => {
                if (res.errors) {
                    alert(res.errors[0].msg)
                } else {
                    setProjects(res)
                }
            },
            error => {
                console.log(error)
            },
        )
    }, [])

    ////////////////////////////////////////////////////////////////////////////////////////////////////////NAJGORZEJ

    const addComment = async (log, date, project, comment) => {
        const currentMonth = moment(date).format('YYYY-MM')
        let commentsFromMonth = currentMonthComments || []
        commentsFromMonth = commentsFromMonth.filter(el => el.date !== date || el.projectId !== project.id)
        let data = {
            date: date,
            description: comment,
            workedHours: parseFloat(log),
            projectId: project.id,
        }
        commentsFromMonth.push(data)
        setCurrentMonthComments(commentsFromMonth)
        setPendingLogs(commentsFromMonth)
        sendComments(currentMonth, commentsFromMonth)
    }

    const commentsChanged = (log, date, project, comment) => {
        addComment(log, date, project, comment)
    }

    const getCommentsData = useCallback(async () => {
        const currentMonth = moment(currentDate).format('YYYY-MM')
        try {
            const res = await fetchAPI(`/employees/${user.details.id}/work-logs/${currentMonth}`, config())
            if (res.errors) {
                alert(res.errors[0].msg)
            } else if (res.status === 404) {
                setCurrentMonthComments([])
            } else {
                setCurrentMonthComments(res.workLogs)
            }
        } catch (error) {
            return console.log(error)
        }
    }, [user, currentDate])

    const sendComments = async (currentMonth, logsFromMonth) => {
        try {
            const res = await fetchAPI(
                `/employees/${user.details.id}/work-logs/${currentMonth}`,
                configPOST(logsFromMonth),
            )
            setCurrentMonthComments(res)
        } catch (error) {
            return console.log(error)
        }
    }

    const getCommentProjectsData = useCallback(async () => {
        await fetchAPI(`/orders-overview`, config()).then(
            res => {
                if (res.errors) {
                    alert(res.errors[0].msg)
                } else {
                    setCommentProjects(res)
                }
            },
            error => {
                console.log(error)
            },
        )
    }, [])

    useEffect(() => {
        const handler = async () => {
            await getCommentsData()
            await getCommentProjectsData()
        }
        if (showComments) handler()
    }, [getCommentsData, getCommentProjectsData, showComments])

    //////////////////////////////////////////////////////////////////////////////////////KONIEC NAJGORZEJ

    const getLogsData = useCallback(async () => {
        try {
            //const fns = format(currentDate, "yyyy-MM")
            const currentMonth = moment(dateValue).format('YYYY-MM')
            const res = await fetchAPI(`/employees/${user.details.id}/work-logs/${currentMonth}`, config())
            if (res.errors) {
                setSendAgainError('FETCH')
                alert(res.errors[0].msg)
            } else if (res.status === 404) {
                setSendAgainError('')
                setCurrentMonthLogs([])
                setPendingLogs([])
            } else {
                setCurrentMonthLogs(res.workLogs)
                setPendingLogs(res.workLogs)
                setSendAgainError('')
            }
        } catch (error) {
            setSendAgainError('')
            setSendAgainError('FETCH')
        }
    }, [user, dateValue])

    const sendLogs = async (currentMonth, logsFromMonth, otherChanged) => {
        try {
            const res = await fetchAPI(
                `/employees/${user.details.id}/work-logs/${currentMonth}`,
                configPOST(logsFromMonth),
            )
            setCurrentMonthLogs(res)
            setOldLogs(logsFromMonth)
            getProjectsDataByDate()
            setPending(false)
            if (otherChanged) setShowComments(true)
        } catch (error) {
            setCancelSendAgainError(false)
            setTimeout(setSendAgainError('ADD'), 1000)
            return console.log(error)
        }
    }

    const changeProjectColor = useCallback((projects, project, hex, isByDate) => {
        let newProjects = [...projects]
        //let projectIndex = newProjects.findIndex(p => p.id === project.id)
        var indices = newProjects.map((p, i) => (p.id === project.id ? i : '')).filter(String)
        if (indices.length > 1) {
            // for multiple "torn" projects, ones which have a couple of different ranges within month
            indices.forEach(i => {
                newProjects[i] = {
                    ...newProjects[i],
                    projectColor: hex,
                }
            })
        } else {
            //simple project color change for simply viewed projects
            newProjects[indices[0]] = {
                ...newProjects[indices[0]],
                projectColor: hex,
            }
        }
        isByDate ? setProjectsByDate(newProjects) : setProjects(newProjects)
    }, [])

    const sendColor = async (hex, project) => {
        try {
            await fetchAPI(`/employees/${user.details.id}/projects/${project.id}/color`, configPUT({ color: hex }))
            //change worklog project color
            changeProjectColor(projects, project, hex, false)
            //change "project menu" project color
            changeProjectColor(projectsByDate, project, hex, true)
            setPending(false)
        } catch (error) {
            return console.log(error)
        }
    }

    // get projects by date for "project menu" only
    const getProjectsDataByDate = useCallback(
        async (startDate, endDate) => {
            if (!startDate || !endDate) {
                //default
                const date = new Date(dateValue)
                startDate = format(startOfMonth(date), 'MM.dd.yyyy')
                endDate = format(endOfMonth(date), 'MM.dd.yyyy')
            } else {
                //dates provided
                startDate = format(startDate, 'MM.dd.yyyy')
                endDate = format(endDate, 'MM.dd.yyyy')
            }
            await fetchAPI(`/orders-overview?startDate=${startDate}&endDate=${endDate}`, config()).then(
                res => {
                    if (res.errors) {
                        alert(res.errors[0].msg)
                    } else {
                        setProjectsByDate(res)
                    }
                },
                error => {
                    console.log(error)
                },
            )
        },
        [dateValue],
    )

    const getHolidays = useCallback(
        async (startDate, endDate) => {
            if (!startDate || !endDate) {
                const date = new Date(dateValue)
                startDate = format(startOfMonth(date), 'dd.MM.yyyy')
                endDate = format(endOfMonth(date), 'dd.MM.yyyy')

                try {
                    const res = await fetchAPI(
                        `/employees/${user.details.id}/holidays?startDate=${startDate}&endDate=${endDate}`,
                        config(),
                    )
                    const filteredHolidays = [...res].filter(h => h.isPaid)
                    const totalHolidayHoursTaken = filteredHolidays.reduce(
                        (pv, cv) => (pv += parseFloat(cv.hours, 10)),
                        0,
                    )
                    setHolidayRecap(totalHolidayHoursTaken)
                    setHolidays(res)
                } catch (e) {
                    console.error(e)
                }
            } else {
                startDate = format(startDate, 'dd.MM.yyyy')
                endDate = format(endDate, 'dd.MM.yyyy')
                try {
                    const res = await fetchAPI(
                        `/employees/${user.details.id}/holidays?startDate=${startDate}&endDate=${endDate}`,
                        config(),
                    )
                    const filteredHolidays = [...res].filter(h => h.isPaid)
                    const totalHolidayHoursTaken = filteredHolidays.reduce(
                        (pv, cv) => (pv += parseFloat(cv.hours, 10)),
                        0,
                    )
                    setHolidayRecap(totalHolidayHoursTaken)
                } catch (e) {
                    console.error(e)
                }
            }
        },

        [dateValue, user],
    )

    // const getHolidays = useCallback(
    //     async (startDate, endDate) => {
    //         if (!startDate || !endDate) {
    //             const date = new Date(dateValue)
    //             startDate = format(startOfMonth(date), 'dd.MM.yyyy')
    //             endDate = format(endOfMonth(date), 'dd.MM.yyyy')
    //         } else {
    //             startDate = format(startDate, 'MM.dd.yyyy')
    //             endDate = format(endDate, 'MM.dd.yyyy')
    //         }
    //         try {
    //             const res = await fetchAPI(
    //                 `/employees/${user.details.id}/holidays?startDate=${startDate}&endDate=${endDate}`,
    //                 config(),
    //             )
    //             const filteredHolidays = [...res].filter(h => h.isPaid)
    //             const totalHolidayHoursTaken = filteredHolidays.reduce((pv, cv) => (pv += parseFloat(cv.hours, 10)), 0)
    //             setHolidayRecap(totalHolidayHoursTaken)
    //             setHolidays(res)
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     },

    //     [dateValue, user],
    // )

    useEffect(() => {
        const handler = async () => {
            await getHolidays()
            await getLogsData()
            await getProjectsData()
            setLoading(false)
        }
        handler()
    }, [getLogsData, getProjectsData, getHolidays])

    const handleCancel = () => {
        setSendAgainError('')
        setCurrentMonthLogs(oldLogs)
        setCancelSendAgainError(true)
        setPending(false)
    }

    const state = {
        projectsByDate,
        sendColor,
        holidays,
        holidayRecap,
        getHolidays,
        dateValue,
        logsChanged,
        commentsChanged,
        worklogsForCurrentMonth: currentMonthLogs,
        projectsForCurrentMonth,
        commentProjectsForCurrentMonth,
        log,
        currentMonthComments,
        changeDate,
        goForwardOneMonth,
        goBackOneMonth,
        goToToday,
        sendAgainError,
        cancelSendAgainError,
        setCancelSendAgainError,
        invalidValueError,
        setInvalidValueError,
        loading,
        setLoading,
        pending,
        showComments,
        setShowComments,
        showProjects,
        setShowProjects,
        currentDate,
        setCurrentDate,
        getProjectsDataByDate,
        pageChanged,
    }

    const handleExit = useCallback(e => {
        e.preventDefault()
        e.returnValue = ''
    }, [])

    useMemo(() => {
        if (pending) {
            window.addEventListener('beforeunload', handleExit)
        } else {
            window.removeEventListener('beforeunload', handleExit)
        }

        return () => window.removeEventListener('beforeunload', handleExit)
    }, [handleExit, pending])

    return (
        <WorkLogsContext.Provider value={state}>
            {sendAgainError && (
                <ErrorModal
                    error={sendAgainError}
                    handleOk={sendOrFetchAgain}
                    handleCancel={handleCancel}
                    afterClose={handleCancel}>
                    Wygląda na to, że nie udało się {sendAgainError === 'FETCH' ? ' pobrać' : ' zapisać'} godzinówek.
                    Spróbuj ponownie, a&nbsp;jeśli&nbsp;problem dalej występuje, sprawdź swoje połączenie z&nbsp;
                    Internetem.
                </ErrorModal>
            )}
            {loading && <Loader />}
            <Content hidden={loading}>{props.children}</Content>
        </WorkLogsContext.Provider>
    )
}

export default WorkLogsContextProvider

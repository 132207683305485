import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'

const SuperIcon = styled(Icon)`
    width: 18px;
    height: 18px;
    margin-right: 5px;
`

const CancelIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" id="cancel-24px" width="18" height="18" viewBox="0 0 18 18">
        <path id="Path_354" fill="none" d="M0 0h18v18H0z" data-name="Path 354" opacity="0.87" />
        <path
            id="Path_355"
            fill="#212529"
            d="M9.5 2A7.5 7.5 0 1 0 17 9.5 7.493 7.493 0 0 0 9.5 2zm3.225 10.725a.747.747 0 0 1-1.057 0L9.5 10.557l-2.168 2.168a.748.748 0 0 1-1.057-1.057L8.443 9.5 6.275 7.332a.748.748 0 0 1 1.057-1.057L9.5 8.443l2.168-2.168a.748.748 0 1 1 1.057 1.057L10.557 9.5l2.167 2.168a.761.761 0 0 1 0 1.057z"
            data-name="Path 355"
            transform="translate(-.5 -.5)"
        />
    </svg>
)

const CancelIcon = props => <SuperIcon component={CancelIconSVG} {...props} />

export default CancelIcon

import styled from 'styled-components'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import theme from '../theme'

const Spinner = styled(Spin)`
    && {
        color: ${theme.colors.blue};
        margin: auto;
        z-index: 12;
        ${p =>
            p.isProjectLoader &&
            `
                z-index: -1;
                padding-left: 186px;
                margin-top: 24px;
            `}

        @media only screen and (max-width: 1199px) {
            ${props => !props.isProjectLoader && 'padding-left: 0px;'}
        }
    }
`

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const Loader = ({ isProjectLoader }) => {
    return <Spinner size="large" isProjectLoader={isProjectLoader} indicator={antIcon} />
}

export default Loader

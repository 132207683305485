import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 18px;
    height: 18px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const HidePwSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>hide-pw</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect class="cls-1" fill="none" width="24" height="24" />
                <path
                    class="cls-2"
                    fill="#212529"
                    d="M21.71,20.29,18,16.62l-3.21-3.21L3.71,2.29A1,1,0,0,0,2.29,3.71L5.16,6.58A9.72,9.72,0,0,0,2,11.77a.9.9,0,0,0,0,.46A10.12,10.12,0,0,0,12,20a10.26,10.26,0,0,0,5.19-1.39l3.1,3.1a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29Zm-9.2-6.36a2,2,0,0,1-1.92-.52,2,2,0,0,1-.52-1.92ZM12,18a8.25,8.25,0,0,1-8-6A7.89,7.89,0,0,1,6.57,8h0l2,2h0A4,4,0,0,0,12,16a3.85,3.85,0,0,0,2-.56h0l1.68,1.69A8.26,8.26,0,0,1,12,18Z"
                />
                <path
                    class="cls-2"
                    fill="#212529"
                    d="M19.8,15.8a.94.94,0,0,1-.55-.17A1,1,0,0,1,19,14.25,7.59,7.59,0,0,0,20,12a8.25,8.25,0,0,0-8-6,7.81,7.81,0,0,0-1.62.16,1,1,0,1,1-.4-2A10.33,10.33,0,0,1,12,4a10.12,10.12,0,0,1,10,7.77.86.86,0,0,1,0,.45,9.33,9.33,0,0,1-1.34,3.13A1,1,0,0,1,19.8,15.8Z"
                />
                <polygon class="cls-2" points="6.58 7.99 8.56 9.97 8.56 9.98 6.57 7.99 6.58 7.99" fill="#212529" />
                <polygon class="cls-2" points="15.71 17.13 14.02 15.44 14.03 15.44 15.71 17.13" fill="#212529" />
            </g>
        </g>
    </svg>
)

const HidePasswordIcon = props => <SuperIcon component={HidePwSvg} {...props} />

export default HidePasswordIcon

import theme from '../../../../Common/theme'
import styled from 'styled-components'
import { DatePicker } from 'antd'

export const PickDate = styled(DatePicker)`
    && {
        cursor: ${props => props.isPending && 'not-allowed'};
        width: 0px;
        height: 0px;
        background-color: ${theme.colors.white};
        visibility: hidden;
        margin-left: -82px !important;
        z-index: 2000;
        position: fixed;
        left: 50%;

        div > input {
            cursor: ${props => (props.isPending ? 'not-allowed' : 'pointer')};
            max-width: 168px;
            height: 36px;
            color: ${theme.colors.primary};
            text-transform: capitalize;
            font-size: ${theme.fontSizes.medium};
            &:focus,
            &:active {
                border-color: ${props => (!props.isPending ? theme.colors.border : theme.colors.disabled)} !important;
                box-shadow: none !important;
                background-color: ${props => (!props.isPending ? theme.colors.white : theme.colors.disabled)};
            }
            &:hover {
                border-color: ${props => (!props.isPending ? theme.colors.border : theme.colors.disabled)} !important;
                box-shadow: none !important;
                background-color: ${props => (!props.isPending ? theme.colors.hover : theme.colors.disabled)};
            }
        }

        div > i {
            color: ${theme.colors.disabled};
        }
    }
`

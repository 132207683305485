import { Switch } from 'antd'
import { DashboardBox } from '../../Common/components/dashboard'
import styled from 'styled-components'
import theme from '../../Common/theme'

export const WagesWrapper = styled(DashboardBox)`
    && {
        height: 473px;
    }
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const GraphWrapper = styled.div`
    height: 370px;
    width: 100%;
    position: relative;

    @media (max-width: 599px) {
        height: 390px;
    }
`

export const PreviousMonthSumWrapper = styled.div`
    background-color: ${theme.colors.magnolia};
    border: solid ${theme.borders.medium} ${theme.colors.purple};
    position: absolute;
    width: 128px;
    height: 128px;
    top: 141px;
    left: 191px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1200px) and (max-width: 1280px) {
        left: 161px;
    }

    @media (min-width: 321px) and (max-width: 450px) {
        left: 115px;
    }

    @media (max-width: 320px) {
        left: 95px;
    }
`

export const CurrentMonthSumWrapper = styled.div`
    background-color: ${theme.colors.quarterPearlLusta};
    border: solid ${theme.borders.medium} ${theme.colors.yellow};
    position: absolute;
    width: 168px;
    height: 168px;
    display: inline-block;
    top: 0px;
    left: 183px;
    z-index: 81;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1200px) and (max-width: 1280px) {
        left: 153px;
    }

    @media (min-width: 321px) and (max-width: 450px) {
        left: 107px;
    }

    @media (max-width: 320px) {
        left: 87px;
    }
`

export const HourSumWrapper = styled.div`
    background-color: ${theme.colors.tutu};
    border: solid ${theme.borders.medium} ${theme.colors.pink};
    position: absolute;
    width: 128px;
    height: 128px;
    left: 98px;
    top: 89px;
    z-index: 80;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1200px) and (max-width: 1280px) {
        left: 68px;
    }

    @media (min-width: 321px) and (max-width: 450px) {
        left: 22px;
    }

    @media (max-width: 320px) {
        left: 2px;
    }
`

export const CurrentMonthPayment = styled.div`
    font-family: SofiaProRegular;
    font-size: ${props => (props.iconView ? '48px' : '34px')};
    font-weight: 500;
    color: ${theme.colors.primary};
`

export const PreviousMonthPayment = styled.div`
    font-family: SofiaProRegular;
    font-size: ${props => (props.iconView ? '32px' : '16px')};
    font-weight: 500;
    color: ${theme.colors.primary};
    letter-spacing: -1px;
    margin-bottom: 2px;
`

export const HourCount = styled.div`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.big};
    font-weight: 500;
    letter-spacing: -2px;
    text-align: center;
    color: ${theme.colors.primary};
`

export const LegendWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    bottom: 10px;

    @media (max-width: 599px) {
        bottom: 0px;
    }
`

export const InfoWrapper = styled.div`
    height: 32px;
    max-width: 150px;
    border-radius: 16px;
    background-color: ${theme.colors.background};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
`

export const GrayWord = styled.div`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumSmall};
    color: ${theme.colors.inactive};
    margin-top: -9px;
`

export const InfoText = styled.div`
    font-size: ${theme.fontSizes.mediumSmall};
    text-align: left;
    color: ${theme.colors.black};
`

export const Color = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
    margin-right: 8px;
`

export const SwitchView = styled(Switch)`
    && {
        background-color: ${theme.colors.border};
    }

    &&:focus {
        box-shadow: none;
    }
`

export const icons = ['💵', '🤑', '💰', '💸']

import React, { useState, useEffect } from 'react'
import { JWT_TOKEN } from '../../Constants'
import createGravatar from '../../shared/createGravatar'
import Employee from './Employee/Employee'
import { LayoutWrapper, StyledHeader } from '../Common/components/header'
import PhoneModal from '../Common/components/phone-modal'
import latinise from '../../shared/Latinise'
import SearchIcon from '../../assets/icons/SearchIcon'
import Media from 'react-media'
import { SpecialHeaderMobileButton, GoToTopButton } from '../Common/components/buttons'
import useIntersect from '../Layout/hooks/useIntersect'
import CloseIcon from '../../assets/icons/CloseIcon'
import ArrowUpIcon from '../../assets/icons/ArrowUpIcon'
import Loader from '../Common/components/spinner'
import { CopiedAlert, SearchBar, SearchIconMobile, HeaderInfoWrapper, Content } from './EmployeesPage.components'
import SuccessIcon from '../../assets/icons/SuccessIcon'

export const icon = <SuccessIcon /> /// TODO: przerób na wspólny komponent tego popupa;

const Employees = props => {
    const [people, setPeople] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [filteredPeople, setFilteredPeople] = useState([])
    const [displayModal, setDisplayModal] = useState({
        isModalDisplayed: false,
        phone: props.phoneNumber,
    })
    const [copied, setCopied] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [ref, entry] = useIntersect({ threshold: 0 })
    const [loading, setLoading] = useState(true)

    let config = () => {
        return {
            headers: { Authorization: `Bearer ${JWT_TOKEN()}` },
        }
    }

    const dynamicSort = property => {
        var sortOrder = 1

        if (property[0] === '-') {
            sortOrder = -1
            property = property.substr(1)
        }

        return function (a, b) {
            if (sortOrder === -1) {
                return b[property].localeCompare(a[property])
            } else {
                return a[property].localeCompare(b[property])
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/employees-overview`, config())
            res.json()
                .then(res => {
                    res.sort(dynamicSort('lastName'))
                    setPeople(res)
                    setTimeout(() => {
                        setLoading(false)
                    }, 500)
                })
                .catch(err => console.log(err))
        }
        fetchData()
    }, [])

    const filterPeople = e => setSearchValue(e.target.value)

    const setPhoneModal = (isModalShown, phone) => {
        return setDisplayModal({ isModalDisplayed: true, phoneNumber: phone })
    }

    const setCopiedModal = () => {
        setCopied(true)

        setTimeout(() => {
            setCopied(false)
        }, 1500)
    }

    const copyAlert = (
        <CopiedAlert
            icon={icon}
            description="Skopiowano do schowka."
            type="success"
            showIcon
            closable="true"
            onClose={() => {
                setCopied(false)
            }}
            className="error-alert"
            data-cy="closed-month-error"
        />
    )

    const handleCancel = () => {
        setDisplayModal(false)
    }

    useEffect(() => {
        const results = people.filter(person => {
            const fullName = latinise(`${person.firstName} ${person.lastName}`.toLowerCase())
            const searchVal = latinise(searchValue.toLowerCase())
            return fullName.includes(searchVal)
        })
        setFilteredPeople(results)
    }, [searchValue, people])

    const EmployeeList = filteredPeople.map(person => {
        if (!person.role || person.id === '666') return null
        const gravatarUrl = createGravatar(person.email)
        return (
            <Employee
                key={person.id}
                fullName={`${person.firstName} ${person.lastName}`}
                email={person.email}
                phoneNumber={person.phoneNumber ? person.phoneNumber : 'Nie podano numeru.'}
                gravatar={gravatarUrl}
                showModal={setPhoneModal}
                copied={setCopiedModal}
            />
        )
    })

    const toggleSidebar = data => {
        props.showSidebar(data)
    }

    const toggleSearch = () => {
        setShowSearch(!showSearch)
    }

    const goTop = () => {
        document.getElementById('root').scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <LayoutWrapper>
            <span ref={ref} ratio={entry.intersectionRatio}></span>
            <StyledHeader
                isSearch={showSearch}
                isEmployees={true}
                showSidebar={toggleSidebar}
                ratio={entry.intersectionRatio}>
                <Media
                    queries={{
                        small: '(max-width: 599px)',
                        medium: '(min-width: 600px) and (max-width: 1199px)',
                        large: '(min-width: 1200px)',
                    }}>
                    {matches => (
                        <>
                            {(matches.small || matches.medium) &&
                                (showSearch ? (
                                    <SearchBar
                                        placeholder="Szukaj znajomych"
                                        onChange={filterPeople}
                                        value={searchValue}
                                        suffix={
                                            <CloseIcon
                                                onClick={() => {
                                                    toggleSearch()
                                                    setSearchValue('')
                                                }}
                                            />
                                        }
                                    />
                                ) : (
                                    <>
                                        <HeaderInfoWrapper>Pracownicy</HeaderInfoWrapper>
                                        <SpecialHeaderMobileButton onClick={toggleSearch}>
                                            <SearchIconMobile />
                                        </SpecialHeaderMobileButton>
                                    </>
                                ))}
                            {matches.large && (
                                <SearchBar
                                    size="large"
                                    placeholder="Szukaj znajomych"
                                    allowClear={true}
                                    onChange={filterPeople}
                                    value={searchValue}
                                    prefix={<SearchIcon />}
                                />
                            )}
                        </>
                    )}
                </Media>
            </StyledHeader>
            <Content hidden={loading}>{EmployeeList}</Content>
            <Media
                query="(max-width: 1199px)"
                render={() => (
                    <GoToTopButton ratio={entry.intersectionRatio} onClick={goTop}>
                        <ArrowUpIcon />
                    </GoToTopButton>
                )}
            />
            {displayModal.isModalDisplayed && (
                <PhoneModal
                    error={displayModal.isModalDisplayed}
                    handleCancel={() => handleCancel()}
                    clickedCopy={() => setCopiedModal()}
                    afterClose={() => handleCancel()}
                    phoneNo={displayModal.phoneNumber}></PhoneModal>
            )}
            {copied && copyAlert}
            {loading && <Loader />}
        </LayoutWrapper>
    )
}

export default Employees

import React, { useState, useContext, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { LogInputBase } from '../Common/components/form.js'
import theme from '../Common/theme'
import check from '../../assets/icons/ok-check.svg'
import moment from 'moment'
import { OKSystemContext } from './contexts/OKSystemContext.js'

const OKInput = styled(LogInputBase)`
    display: ${props => (props.shouldDisplay ? 'block' : 'none')};
    color: transparent;
    padding: 0 4px;
    background: ${props => props.bgColor};
    border: ${theme.borders.medium} solid ${props => props.borderColor};
    opacity: ${props => props.bgOpacity};
    background-color: ${props => (props.disabled ? 'none' : 'auto')};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-position: center;
    animation: fadeIn 1.5s;
    &:hover {
        margin-top: -3px;
        margin-bottom: 5px;
        margin-left: -3px;
        margin-right: 5px;
        cursor: pointer;
    }
    &:focus {
        border-width: ${theme.borders.thin};
        cursor: text;
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @media only screen and (max-width: 1199px) {
        margin-bottom: 0px !important;
    }
`
const OKContainer = styled.div`
    display: flex;
    min-height: 26px;

    @media only screen and (max-width: 1199px) {
        height: 100%;
        justify-content: center;
        align-items: center;
    }
`

const OKInputs = props => {
    const getColorForInput = (el, index) => {
        if (el === '') {
            return isDisabled ? 'rgba(172, 181, 189, 0.16)' : theme.colors.disabled
        } else if (lastClosedInput === index && !props.codes[index]) {
            return 'rgba(254, 199, 0, 0.24)'
        } else {
            return props.isClosed ? 'rgba(254, 199, 0, 0.24)' : theme.colors.yellow
        }
    }
    const getColorForBorder = el => {
        if (el === '') {
            return isDisabled ? 'rgba(172, 181, 189, 0.24)' : theme.colors.inactive
        } else {
            return props.isClosed ? 'rgba(254, 199, 0, 0)' : theme.colors.yellow
        }
    }

    const codesTemplate = useCallback(() => {
        return props.codes.length ? props.codes.map(el => el.code) : ['']
    }, [props.codes])
    const isDisabled = moment(props.dateValue).isAfter(moment(), 'month')
    const [isFocused, setIsFocused] = useState(false)
    const [values, setValues] = useState(codesTemplate())
    const [currentValue, setCurrentValue] = useState('')
    const allEntries = useContext(OKSystemContext)
    const [lastClosedInput, setLastClosedInput] = useState(null)
    useEffect(() => {
        setValues(codesTemplate())
    }, [codesTemplate])

    if (values.length === 1 && values.every(el => el !== '')) {
        let newValues = [...values]
        newValues.push('')
        setValues(newValues)
    }

    const addCodeOnEnter = event => {
        if (event.keyCode === 13) {
            event.target.blur()
        }
    }

    const addCode = (index, event) => {
        if (!isFocused) return

        let newValues = [...values]
        newValues[index] = currentValue

        setCurrentValue('')
        setValues(newValues)
        allEntries.codesChanged(newValues, props.dateValue)
        setLastClosedInput(index)
        setIsFocused(false)
    }

    const changeToInput = index => {
        setCurrentValue(values[index])
        setIsFocused(true)
    }
    return (
        <OKContainer>
            {values.map((el, index) => {
                return (
                    <OKInput
                        type="text"
                        pattern="\d*"
                        inputMode="decimal"
                        borderColor={() => getColorForBorder(el)}
                        bgColor={() => getColorForInput(el, index)}
                        bgOpacity={1}
                        opacity={0}
                        key={index}
                        icon={el === '' ? '' : check}
                        value={currentValue}
                        onChange={event => setCurrentValue(event.target.value.replace(/\D/g, '').slice(0, 4))}
                        disabled={props.isClosed || isDisabled}
                        shouldDisplay={!props.isClosed || (props.isClosed && !!el && el !== '')}
                        onFocus={() => changeToInput(index)}
                        onBlur={event => addCode(index, event)}
                        onKeyUp={event => addCodeOnEnter(event)}
                        data-cy="ok-input"></OKInput>
                )
            })}
        </OKContainer>
    )
}

export default OKInputs

import React from 'react'
import Icon from '@ant-design/icons'

const GitLabSvg = () => (
    <svg width="24" height="24" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.6518 12.1175L10.9813 19L1.31085 12.1175C1.1812 12.0241 1.08504 11.893 1.03611 11.7428C0.98718 11.5927 0.987996 11.4312 1.03844 11.2816L2.14623 7.92035L4.36181 1.24232C4.3833 1.18791 4.41751 1.13918 4.46169 1.10005C4.53364 1.03569 4.62757 1 4.72502 1C4.82247 1 4.91639 1.03569 4.98835 1.10005C5.035 1.14421 5.06938 1.1993 5.08823 1.26011L7.30381 7.92035H14.6588L16.8744 1.24232C16.8959 1.18791 16.9301 1.13918 16.9743 1.10005C17.0462 1.03569 17.1401 1 17.2376 1C17.335 1 17.429 1.03569 17.5009 1.10005C17.5476 1.14421 17.582 1.1993 17.6008 1.26011L19.8164 7.93813L20.9696 11.2816C21.0155 11.4359 21.0093 11.6005 20.9521 11.7511C20.8948 11.9017 20.7895 12.0301 20.6518 12.1175V12.1175Z"
            stroke="#212529"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const GitLabIcon = props => <Icon className="icon" component={GitLabSvg} {...props} />

export default GitLabIcon

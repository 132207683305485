import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: ${props => (props.width ? props.width : '24px')};
    height: ${props => (props.height ? props.height : '24px')};

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const ChevronRightSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" fill="none" width="24" height="24" />
                <path
                    className="cls-2"
                    d="M9,19a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L13.59,12,8.29,6.71A1,1,0,0,1,9.71,5.29l6,6a1,1,0,0,1,0,1.42l-6,6A1,1,0,0,1,9,19Z"
                />
            </g>
        </g>
    </svg>
)

const ChevronRightIcon = props => <SuperIcon component={ChevronRightSvg} {...props} />

export default ChevronRightIcon

import styled from 'styled-components'
import { ContentWrapper, LayoutWrapper } from '../Common/components/header'
import theme from '../Common/theme'

export const DashboardContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    // flex-direction: column;
    flex-direction: row;
    margin-top: 48px;
`

export const Layout = styled(LayoutWrapper)`
    && {
        padding: 0px;
    }
`

export const DashboardHeader = styled.div`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.huge};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: left;
    color: ${theme.colors.primary};
    width: 100%;

    @media only screen and (max-width: 1199px) {
        font-size: ${theme.fontSizes.big};
        font-weight: 500;
        line-height: 1.5;
        margin-top: 24px;
    }

    @media (max-width: 1199px) and (min-width: 600px) {
        font-size: ${theme.fontSizes.huge};
        line-height: 1.53;
        margin-top: 32px;
        width: 512px;
    }
`

export const DashboardSubHeader = styled.div`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.medium};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    width: 100%;
    color: ${theme.colors.primary};

    @media only screen and (max-width: 599px) {
        font-size: ${theme.fontSizes.medium};
        margin-top: 2px;
    }

    @media (max-width: 1199px) and (min-width: 600px) {
        width: 512px;
    }
`

export const Content = styled(ContentWrapper)`
    && {
        border: none !important;
        margin-top: 48px;
        @media only screen and (min-width: 1440px) {
            margin-left: 64px !important;
            margin-right: 64px !important;
        }

        @media (min-width: 1200px) and (max-width: 1279px) {
            margin-left: 24px !important;
            margin-right: 24px !important;
        }

        @media (min-width: 1280px) and (max-width: 1439px) {
            margin-left: 32px !important;
            margin-right: 32px !important;
        }

        @media only screen and (max-width: 1199px) {
            padding-left: 16px;
            padding-right: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 64px;
            background-color: ${theme.colors.background} !important;
            border-top: ${theme.borders.thin} solid ${theme.colors.background} !important;
            border-radius: 0px;
        }
    }
`

export const TitleWrapper = styled.div`
    position: relative;
    margin: 0 auto;
    padding-right: 40px;
    font-size: ${theme.fontSizes.mediumBig};
    letter-spacing: normal;
    color: ${theme.colors.primary};
`

export const BoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: no wrap;
    align-items: center;
    position: relative;

    @media only screen and (max-width: 1199px) {
        width: 100%;
        margin-right: 0px;
    }

    @media (min-width: 1200px) and (max-width: 1279px) {
        margin-right: 24px;
        width: 48.7%;
    }

    @media (min-width: 1280px) and (max-width: 1439px) {
        margin-right: 24px;
        width: 48.7%;
    }

    @media (min-width: 1440px) {
        margin-right: 32px;
    }
`

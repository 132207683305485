const convertTimeToNumber = time => {
  const decimalPlaces = 2;
  const intFormat = time.match(/^\d+$/);
  const timeFormat = time.match(/([\d]*):([\d]+)/);
  let hours;
  let minutes;

  if (timeFormat != null) {
    hours = parseInt(timeFormat[1]);
    minutes = parseFloat(timeFormat[2] / 60);
    time = hours + minutes;
  } else if (intFormat != null) {
    time = parseInt(time);
  }

  time = parseFloat(time).toFixed(decimalPlaces);
  return time;
};

export default convertTimeToNumber;

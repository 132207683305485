import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    padding-right: 2px;

    .cls-1 {
        fill: none;
    }

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const AlwaysIconSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" width="24" height="24" data-name="Layer 2" viewBox="0 0 24 24">
        <g id="Layer_2-2" data-name="Layer 2">
            <path id="Rectangle_435" d="M0 0h24v24H0z" class="cls-1" data-name="Rectangle 435" />
            <path
                id="Path_211"
                d="M12 22a10 10 0 1 1 10-10 10 10 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8 8 0 0 0-8-8z"
                class="cls-2"
                data-name="Path 211"
            />
            <path
                id="Path_212"
                d="M9 16a1 1 0 0 1-.71-1.71l6-6a1 1 0 0 1 1.42 1.42l-6 6A1 1 0 0 1 9 16z"
                class="cls-2"
                data-name="Path 212"
            />
        </g>
    </svg>
)

const AlwaysIcon = props => <SuperIcon component={AlwaysIconSvg} {...props} />

export default AlwaysIcon

import { StyledAlert } from '../Common/components/alert'
import { ContentWrapper } from '../Common/components/header'
import { Input } from 'antd'
import theme from '../Common/theme'
import styled from 'styled-components'
import SearchIcon from '../../assets/icons/SearchIcon'

export const Content = styled(ContentWrapper)`
    && {
        border: none !important;
        display: grid;
        box-sizing: border-box;
        //grid-template-columns: repeat(5, 1fr);
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        grid-template-rows: auto;
        padding: 10px 80px;
        grid-gap: 48px;

        // @media only screen and (min-width: 1200px) {
        //   margin-left: 256px !important;
        // }

        @media only screen and (max-width: 599px) {
            padding: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 64px;
            background-color: ${theme.colors.background} !important;
            border-top: ${theme.borders.thin} solid ${theme.colors.background} !important;
            border-radius: 0px;
        }

        @media (min-width: 600px) and (max-width: 1199px) {
            padding: 24px 124px;
            margin-top: 64px;
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
            grid-gap: 24px;
            background-color: ${theme.colors.background} !important;
        }

        @media (min-width: 956px) and (max-width: 1040px) {
            padding: 24px 84px;
        }
    }
`

export const SearchBar = styled(Input)`
    && .ant-input-lg {
        font-size: ${theme.fontSizes.medium};
    }

    && {
        width: 528px;
        border-radius: 100px;
        outline: none;
        background-color: ${theme.colors.background};
        border-color: ${theme.colors.background};
        align-items: center;

        &:hover,
        &:active,
        &:focus {
            border-color: ${theme.colors.blue} !important;
            box-shadow: none !important;
            background-color: ${theme.colors.white} !important;

            & > input {
                border-color: ${theme.colors.blue} !important;
                background-color: ${theme.colors.white} !important;
            }
        }

        & > input {
            height: inherit;
            width: inherit;
            background-color: ${theme.colors.background} !important;
            &:hover,
            &:active,
            &:focus {
                border-color: ${theme.colors.blue} !important;
                background-color: ${theme.colors.white} !important;
            }

            ::placeholder {
                color: ${theme.colors.inactive};
            }
        }

        @media only screen and (max-width: 1199px) {
            width: 100%;
            height: 100%;
            border-radius: unset;
            margin-top: 0px;
            background-color: ${theme.colors.white} !important;

            & > input {
                border-radius: unset;
                background-color: ${theme.colors.white} !important;
                border-color: ${theme.colors.background};
                padding-left: 20px !important;
            }
        }
    }
`

export const SearchIconMobile = styled(SearchIcon)`
    && {
        width: 100%;
        height: 100%;
        & > svg {
            width: 24px;
            height: 24px;
        }

        .cls-1-search {
            fill: none;
        }
        .cls-2-search {
            fill: ${theme.colors.primary};
        }
    }
`

export const CopiedAlert = styled(StyledAlert)`
    && {
        position: fixed;
        bottom: 2px;
        left: 45%;
        width: 323px;
        height: 56px;
        border-radius: 8px;
        box-shadow: 0 8px 16px 0 rgba(32, 32, 35, 0.08);
        border: solid ${theme.borders.thin} ${theme.colors.green};
        background-color: ${theme.colors.narvik};
        & > .ant-alert-content {
            & > .ant-alert-message {
                display: none;
                margin-bottom: 0px;

                transition: all 0.2s ease-in-out;
            }
            & > .ant-alert-description {
                padding-top: 2px;
                padding-right: 64px;
                margin-left: -8px;
            }
        }
        & > .ant-alert-close-icon {
            line-height: normal;
            margin-left: 0px;
            font-size: inherit;
            margin-top: 3px;
        }
    }
`

export const HeaderInfoWrapper = styled.div`
    font-size: ${theme.fontSizes.mediumBig};
`

import React from 'react'
import { Menu, Tooltip } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import Avatar from './Avatar/Avatar'
import './menu.css'
import { ROUTES } from '../../Routes'
import theme from '../Common/theme'
import EmployeesIcon from '../../assets/icons/EmployeesIcon'
import WorkLogsIcon from '../../assets/icons/WorkLogsIcon'
import KnowledgeIcon from '../../assets/icons/KnowledgeIcon'
import ExternalIcon from '../../assets/icons/ExternalLinkIcon'
import HomeIcon from '../../assets/icons/HomeIcon'
import ChevronDownIcon from '../../assets/icons/ChevronDownIcon'
import LinkMenuIcon from '../../assets/icons/LinkMenuIcon'
import ParabankIcon from '../../assets/icons/ParabankIcon'
import TourIcon from '../../assets/icons/TourIcon'
import SwagShopIcon from '../../assets/icons/SwagShopIcon'
import GitLabIcon from '../../assets/icons/GitLabIcon'
import GorrionBoxIcon from '../../assets/icons/GorrionBoxIcon'
import PassboltLogoIcon from '../../assets/icons/PassboltIcon'
import PlanningPokerIcon from '../../assets/icons/PlanningPokerIcon'
import {
    SubMenu,
    SubMenuItem,
    StyledTitle,
    ExternalLinkContentWrapper,
    ExternalLinkWrapper,
} from './appRoutes.components'

const takeRoute = withRouter(({ handleSubmenuClick, subMenuOpen, openKeys, defaultOpenKeys, collapsed, ...props }) => {
    const { location } = props

    const items = [
        {
            name: 'Wieprzuga',
            url: `${ROUTES.HOME}`,
            icon: <HomeIcon />,
            isExpandable: false,
            isNewPage: false,
        },

        {
            name: 'Wykaz godzin',
            url: `${ROUTES.WORKLOGS}`,
            icon: <WorkLogsIcon />,
            isExpandable: false,
            isNewPage: false,
        },
        {
            name: 'Pracownicy',
            url: `${ROUTES.EMPLOYEES}`,
            icon: <EmployeesIcon />,
            isExpandable: false,
            isNewPage: false,
        },
        // {
        //     name: 'OK System',
        //     url: `${ROUTES.OKSYSTEM}`,
        //     icon: <OKSystemIcon />,
        //     isExpandable: false,
        //     isNewPage: false,
        // },
        {
            name: 'Przydatne linki',
            url: `${ROUTES.OKSYSTEM}`,
            icon: <LinkMenuIcon />,
            isExpandable: true,
            isNewPage: false,
            submenu: [
                {
                    name: 'Poradnik Gorrionka',
                    url: `${ROUTES.KNOWLEDGE}`,
                    icon: <KnowledgeIcon />,
                    isExpandable: false,
                    isNewPage: true,
                    newPageIcon: <ExternalIcon />,
                },
                {
                    name: 'Przewodniki',
                    url: `${ROUTES.TOUR}`,
                    icon: <TourIcon />,
                    isExpandable: false,
                    isNewPage: true,
                },
                {
                    name: 'Parabank',
                    url: `${ROUTES.PARABANK}`,
                    icon: <ParabankIcon />,
                    isExpandable: false,
                    isNewPage: true,
                },
                {
                    name: 'Swag Shop',
                    url: `${ROUTES.SWAGSHOP}`,
                    icon: <SwagShopIcon />,
                    isExpandable: false,
                    isNewPage: true,
                },
                {
                    name: 'Swag Score',
                    url: `${ROUTES.SWAGSCORE}`,
                    icon: <SwagShopIcon />,
                    isExpandable: false,
                    isNewPage: true,
                },
                {
                    name: 'GitLab',
                    url: `${ROUTES.GITLAB}`,
                    icon: <GitLabIcon />,
                    isExpandable: false,
                    isNewPage: true,
                },
                {
                    name: 'Gorrion Box',
                    url: `${ROUTES.BOX}`,
                    icon: <GorrionBoxIcon />,
                    isExpandable: false,
                    isNewPage: true,
                },
                {
                    name: 'Planning Poker',
                    url: `${ROUTES.POKER}`,
                    icon: <PlanningPokerIcon />,
                    isExpandable: false,
                    isNewPage: true,
                },
                {
                    name: 'Vault',
                    url: `${ROUTES.VAULT}`,
                    icon: <PassboltLogoIcon />,
                    isExpandable: false,
                    isNewPage: true,
                },
            ],
        },
    ]

    const menuItems = items.map(item => {
        return item.isExpandable ? (
            <SubMenu
                key="sub1"
                mode="inline"
                expandIcon={
                    <ChevronDownIcon
                        className="ant-submenu-collapse-icon"
                        collapsed={subMenuOpen}
                        highlight={collapsed && subMenuOpen}
                        menuCollapsed={collapsed}
                        baseColor={theme.colors.secondary}
                    />
                }
                onTitleClick={handleSubmenuClick}
                title={
                    !collapsed ? (
                        <>
                            <LinkMenuIcon highlight={subMenuOpen} />
                            <StyledTitle className="nav-text" highlight={subMenuOpen}>
                                {item.name}
                            </StyledTitle>
                        </>
                    ) : (
                        <></>
                    )
                }>
                {item.submenu.map(subitem => (
                    <SubMenuItem key={subitem.url} styles={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                        {subitem.isNewPage ? (
                            collapsed ? (
                                <Tooltip hide={collapsed} placement="left" title={subitem.name}>
                                    <ExternalLinkWrapper
                                        tabIndex={-1}
                                        href={subitem.url}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {subitem.icon}
                                        <ExternalLinkContentWrapper>
                                            <StyledTitle className="nav-text">{subitem.name}</StyledTitle>
                                            <ExternalIcon />
                                        </ExternalLinkContentWrapper>
                                    </ExternalLinkWrapper>
                                </Tooltip>
                            ) : (
                                <ExternalLinkWrapper
                                    tabIndex={-1}
                                    href={subitem.url}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {subitem.icon}
                                    <ExternalLinkContentWrapper>
                                        <StyledTitle className="nav-text">{subitem.name}</StyledTitle>
                                        <ExternalIcon />
                                    </ExternalLinkContentWrapper>
                                </ExternalLinkWrapper>
                            )
                        ) : collapsed ? (
                            <Tooltip hide={collapsed} placement="left" title={subitem.name}>
                                <Link tabIndex={-1} to={subitem.url}>
                                    {subitem.icon}
                                    <StyledTitle className="nav-text">{subitem.name}</StyledTitle>
                                </Link>
                            </Tooltip>
                        ) : (
                            <Link tabIndex={-1} to={subitem.url}>
                                {subitem.icon}
                                <StyledTitle className="nav-text">{subitem.name}</StyledTitle>
                            </Link>
                        )}
                    </SubMenuItem>
                ))}
            </SubMenu>
        ) : (
            <Menu.Item key={item.url} styles={{ color: 'rgba(0, 0, 0, 0.8)' }} onClick={props.itemChosen}>
                {item.isNewPage ? (
                    collapsed ? (
                        <Tooltip hide={collapsed} placement="right" title={item.name}>
                            <ExternalLinkWrapper
                                tabIndex={-1}
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer">
                                {item.icon}
                                <ExternalLinkContentWrapper>
                                    <StyledTitle className="nav-text">{item.name}</StyledTitle>
                                    {item.newPageIcon}
                                </ExternalLinkContentWrapper>
                            </ExternalLinkWrapper>
                        </Tooltip>
                    ) : (
                        <ExternalLinkWrapper tabIndex={-1} href={item.url} target="_blank" rel="noopener noreferrer">
                            {item.icon}
                            <ExternalLinkContentWrapper>
                                <StyledTitle className="nav-text">{item.name}</StyledTitle>
                                {item.newPageIcon}
                            </ExternalLinkContentWrapper>
                        </ExternalLinkWrapper>
                    )
                ) : collapsed ? (
                    <Tooltip hide={collapsed} placement="right" title={item.name}>
                        <Link tabIndex={-1} to={item.url}>
                            {item.icon}
                            <StyledTitle className="nav-text">{item.name}</StyledTitle>
                        </Link>
                    </Tooltip>
                ) : (
                    <Link tabIndex={-1} to={item.url}>
                        {item.icon}
                        <StyledTitle className="nav-text">{item.name}</StyledTitle>
                    </Link>
                )}
            </Menu.Item>
        )
    })

    return (
        <Menu
            mode="inline"
            key="menu1"
            theme="light"
            inlineIndent="0px"
            selectedKeys={location.pathname}
            triggerSubMenuAction={'click'}
            forceSubMenuRender={true}
            openKeys={openKeys}
            styles={{ backgroundColor: theme.colors.background }}>
            <Avatar collapsed={collapsed} className="nav-text"></Avatar>
            {menuItems}
        </Menu>
    )
})

export default takeRoute

import React, { useState } from 'react'
import { Formik } from 'formik'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Label, IconPassword, InputPasswordContainer, StyledInputPassword } from '../Common/components/form'
import { ButtonPrimary } from '../Common/components/buttons'
import { LoginPageContainer, LoginWrapper, PasswordInformation } from './Login.components'
import { AlignRightContainer } from '../Common/components/layout'
import { H2 } from '../Common/components/typography'
import { ROUTES } from '../../Routes'
import { ErrorMessage } from '../Common/components/alert'
import { resetPassword } from './services/useAuthorization'
import HidePasswordIcon from '../../assets/icons/HidePasswordIcon'
import ShowPasswordIcon from '../../assets/icons/ShowPasswordIcon'
import LogoIcon from '../../assets/icons/LogoIcon'

const PasswordReset = props => {
    const [password] = useState('')

    const [isPasswordValid, setIsPasswordValid] = useState(true)
    const [isFormView, setIsFormView] = useState(true)
    const [showPasswordIcon, setShowPasswordIcon] = useState(false)
    const [params] = useState({
        hash: props.location.search && props.location.search.substring(6, props.location.search.indexOf('&id=')),
        id: props.location.search && props.location.search.substring(props.location.search.indexOf('&id=') + 4),
    })
    const sendPassword = async credentials => {
        if (validatePassword(credentials.password)) {
            const resetPasswordData = {
                employeeId: params.id,
                hash: params.hash,
                newPassword: credentials.password,
            }
            resetPassword(resetPasswordData).then(
                res => {
                    setIsFormView(false)
                },
                error => {
                    props.history.push(`${ROUTES.PASSWORD_LINK}?token-expired`)
                },
            )
        }
    }

    const validatePassword = password => {
        setIsPasswordValid(password !== '')

        return password !== ''
    }

    return (
        <LoginPageContainer>
            <LoginWrapper>
                <LogoIcon marginBottom="40px" />
                <H2>Odzyskiwanie hasła</H2>
                {isFormView ? (
                    <>
                        <PasswordInformation>
                            Wprowadź swoje nowe hasło. Pamiętaj o&nbsp;kwestiach bezpieczeństwa!
                        </PasswordInformation>
                        <Formik
                            initialValues={{
                                password: password || '',
                            }}
                            onSubmit={async (values, actions) => {
                                await sendPassword(values)
                            }}
                            render={props => (
                                <Form onSubmit={props.handleSubmit}>
                                    <Label className="label">Hasło</Label>
                                    <Form.Item>
                                        <InputPasswordContainer>
                                            <StyledInputPassword
                                                name="password"
                                                autoComplete="off"
                                                type={showPasswordIcon ? 'text' : 'password'}
                                                value={props.values.password}
                                                onChange={props.handleChange}
                                                onKeyUp={() => {
                                                    if (!isPasswordValid) validatePassword(props.values.password)
                                                }}
                                                hasError={!isPasswordValid}></StyledInputPassword>
                                            <IconPassword
                                                showPasswordIcon={showPasswordIcon}
                                                onClick={() => {
                                                    setShowPasswordIcon(!showPasswordIcon)
                                                }}>
                                                {showPasswordIcon ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                                            </IconPassword>
                                        </InputPasswordContainer>
                                        <ErrorMessage>{isPasswordValid ? '' : 'Wypełnij to pole'}</ErrorMessage>
                                    </Form.Item>
                                    <AlignRightContainer marginTop={isPasswordValid ? '64px' : '34px'}>
                                        <ButtonPrimary htmlType="submit">Ustaw</ButtonPrimary>
                                    </AlignRightContainer>
                                </Form>
                            )}
                        />
                    </>
                ) : (
                    <>
                        <PasswordInformation>
                            Twoje hasło zostało zmienione. Możesz się teraz zalogować.
                        </PasswordInformation>
                        <AlignRightContainer marginTop="32px">
                            <ButtonPrimary onClick={() => props.history.push(ROUTES.LOGIN)}>Zaloguj</ButtonPrimary>
                        </AlignRightContainer>
                    </>
                )}
            </LoginWrapper>
        </LoginPageContainer>
    )
}

export default PasswordReset

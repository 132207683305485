import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'

const SuperIcon = styled(Icon)`
    padding-top: 4px;
`

const SwagShopSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 20C15.4762 20 19.9206 15.6085 20 10.1323H11.9577C11.9577 11.7196 13.2275 12.9894 14.8148 12.9894H15.873C14.7619 15.1323 12.5397 16.6138 9.97355 16.6138C6.32275 16.6138 3.35979 13.6508 3.35979 10C3.35979 6.34921 6.32275 3.38624 9.97355 3.38624C12.672 3.38624 15 5 16.0317 7.32804L20 9.68254C19.8413 4.31217 15.4233 0 10 0C4.4709 0 0 4.4709 0 10C0 15.5291 4.4709 20 10 20Z"
            fill="#212529"
        />
        <path
            d="M8.70371 8.4127C8.70371 7.71139 9.27224 7.14286 9.97355 7.14286C10.6749 7.14286 11.2434 7.71139 11.2434 8.4127C11.2434 9.11401 10.6749 9.68254 9.97355 9.68254C9.27224 9.68254 8.70371 9.11401 8.70371 8.4127Z"
            fill="#212529"
        />
    </svg>
)

const SwagShopIcon = props => <SuperIcon className="icon" component={SwagShopSvg} {...props} />

export default SwagShopIcon

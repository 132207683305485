import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'

const SuperIcon = styled(Icon)`
    opacity: ${props => (props.collapsed ? '1' : '0')};
    transition: opacity ${props => (props.collapsed ? '0.1s' : '0.3s')} cubic-bezier(0.645, 0.045, 0.355, 1);
    height: ${props => (props.height ? props.height : '24px')};
    width: ${props => (props.width ? props.width : '42px')};
    margin: ${props => (props.margin ? props.margin : '0 auto')};
`

const ShortLogoSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="24" viewBox="0 0 42 24">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <rect class="cls-1" fill="#fec707" width="42" height="24" />
                <path
                    class="cls-2"
                    fill="#fff"
                    d="M17.64,11.25A4.64,4.64,0,0,1,19,14.77a4.19,4.19,0,0,1-1.42,3.3,5.31,5.31,0,0,1-3.72,1.26A4.89,4.89,0,0,1,10.38,18,5.39,5.39,0,0,1,8.75,14.4c-.34-4.36.42-9.92,5.73-9.72a4.54,4.54,0,0,1,4.36,2.74L16,8.63a1.73,1.73,0,0,0-1.64-1.12c-2,0-2.74,2.14-2.59,4.08a2.42,2.42,0,0,1,.93-1.06A3.21,3.21,0,0,1,14.63,10,4.21,4.21,0,0,1,17.64,11.25ZM15.28,13.3a1.78,1.78,0,0,0-2.61,0,1.85,1.85,0,0,0,2.61,2.61A1.78,1.78,0,0,0,15.28,13.3Z"
                />
                <path
                    class="cls-2"
                    fill="#fff"
                    d="M26.56,11.82l6.76,7.29H29.13L25,14.39v4.72H21.74V4.67H25V9.14l3.26-4.47H32Z"
                />
            </g>
        </g>
    </svg>
)

const ShortLogoIcon = props => <SuperIcon component={ShortLogoSvg} {...props} />

export default ShortLogoIcon

import React, { useContext } from 'react'
import './legend.css'
import { WorkLogsContext } from '../../WorkLogs/context/WorkLogsContext'
import CalendarNavigation from './CalendarNavigation/CalendarNavigation'
import convertNumberToTime from '../../../shared/convertNumberToTime'
import {
    HeaderWrapper,
    Sum,
    SumWrapper,
    Hour,
    HourSumAfterSlash,
    HourSumIcon,
    HourSumWrapper,
    ButtonWrapper,
    ProjectButton,
    StyledLabel,
} from './Legend.components'
import DocumentIcon from '../../../assets/icons/DocumentIcon'

const Legend = props => {
    const { worklogsForCurrentMonth: workLogs, setShowProjects, holidays } = useContext(WorkLogsContext)

    const totalHoursWorkedInMonth = workLogs.reduce((pv, cv) => (pv += parseFloat(cv.workedHours, 10)), 0)
    const filteredHolidays = [...holidays].filter(h => h.isPaid)
    const totalHolidayHoursTaken = filteredHolidays.reduce((pv, cv) => (pv += parseFloat(cv.hours, 10)), 0)

    const openProjectDrawer = () => {
        setShowProjects(true)
    }

    return (
        <HeaderWrapper>
            <SumWrapper>
                <Sum>
                    <HourSumIcon />
                    <HourSumWrapper>
                        {convertNumberToTime(totalHoursWorkedInMonth + totalHolidayHoursTaken)}
                    </HourSumWrapper>
                    <HourSumAfterSlash>/ {props.totalPossibleHoursUntilNow}</HourSumAfterSlash>
                    <Hour>h</Hour>
                </Sum>
            </SumWrapper>
            <ButtonWrapper>
                <ProjectButton onClick={openProjectDrawer} data-cy="today" type="link" tabIndex={-1}>
                    <DocumentIcon />
                    <StyledLabel>Projekty</StyledLabel>
                </ProjectButton>
                <CalendarNavigation context={WorkLogsContext} />
            </ButtonWrapper>
        </HeaderWrapper>
    )
}

export default Legend

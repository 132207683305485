import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  z-index: 90;
  background-color: black;
  width: 100%;
  height: 100%;
  position: absolute;
  animation: fadeIn 1s;
  opacity: 0.7;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.7;
    }
  }
`;

const Backdrop = (props) =>
  props.show ? <Wrapper onClick={props.clicked}></Wrapper> : null;

export default Backdrop;

import styled, { css } from 'styled-components'
import EmailIcon from '../../../assets/icons/EmailIcon'
import PhoneIcon from '../../../assets/icons/PhoneIcon'
import theme from '../../Common/theme'

export const EmployeeWrapper = styled.div`
    display: flex;
    height: 262px;
    width: 248px;
    border-radius: 8px;
    border: solid ${theme.borders.thin} ${theme.colors.border};
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    user-select: none;
    transition: transform 0.2s ease-in-out;

    &:hover .header {
        color: blue !important;
    }
    &:hover {
        border: solid ${theme.borders.thin} ${theme.colors.blue};
        box-shadow: 0 4px 8px 0 rgba(32, 32, 35, 0.16);
        transform: translate(0px, -8px);
    }

    @media only screen and (max-width: 599px) {
        height: 64px;
        width: 90%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: none;
        margin-top: 16px;
        background-color: ${theme.colors.white};

        &:hover .header {
            color: ${theme.colors.primary} !important;
        }
        &:hover {
            border: none;
            box-shadow: none;
            transform: none;
        }

        &:last-child {
            margin-bottom: 80px;
        }
    }
`

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    padding-top: 32px;

    @media only screen and (max-width: 599px) {
        padding-top: 0px;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        width: 70%;
        text-align: left;
    }
`

export const NameWrapper = styled.div`
    text-align: center;
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumBig};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${theme.colors.primary};
    padding: 0 32px;
    @media only screen and (max-width: 599px) {
        font-size: ${theme.fontSizes.mediumSmall};
        text-align: left;
        padding: 0px;
        padding-left: 16px;
    }
`

export const Avatar = styled.img`
    width: 64px;
    height: 64px;
    border: solid ${theme.borders.thin} ${theme.colors.border};
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 23px;

    @media only screen and (max-width: 599px) {
        width: 40px;
        height: 40px;
        margin: 0px 0px 0px 16px;
    }
`

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    justify-content: center;
    width: 100%;

    @media only screen and (max-width: 599px) {
        height: 100%;
        align-items: center;
        margin: 0px;
        width: 30%;
    }
`

export const buttonBase = css`
    width: 40px;
    height: 40px;
    cursor: pointer;
    text-align: center;
    top: calc(50% - 18px);

    &:hover {
        border: ${theme.borders.extraThick};
        border-radius: 50%;
        background-color: rgba(0, 44, 193, 0.08);
    }
`
export const EmailButton = styled.div`
    ${props => buttonBase}
    && {
        margin-right: 4px;
    }
`

export const PhoneButton = styled.div`
    ${props => buttonBase}
    && {
        margin-left: 4px;
    }
`

export const buttonIcon = css`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        & > svg {
            width: 24px;
            height: 24px;
        }
        .cls-1 {
            fill: none;
        }
        .cls-2 {
            fill: ${theme.colors.inactive};
        }

        &:hover {
            & > svg > g > g > .cls-2 {
                fill: ${theme.colors.primary};
            }
        }
    }
`

export const EmailButtonIcon = styled(EmailIcon)`
    ${props => buttonIcon}
`

export const PhoneButtonIcon = styled(PhoneIcon)`
    ${props => buttonIcon}
`

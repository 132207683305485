import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'

const SuperIcon = styled(Icon)`
    height: ${props => (props.height ? props.height : '16px')};
    width: ${props => (props.width ? props.width : '16px')};
    margin-left: 6px;
`

const HolidaySvg = () => (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.5713 0.966667C12.5713 0.433333 12.138 0 11.6046 0H1.53796C1.00462 0 0.571289 0.433333 0.571289 0.966667C0.571289 1.2 0.657956 1.43333 0.817956 1.60667L5.90462 7.33333V10.6667H3.23796C2.87129 10.6667 2.57129 10.9667 2.57129 11.3333C2.57129 11.7 2.87129 12 3.23796 12H9.90462C10.2713 12 10.5713 11.7 10.5713 11.3333C10.5713 10.9667 10.2713 10.6667 9.90462 10.6667H7.23796V7.33333L12.3246 1.60667C12.4846 1.43333 12.5713 1.2 12.5713 0.966667ZM3.52462 2.66667L2.34462 1.33333H10.8046L9.61796 2.66667H3.52462Z"
            fill="#002CC1"
        />
    </svg>
)

const HolidayIcon = props => <SuperIcon component={HolidaySvg} {...props} />

export default HolidayIcon

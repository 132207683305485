import styled from 'styled-components'
import { transparentize } from 'polished'
import { LogInputBase } from '../../../Common/components/form'
import theme from '../../../Common/theme'

export const LogInput = styled(LogInputBase)`
    background: ${props => transparentize(props.transparentizeBackground, props.bgColor)};
    border: ${theme.borders.medium} solid ${props => transparentize(props.transparentizeBorder, props.borderColor)};
    opacity: 100%;
    line-height: inherit;
    animation: fadeIn 1.5s;

    ::-webkit-input-placeholder {
        opacity: 0;
        text-transform: lowercase;
        color: ${theme.colors.inactive};
    }

    ::-moz-placeholder {
        opacity: 0;
        text-transform: lowercase;
        color: ${theme.colors.inactive};
    }

    &:focus {
        border: ${theme.borders.thin} solid ${props => transparentize(props.transparentizeBorder, props.borderColor)};
        ::-webkit-input-placeholder {
            opacity: 100%;
            color: ${theme.colors.inactive};
        }
        ::-moz-placeholder {
            opacity: 100%;
            color: ${theme.colors.inactive};
        }
    }

    @media only screen and (max-width: 1199px) {
        &:focus {
            border-width: ${theme.borders.thin};
        }
    }
`

export const LogInputWrapper = styled.div`
    background-color: ${theme.colors.white};
    margin-bottom: 8px;
    margin-right: 8px;
    border-radius: 40px;

    @media only screen and (max-width: 1199px) {
        margin-bottom: 0px;
    }
`

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const EmployeesSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" fill="none" width="24" height="24" />
                <path className="cls-2" d="M12,12a5,5,0,1,1,5-5A5,5,0,0,1,12,12Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,12,4Z" />
                <path
                    className="cls-2"
                    d="M18,22a1,1,0,0,1-1-1A5,5,0,0,0,7,21a1,1,0,0,1-2,0,7,7,0,0,1,14,0A1,1,0,0,1,18,22Z"
                />
            </g>
        </g>
    </svg>
)

const EmployeesIcon = props => <SuperIcon component={EmployeesSvg} {...props} />

export default EmployeesIcon

import React, { useContext } from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../Routes'
import { UserContext } from '../../Common/contexts/userContext'
import LogoutIcon from '../../../assets/icons/LogoutIcon'
import { LogoutButton } from './Logout.components'
import '../menu.css'

const Logout = ({ scrollable, ...props }) => {
    const user = useContext(UserContext)
    const resetAndLogout = () => {
        localStorage.removeItem('token')
        user.clear()
    }
    return (
        <LogoutButton menuScrollable={scrollable} mode="inline" theme="light" className="logout">
            <Menu.Item key="wyloguj" onClick={() => resetAndLogout()}>
                <Link tabIndex={-1} data-cy="logout" to={ROUTES.LOGIN}>
                    <LogoutIcon />
                    <span className="nav-text">Wyloguj</span>
                </Link>
            </Menu.Item>
        </LogoutButton>
    )
}

export default Logout

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.error)};
    }
`

const ErrorSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2" transform="rotate(180 12 12)">
            <g id="Layer_2-2" data-name="Layer 2">
                <path id="Rectangle_338" d="M0 0h24v24H0z" fill="none" data-name="Rectangle 338" />
                <path
                    id="Path_123"
                    d="M12 22a10 10 0 1 1 10-10 10 10 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8 8 0 0 0-8-8z"
                    className="cls-2"
                    data-name="Path 123"
                />
                <circle
                    id="Ellipse_159"
                    cx="1"
                    cy="1"
                    r="1"
                    className="cls-2"
                    data-name="Ellipse 159"
                    transform="translate(11 7)"
                />
                <path
                    id="Path_124"
                    d="M12 17a1 1 0 0 1-1-1v-4a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1z"
                    className="cls-2"
                    data-name="Path 124"
                />
            </g>
        </g>
    </svg>
)

const ErrorIcon = props => <SuperIcon component={ErrorSvg} {...props} />

export default ErrorIcon

import styled from 'styled-components'
import { ButtonBlank, CloseButtonMobile } from '../../Common/components/buttons'
import theme from '../../Common/theme'

export const Header = styled.div`
    position: sticky;
    top: 0;
    background-color: ${theme.colors.white};
    user-select: none;
`
export const Navigation = styled.div`
    width: 100%;
    padding: 24px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: solid ${theme.borders.thin} ${theme.colors.border};
`

export const CloseButton = styled(CloseButtonMobile)`
    && {
        margin: 0px;

        &:hover {
            background-color: transparent !important;
        }
    }
`

export const HeaderButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 64px;
    background-color: ${theme.colors.background};
    padding: 14px 24px !important;
`

export const ProjectColor = styled.div`
    border-radius: 50%;
    background-color: ${props => props.color};
    border: ${theme.borders.thin} solid ${props => props.color};
    width: 8px;
    height: 8px;
    margin-right: 16px;
    margin-left: 24px;
`

export const Project = styled.div`
    font-size: ${theme.fontSizes.mediumBig};
    line-height: 1.5;
    text-align: left;
    color: ${theme.colors.primary};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
`

export const ProjectsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 48px;
    user-select: none;
    &:hover {
        background-color: ${theme.colors.athensGray};

        > div > div > .hour-container {
            display: none !important;
        }

        > div > div > div {
            display: flex;
        }
    }

    &:last-child {
        margin-bottom: 24px;
    }
`

export const Title = styled.div`
    width: 100%;
    font-size: ${theme.fontSizes.big};
    text-align: left;
    color: ${theme.colors.primary};
`

export const DrawerTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 24px;
`

export const ProjectCount = styled.div`
    border-radius: 50%;
    background-color: ${theme.colors.background};
    width: 36px !important;
    height: 31px;
    margin-top: 2px;
    text-align: center;
    padding-top: 3px;
    font-size: ${theme.fontSizes.mediumBig};
    font-family: SofiaProRegular;
    padding-right: 1px;
    font-weight: 600;
`

export const ProjectHourSum = styled.div`
    height: 64px;
    border-radius: 8px;
    border: solid ${theme.borders.thin} ${theme.colors.blue};
    background-color: ${theme.colors.disabled};
    margin: 24px 24px 24px 24px;
    padding: 0px 24px;
    color: ${theme.colors.primary};
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const HourSumWrapper = styled.span`
    padding-left: 8px;
    padding-bottom: 5px;
    font-size: ${theme.fontSizes.big};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;

    @media only screen and (max-width: 1199px) {
        padding-left: 16px !important;
    }
`

export const LegendItem = styled.div`
    color: ${theme.colors.primary};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    height: 25px;
`

export const ProjectName = styled.span`
    min-width: max-content;
    margin-top: 2px;
`

export const LegendItemHours = styled.span`
    color: ${theme.colors.inactive};
    font-weight: 500;
`

export const ColorButtonContainer = styled.div`
    height: 25px;
    display: none;
    justify-content: flex-end;
    align-items: center;
`

export const ColorButton = styled(ButtonBlank)`
    && {
        height: 38px;
        width: 38px !important;
        border-radius: 50%;
        float: right;
        background-color: ${theme.colors.hover} !important;
        border: none;
        box-shadow: none;
        position: relative;
        right: 0;
        bottom: 0;

        &::after {
            content: 'Wybór koloru';
            background-color: ${theme.colors.secondary};
            color: ${theme.colors.white};
            display: inline-block;
            position: absolute;
            opacity: 0;
            bottom: 40px;
            left: 16px;
            width: 80px;
            pointer-events: none;
            height: 24px;
            font-size: ${theme.fontSizes.small};
            text-align: center;
            padding: 5px 10px;
            border-radius: 4px;
            transform: translateX(-50%);
            transition: opacity 0.2s ease-in-out;
            box-shadow: 0 2px 4px 0 rgba(32, 32, 35, 0.24);
        }

        &:hover {
            background-color: ${theme.colors.border} !important;
            &::after {
                opacity: 1;
            }
        }
    }
`

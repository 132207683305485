import { ButtonBlank } from '../Common/components/buttons'
import styled from 'styled-components'
import { StyledAlert } from '../Common/components/alert'
import theme from '../Common/theme'
import { DayHeader } from '../Common/components/calendar'
import { DayHeaderMobile, Day } from '../Common/components/calendar'

export const DailyHourSum = styled.span`
    height: 32px;
    font-family: SofiaProMedium;
    font-size: ${theme.fontSizes.large};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    opacity: 100%;
    line-height: 0.69;
    letter-spacing: normal;
    text-align: right;
    color: ${props => props.sumColor};
    visibility: ${props => (props.hide ? 'hidden' : 'visible')};
    animation: fadeIn 1.5s;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @media (min-width: 1200px) and (max-width: 1440px) {
        font-size: ${theme.fontSizes.big};
    }

    @media only screen and (max-width: 1199px) {
        font-size: ${theme.fontSizes.big};
        line-height: 0.92;
        margin-bottom: -7px;
    }
`

export const Hour = styled.span`
    font-size: ${theme.fontSizes.medium};
    color: ${props => props.sumColor};
    font-weight: 100;

    @media only screen and (max-width: 1199px) {
        padding-right: 8px;
    }
`

export const ErrorAlert = styled(StyledAlert)`
    && {
        position: fixed;
        bottom: 12%;
        margin: 0% 5%;
        justify-self: center;
        justify-content: center;
        align-items: center;

        & > .ant-alert-message {
            display: none;
            transition: all 0.2s ease-in-out;
        }
        & > .ant-alert-description {
            padding-right: 20px;
        }
    }
`

export const DayCellHeader = styled(DayHeader)`
    display: flex;
    flex-direction: row;

    && {
        margin-bottom: 8px;
        text-transform: capitalize;
        color: ${props =>
            props.bankHoliday ? theme.colors.error : props.isHoliday ? theme.colors.blue : theme.colors.primary};
    }
`

export const InputsWrapper = styled.div`
    opacity: ${props => (props.isFuture ? 0.9 : 1)};
`

export const CommentButtonContainer = styled.div`
    height: 24px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`

export const CommentButton = styled(ButtonBlank)`
    && {
        height: 24px;
        width: 24px !important;
        border-radius: 50%;
        float: right;
        background-color: ${theme.colors.athensGray} !important;
        border: none;
        box-shadow: none;
        padding: 0px;
        display: ${props => (props.visible ? 'inline-block' : 'none')};
        padding-top: 2px;
        position: relative;
        right: 0;
        bottom: 0;
    }

    &::after {
        content: 'Notatki';
        background-color: ${theme.colors.secondary};
        color: ${theme.colors.white};
        display: inline-block;
        position: absolute;
        opacity: 0;
        bottom: 32px;
        left: 12px;
        width: 57px;
        pointer-events: none;
        height: 24px;
        font-size: ${theme.fontSizes.small};
        text-align: center;
        padding: 5px 10px;
        border-radius: 4px;
        transform: translateX(-50%);
        transition: opacity 0.2s ease-in-out;
        box-shadow: 0 2px 4px 0 rgba(32, 32, 35, 0.24);
    }

    &:hover {
        .comment-button {
            fill: black !important;
        }
        &::after {
            opacity: 1;
        }
    }
`

export const DayCellHeaderMobile = styled(DayHeaderMobile)`
    && {
        text-transform: capitalize;
        color: ${props => (props.holiday ? theme.colors.white : theme.colors.primary)} !important;
    }
`

export const DayWrapperMobile = styled(Day)`
    display: flex;
    flex-direction: row;

    && {
        min-height: 64px !important;
        transition: min-height 0.2s ease-in;
        height: fit-content;

        @media (min-width: 768px) and (max-width: 1199px) {
            max-width: 450px;
            width: 100%;
        }
    }
`

const convertNumberToTime = number => {
  const numberInMinutes = number * 60;
  const hours = numberInMinutes / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  if (rminutes === 0) return rhours;
  return `${rhours}:${rminutes.toString().padStart(2, "0")}`;
};

export default convertNumberToTime;

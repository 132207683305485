import React from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import ErrorIcon from '../../../assets/icons/ErrorIcon'
import { ButtonPrimary } from './buttons'
import theme from '../../Common/theme'

const ModalErrorIcon = styled(ErrorIcon)`
    && {
        border-radius: 50%;
        background-color: rgba(0, 44, 193, 0.08);
        padding: 8px;

        align-items: center;
        justify-content: center;

        & > svg > g > g > .cls-2 {
            fill: blue;
        }
    }
`

const ModalHeader = styled.span`
    margin-bottom: 28px;
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.big};
    color: ${theme.colors.primary};
    padding-left: 16px;
`

const ModalBody = styled.p`
    margin-top: 28px;
    margin-right: 8px;
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.secondary};
`

const ModalFooter = styled.div`
    margin-bottom: 12px;
    padding-top: 48px;
    display: flex;
    justify-content: flex-end;
`

const ErrorModal = props => {
    return (
        <Modal
            title="Ups... coś poszło nie tak."
            visible={!!props.error}
            onOk={() => props.handleOk()}
            onCancel={() => props.handleCancel()}
            afterClose={() => props.handleCancel()}
            maskClosable={true}
            centered>
            <ModalErrorIcon />
            <ModalHeader>Ups... coś poszło nie tak.</ModalHeader>
            <ModalBody>{props.children}</ModalBody>
            <ModalFooter>
                <ButtonPrimary onClick={props.handleOk} type="button">
                    Spróbuj ponownie
                </ButtonPrimary>
            </ModalFooter>
        </Modal>
    )
}

export default ErrorModal

import styled from 'styled-components'
import { Button, DatePicker } from 'antd'
import theme from '../theme'

const { MonthPicker } = DatePicker

export const HeaderWrapper = styled.div`
    font-size: ${theme.fontSizes.medium};
    height: 100%;
    color: ${theme.colors.inactive};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-flow: row nowrap;
`
export const TodayButton = styled(Button)`
    && {
        margin-right: 16px;
        width: 74px;
        height: 36px;
        border-radius: 4px !important;
        border: solid ${theme.borders.thin} ${theme.colors.border};
        background-color: ${theme.colors.white};
        color: ${theme.colors.primary};
        &::after {
            animation-duration: 0s !important;
        }
        &:focus,
        &:hover {
            border-color: ${theme.colors.blue};
            color: ${theme.colors.blue};
        }
    }
`

export const PreviousMonthButton = styled(Button)`
    && {
        margin-right: 8px;
        width: 36px;
        height: 36px;
        border-radius: 4px !important;
        border: solid ${theme.borders.thin} ${theme.colors.border};
        background-color: ${theme.colors.white};
        text-align: center;
        &::after {
            animation-duration: 0s !important;
        }
        &:focus,
        &:hover {
            border-color: ${theme.colors.blue};
            color: ${theme.colors.blue};
        }
    }
`

export const NextMonthButton = styled(Button)`
    && {
        width: 36px;
        height: 36px;
        border-radius: 4px !important;
        border: solid ${theme.borders.thin} ${theme.colors.border};
        background-color: ${theme.colors.white};
        text-align: center;
        &::after {
            animation-duration: 0s !important;
        }
        &:focus,
        &:hover,
        &:active {
            border-color: ${theme.colors.blue};
            color: ${theme.colors.blue};
        }
    }
`

export const PickDate = styled(MonthPicker)`
    && {
        width: 168px;
        height: 36px;
        background-color: ${theme.colors.white};
        margin-left: 16px !important;

        div > input {
            cursor: pointer;
            width: 168px;
            height: 36px;
            color: ${theme.colors.primary} !important;
            text-transform: capitalize;
            font-size: ${theme.fontSizes.medium};
            &:focus,
            &:hover {
                border-color: ${theme.colors.blue};
                box-shadow: none;
            }
        }

        div > i {
            color: ${theme.colors.primary};
        }
        &:focus,
        &:hover {
            div > input {
                border-color: ${theme.colors.blue} !important;
                box-shadow: none !important;
            }
        }
    }
`

export const NavigationWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const HourSumWrapper = styled.span`
    padding-left: 8px;
    color: ${props => props.color || theme.colors.inactive};
    font-size: ${theme.fontSizes.big};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
`

export const OKSumWrapper = styled.span`
    padding-left: 8px;
    color: ${props => props.color || theme.colors.inactive};
    font-size: ${theme.fontSizes.big};
    font-weight: 500;
    padding-bottom: 3px;
`

export const Sum = styled.div`
    vertical-align: bottom;
    user-select: none;
`

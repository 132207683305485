import styled from 'styled-components'
import theme from '../../Common/theme'

export const AvatarWrapper = styled.div`
    background: ${theme.colors.background};
    padding-left: 14px;
    padding-top: 8px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-overflow: clip;
    width: 100%;
    border-top: solid ${theme.borders.thin} ${theme.colors.border};
    margin-top: 16px;
`

export const ImageWrapper = styled.div`
    height: 100%;
    padding-left: 5px;
    padding-top: 1px;
    margin-right: 20px;
    line-height: 1.43;
    margin-bottom: 6px;
`

export const NameWrapper = styled.span`
    opacity: ${props => (props.collapsed ? '0' : '1')};
    transition: opacity ${props => (props.collapsed ? '0.1s' : '1s')} cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    font-family: 'SofiaProRegular';
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.secondary};
    cursor: default;
    text-overflow: clip !important;

    animation: ${props => (props.collapsed ? 'hide-me 0.3s' : 'show-me 0.3s')} cubic-bezier(0.645, 0.045, 0.355, 1);
    @keyframes show-me {
        0% {
            transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
        }
        // 50% {
        //     background: orange;
        // }
        100% {
            background: black;
        }
    }

    @keyframes hide-me {
        0% {
            background: orange;
        }
        // 50% {
        //     background: orange;
        // }
        100% {
            background: black;
        }
    }
`

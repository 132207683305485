/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useLayoutEffect, useEffect } from 'react'
import { BoxTitle } from '../../Common/components/dashboard'
import { UserContext } from '../../Common/contexts/userContext'
import Emoji from '../../../shared/Emoji'
import DashboardGorrionIcon from '../../../assets/icons/DashboardGorrionIcon'
import theme from '../../Common/theme'
import {
    icons,
    WagesWrapper,
    Header,
    SwitchView,
    GraphWrapper,
    PreviousMonthPayment,
    PreviousMonthSumWrapper,
    GrayWord,
    CurrentMonthPayment,
    CurrentMonthSumWrapper,
    HourCount,
    HourSumWrapper,
    LegendWrapper,
    InfoText,
    InfoWrapper,
    Color,
} from './DashboardWages.components'

const DashboardWages = props => {
    const [show, setShow] = useState(false)
    const [currentIcon, setCurrentIcon] = useState()
    const user = useContext(UserContext)

    useLayoutEffect(() => {
        user.get()
    }, [])

    const handleSwitch = checked => {
        setShow(checked)
    }

    useEffect(() => {
        if (!show) {
            const randomIcon = icons[Math.floor(Math.random() * icons.length)]
            setCurrentIcon(randomIcon)
        }
    }, [show])

    return (
        <WagesWrapper>
            <Header>
                <BoxTitle>Wynagrodzenie</BoxTitle>
                <SwitchView onChange={handleSwitch} />
            </Header>
            <GraphWrapper>
                <PreviousMonthSumWrapper>
                    <PreviousMonthPayment iconView={!show}>
                        {show ? (
                            user.details.previousPeriodStats ? (
                                user.details.previousPeriodStats.moneyEarned.toFixed(0)
                            ) : (
                                <Emoji symbol={currentIcon} />
                            )
                        ) : (
                            <Emoji symbol={currentIcon} />
                        )}
                    </PreviousMonthPayment>
                    <GrayWord>złotych</GrayWord>
                </PreviousMonthSumWrapper>
                <CurrentMonthSumWrapper>
                    <CurrentMonthPayment iconView={!show}>
                        {show ? user.details.currentPeriodStats.moneyEarned.toFixed(0) : <Emoji symbol={currentIcon} />}
                    </CurrentMonthPayment>
                    <GrayWord>złotych</GrayWord>
                </CurrentMonthSumWrapper>
                <HourSumWrapper>
                    <HourCount>{user.details.currentPeriodStats.hoursWorked}</HourCount>
                    <GrayWord>godzin</GrayWord>
                </HourSumWrapper>
                <LegendWrapper>
                    <InfoWrapper>
                        <Color bgColor={theme.colors.yellow}></Color>
                        <InfoText>Obecny miesiąc</InfoText>
                    </InfoWrapper>
                    <InfoWrapper>
                        <Color bgColor={theme.colors.pink}></Color>
                        <InfoText>Liczba godzin</InfoText>
                    </InfoWrapper>
                    <InfoWrapper>
                        <Color bgColor={theme.colors.purple}></Color>
                        <InfoText>Poprzedni miesiąc</InfoText>
                    </InfoWrapper>
                </LegendWrapper>
            </GraphWrapper>
            <DashboardGorrionIcon />
        </WagesWrapper>
    )
}

export default DashboardWages

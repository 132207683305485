import React from 'react'
import Icon from '@ant-design/icons'

const EmailSvg = () => (
    <svg className="email-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" width="24" height="24" />
                <path
                    className="cls-2"
                    d="M20.86,4.65s0,0,0,0A3,3,0,0,0,19,4H5a3,3,0,0,0-1.84.64A.85.85,0,0,0,3,4.8H3A2.9,2.9,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,20.86,4.65ZM17.91,6l-5.28,4.22a1,1,0,0,1-1.26,0L6.09,6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7a.37.37,0,0,1,0-.11l6.11,4.89a3,3,0,0,0,3.76,0L20,6.89A.37.37,0,0,1,20,7Z"
                />
            </g>
        </g>
    </svg>
)

const EmailIcon = props => <Icon className="icon" component={EmailSvg} {...props} />

export default EmailIcon

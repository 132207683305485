export const ROUTES = {
    HOME: '/home',
    WORKLOGS: '/worklogs',
    EMPLOYEES: '/employees',
    // OKSYSTEM: '/oksystem',
    LOGIN: '/login',
    PASSWORD_LINK: '/password-reset-link',
    PASSWORD_RESET: '/password-reset',
    KNOWLEDGE: '//files.gorrion.io/d/s/538399914179181140/xOdJx40zrFG6Hq8cFe_4c_8F6iz33ZmA-1DvAmjK.fAc_',
    TOUR: 'https://files.gorrion.io/#file_id=515758459679936560',
    PARABANK: 'https://pb.gorrion.pl/',
    SWAGSHOP: 'https://swag.gorrion.io/',
    SWAGSCORE: 'https://swagscore.gorrion.pl/',
    GITLAB: 'https://git.gorrion.pl/',
    BOX: 'https://box.gorrion.io/',
    POKER: 'https://poker.gorrion.io/',
    VAULT: 'https://vault.gorrion.pl/',
}

import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'
import theme from '../../../Common/theme'

export const CommentInput = styled(TextareaAutosize)`
    width: 328px;
    min-height: 48px;
    border-radius: 8px;
    font-family: 'SofiaProRegular';
    color: ${props => props.color};
    border: ${props => (props.error ? `${theme.borders.thin} solid ${theme.colors.error}` : 'none')};
    float: right;
    font-size: ${theme.fontSizes.medium};
    margin-right: 24px;
    background-color: ${theme.colors.background};
    line-height: 1.71;
    padding: 16px;
    padding-top: 15px;
    text-align: start;
    resize: none;
    overflow: hidden;
    margin-left: 48px;

    ::-webkit-input-placeholder {
        color: ${props => (props.error ? theme.colors.error : theme.colors.inactive)};
    }

    &:hover {
        color: ${theme.colors.inactive};
        border: ${props =>
            props.error
                ? `solid ${theme.borders.thin} ${theme.colors.error}`
                : `solid ${theme.borders.thin} ${theme.colors.border}`};
        box-shadow: 0 1px 2px 0 rgba(33, 36, 41, 0.08);
    }

    &:focus {
        box-shadow: 0 1px 2px 0 rgba(33, 36, 41, 0.08);
        border: ${props =>
            props.error
                ? `solid ${theme.borders.thin} ${theme.colors.error}`
                : `solid ${theme.borders.thin} ${theme.colors.border}`};
        background-color: ${theme.colors.white};
        min-height: 128px;
        color: ${theme.colors.primary};
        outline: none;
    }
    @media (max-width: 599px) {
        width: unset;
    }
`

export const StyledAlert = styled.div`
    width: 100%;
    height: 16px;
    font-size: ${theme.fontSizes.mediumSmall};
    line-height: 1.67;
    margin-top: 3px;
    text-align: left;
    padding-left: 48px;
    color: ${theme.colors.white};
    z-index: -1;
    visibility: ${props => (props.show ? 'visible' : 'hidden')};
`

import React from 'react'
import styled from 'styled-components'
import Icon from '@ant-design/icons'

const SuperIcon = styled(Icon)`
    opacity: ${props => (props.collapsed ? '0' : '1')};
    transition: opacity ${props => (props.collapsed ? '0.1s' : '0.3s')} cubic-bezier(0.645, 0.045, 0.355, 1);
    height: ${props => (props.height ? props.height : '40px')};
    width: ${props => (props.width ? props.width : '160px')};
    background-size: contain;
    background-repeat: no-repeat;
    margin: ${props => (props.margin ? props.margin : '0 auto')};
    ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`};
`

const LogoSvg = () => (
    <svg width="162" height="40" viewBox="0 0 162 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M161.465 0H124.545V20.51H161.465V0Z" fill="#FEC707" />
        <path
            d="M18.8752 22.77L14.8752 34.26H11.9852L9.71516 27L7.53516 34.26H4.69516L0.535156 22.77H4.17516L6.22516 29.61L8.22516 22.77H11.1552L13.1552 29.61L15.1552 22.77H18.8752Z"
            fill="#212529"
        />
        <path
            d="M24.5349 18.51C24.5192 19.0511 24.2911 19.5643 23.9 19.9385C23.5089 20.3128 22.9862 20.5181 22.4449 20.51C21.8956 20.5262 21.3619 20.3255 20.9595 19.9511C20.5571 19.5768 20.3183 19.0591 20.2949 18.51C20.3039 18.2372 20.3668 17.9689 20.4799 17.7205C20.593 17.4722 20.7541 17.2486 20.9539 17.0627C21.1537 16.8769 21.3883 16.7323 21.6442 16.6375C21.9001 16.5426 22.1722 16.4993 22.4449 16.51C22.715 16.4978 22.9848 16.5405 23.238 16.6356C23.4911 16.7306 23.7223 16.876 23.9177 17.063C24.113 17.2499 24.2685 17.4745 24.3745 17.7232C24.4806 17.9719 24.5352 18.2396 24.5349 18.51ZM24.1349 22.77V34.26H20.6949V22.77H24.1349Z"
            fill="#212529"
        />
        <path
            d="M37.7952 32.17C36.7952 33.72 34.9952 34.51 32.5552 34.51C31.7671 34.5265 30.9835 34.3874 30.2491 34.1009C29.5147 33.8143 28.844 33.3859 28.2752 32.84C27.6984 32.2739 27.2457 31.5939 26.9462 30.8432C26.6466 30.0926 26.5066 29.2877 26.5352 28.48C26.5018 27.6809 26.6377 26.8838 26.9341 26.141C27.2306 25.3982 27.6808 24.7265 28.2552 24.17C29.4071 23.0748 30.9464 22.4814 32.5352 22.52C33.3131 22.499 34.087 22.6392 34.8081 22.9317C35.5292 23.2243 36.1819 23.6629 36.7252 24.22C37.2926 24.7907 37.7357 25.4728 38.0265 26.2231C38.3174 26.9735 38.4497 27.776 38.4152 28.58C38.4152 28.9 38.4152 29.24 38.4152 29.64H29.9752C30.1452 30.89 31.4452 31.56 32.8052 31.56C33.2801 31.5904 33.7553 31.5074 34.1918 31.3179C34.6282 31.1283 35.0133 30.8377 35.3152 30.47L37.7952 32.17ZM34.9952 27.5C34.6452 24.89 30.5652 24.86 29.9952 27.5H34.9952Z"
            fill="#212529"
        />
        <path
            d="M40.7751 22.77H44.1751V24.2C44.5357 23.647 45.037 23.1999 45.6275 22.9047C46.218 22.6095 46.8764 22.4767 47.5351 22.52C48.2679 22.5128 48.994 22.6585 49.6673 22.9478C50.3405 23.237 50.946 23.6635 51.4451 24.2C51.9987 24.7623 52.4307 25.4324 52.7146 26.1686C52.9984 26.9049 53.1279 27.6916 53.0951 28.48C53.1319 29.2808 53.0044 30.0807 52.7207 30.8305C52.4369 31.5803 52.0029 32.2641 51.4451 32.84C50.9444 33.3737 50.3383 33.7976 49.6653 34.0851C48.9923 34.3725 48.2669 34.5172 47.5351 34.51C46.8788 34.5491 46.2235 34.4177 45.6329 34.1286C45.0424 33.8395 44.5367 33.4024 44.1651 32.86V39.86H40.7651L40.7751 22.77ZM46.9051 31.29C47.6371 31.29 48.3391 30.9992 48.8568 30.4816C49.3744 29.964 49.6651 29.262 49.6651 28.53C49.6651 27.798 49.3744 27.096 48.8568 26.5784C48.3391 26.0608 47.6371 25.77 46.9051 25.77C46.5463 25.7724 46.1916 25.8469 45.8621 25.989C45.5326 26.131 45.235 26.3378 44.9869 26.5971C44.7388 26.8564 44.5453 27.1628 44.4179 27.4983C44.2905 27.8338 44.2317 28.1914 44.2451 28.55C44.2242 28.9085 44.278 29.2674 44.4032 29.604C44.5284 29.9406 44.7223 30.2475 44.9724 30.5051C45.2225 30.7628 45.5235 30.9656 45.8563 31.1007C46.189 31.2358 46.5462 31.3003 46.9051 31.29Z"
            fill="#212529"
        />
        <path
            d="M59.0152 34.26H55.5952V22.77H59.0152V25.55C59.5852 23.55 60.9552 22.55 62.2652 22.55C62.9553 22.5348 63.6396 22.6785 64.2652 22.97L63.6152 26C63.0679 25.7571 62.4739 25.6376 61.8752 25.65C59.9752 25.65 59.0152 27.18 59.0152 30.23V34.26Z"
            fill="#212529"
        />
        <path
            d="M65.7851 22.77H75.7851V25.33L70.2651 31.11H75.7851V34.26H65.4351V31.73L70.8551 25.92H65.7851V22.77Z"
            fill="#212529"
        />
        <path
            d="M85.965 22.77H89.385V34.26H85.965V32.69C85.6694 33.2306 85.2364 33.6836 84.7096 34.0031C84.1828 34.3227 83.581 34.4975 82.965 34.51C80.285 34.51 78.415 32.79 78.415 29.64V22.77H81.835V28.9C81.7931 29.1859 81.8137 29.4775 81.8952 29.7547C81.9768 30.0319 82.1174 30.2881 82.3075 30.5057C82.4976 30.7234 82.7326 30.8972 82.9963 31.0154C83.26 31.1335 83.5461 31.1931 83.835 31.19C85.015 31.19 85.905 30.45 85.905 28.85L85.965 22.77Z"
            fill="#212529"
        />
        <path
            d="M94.9454 35.08C95.2095 35.6284 95.6222 36.0917 96.1367 36.417C96.6512 36.7423 97.2467 36.9166 97.8554 36.92C100.145 36.92 101.205 35.57 101.025 32.84C100.644 33.3903 100.127 33.833 99.5241 34.1255C98.9216 34.418 98.2539 34.5504 97.5854 34.51C96.0837 34.504 94.6455 33.9035 93.5854 32.84C93.0216 32.2807 92.5796 31.6109 92.287 30.8727C91.9944 30.1344 91.8577 29.3436 91.8854 28.55C91.8492 27.7537 91.977 26.9585 92.2609 26.2137C92.5447 25.4689 92.9785 24.7903 93.5354 24.22C94.0548 23.6839 94.6764 23.2573 95.3634 22.9653C96.0504 22.6733 96.7889 22.5219 97.5354 22.52C98.2035 22.4901 98.8685 22.6282 99.4694 22.9217C100.07 23.2152 100.588 23.6547 100.975 24.2V22.77H104.305V32.66C104.305 37.51 101.965 40 97.8054 40C96.6164 40.0225 95.4469 39.6965 94.4411 39.062C93.4353 38.4276 92.6372 37.5127 92.1454 36.43L94.9454 35.08ZM98.1754 25.77C97.815 25.762 97.4566 25.8262 97.1214 25.9589C96.7862 26.0917 96.4809 26.2902 96.2237 26.5428C95.9665 26.7953 95.7624 27.0969 95.6236 27.4296C95.4847 27.7623 95.414 28.1195 95.4154 28.48C95.4046 28.8477 95.4682 29.2138 95.6024 29.5563C95.7367 29.8989 95.9388 30.2107 96.1966 30.4732C96.4543 30.7356 96.7625 30.9433 97.1026 31.0836C97.4426 31.224 97.8075 31.2942 98.1754 31.29C98.9007 31.2918 99.5977 31.008 100.115 30.5C100.512 30.1177 100.785 29.625 100.898 29.0859C101.012 28.5468 100.961 27.986 100.751 27.4763C100.542 26.9665 100.185 26.5313 99.7257 26.2271C99.2664 25.9229 98.7263 25.7636 98.1754 25.77Z"
            fill="#212529"
        />
        <path
            d="M116.015 34.26V33C115.645 33.506 115.128 33.8864 114.535 34.09C113.959 34.3378 113.342 34.4736 112.715 34.49C111.188 34.5 109.718 33.9071 108.625 32.84C108.039 32.2838 107.578 31.6091 107.272 30.8607C106.967 30.1124 106.825 29.3076 106.855 28.5C106.825 27.6983 106.967 26.8995 107.273 26.1576C107.578 25.4158 108.039 24.7482 108.625 24.2C109.712 23.1201 111.183 22.5158 112.715 22.52C113.352 22.5031 113.984 22.6322 114.563 22.8974C115.142 23.1626 115.652 23.5569 116.055 24.05V22.77H119.455V34.26H116.015ZM116.015 28.5C116.018 28.1365 115.947 27.7761 115.808 27.4403C115.669 27.1046 115.463 26.8003 115.204 26.5456C114.945 26.2908 114.637 26.0909 114.298 25.9577C113.96 25.8244 113.598 25.7606 113.235 25.77C112.494 25.7772 111.781 26.0586 111.235 26.56C110.961 26.8027 110.742 27.1016 110.594 27.4363C110.445 27.7711 110.371 28.1339 110.375 28.5C110.364 28.8759 110.435 29.2497 110.584 29.5952C110.732 29.9407 110.955 30.2495 111.235 30.5C111.784 30.9942 112.496 31.2684 113.235 31.27C113.602 31.2796 113.967 31.2146 114.308 31.0789C114.649 30.9432 114.959 30.7397 115.219 30.4807C115.478 30.2216 115.683 29.9125 115.82 29.5721C115.957 29.2316 116.023 28.8669 116.015 28.5Z"
            fill="#212529"
        />
        <path
            d="M140.125 9.61004C140.514 10.0034 140.816 10.4731 141.014 10.9895C141.212 11.5059 141.301 12.0577 141.275 12.61C141.292 13.1425 141.192 13.6722 140.981 14.1614C140.77 14.6505 140.454 15.0872 140.055 15.44C139.168 16.1852 138.032 16.5675 136.875 16.51C136.324 16.5268 135.775 16.4338 135.259 16.2363C134.744 16.0389 134.274 15.741 133.875 15.36C133.06 14.5482 132.582 13.4591 132.535 12.31C132.245 8.58004 132.885 3.83004 137.435 4.00004C138.22 3.96896 138.996 4.17574 139.662 4.59328C140.327 5.01083 140.851 5.61967 141.165 6.34004L138.745 7.34004C138.643 7.04745 138.453 6.7939 138.2 6.61467C137.947 6.43544 137.645 6.33944 137.335 6.34004C135.625 6.34004 134.995 8.16004 135.125 9.83004C135.291 9.45441 135.566 9.13738 135.915 8.92004C136.403 8.62912 136.969 8.49638 137.535 8.54004C138.016 8.53619 138.494 8.6289 138.938 8.81268C139.383 8.99647 139.787 9.2676 140.125 9.61004ZM138.125 11.37C137.982 11.2162 137.808 11.0935 137.616 11.0096C137.423 10.9257 137.215 10.8824 137.005 10.8824C136.795 10.8824 136.587 10.9257 136.394 11.0096C136.202 11.0935 136.028 11.2162 135.885 11.37C135.739 11.5171 135.623 11.6916 135.544 11.8835C135.465 12.0754 135.424 12.281 135.425 12.4886C135.425 12.6961 135.466 12.9015 135.546 13.0931C135.626 13.2847 135.743 13.4586 135.89 13.605C136.037 13.7515 136.212 13.8675 136.403 13.9465C136.595 14.0255 136.801 14.0659 137.009 14.0654C137.216 14.065 137.421 14.0236 137.613 13.9438C137.805 13.8639 137.979 13.7471 138.125 13.6C138.278 13.4571 138.4 13.284 138.483 13.0918C138.566 12.8995 138.608 12.6922 138.607 12.4829C138.606 12.2735 138.562 12.0666 138.477 11.8751C138.393 11.6836 138.269 11.5116 138.115 11.37H138.125Z"
            fill="white"
        />
        <path
            d="M147.755 10.1L153.535 16.33H149.965L146.415 12.33V16.33H143.635V4H146.405V7.81L149.195 4H152.415L147.755 10.1Z"
            fill="white"
        />
    </svg>
)

const LogoIcon = props => <SuperIcon component={LogoSvg} {...props} />

export default LogoIcon

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: ${props => (props.menuCollapsed ? '24px' : '16px')};
    height: ${props => (props.menuCollapsed ? '24px' : '16px')};

    transform: rotate(${props => (!props.collapsed ? '0deg' : '180deg')});
    color: ${props =>
        props.collapsible && !props.collapsed ? theme.colors.inactive : theme.colors.primary} !important;
    ${props => props.baseColor && `color: ${props.baseColor} !important;`}
    ${props => props.highlight && `color: ${theme.colors.blue} !important;`}
    ${props => props.color && `color: ${props.color};`}
`

const ChevronDownSvg = props => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={props.menuCollapsed ? '0 0 24 24' : '0 0 18 18'}>
        <g id="Layer_2" data-name="Layer 2" transform="translate(0 18) rotate(-90)">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect id="Rectangle_377" data-name="Rectangle 377" />
                <path
                    id="Path_176"
                    data-name="Path 176"
                    d="M13.249,15.5a.75.75,0,0,1-.533-.218l-4.5-4.5a.75.75,0,0,1,0-1.065l4.5-4.5a.753.753,0,0,1,1.065,1.065L9.806,10.249l3.975,3.968a.75.75,0,0,1-.533,1.282Z"
                    transform="translate(-1.999 -1.249)"
                    fill="currentColor"
                />
            </g>
        </g>
    </svg>
)

const ChevronDownIcon = props => <SuperIcon component={ChevronDownSvg} {...props} />

export default ChevronDownIcon

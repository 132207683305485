import styled from 'styled-components'
import React from 'react'
import Icon from '@ant-design/icons'

const SuperIcon = styled(Icon)`
    height: ${props => (props.height ? props.height : '56px')};
    width: ${props => (props.width ? props.width : '120px')};
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: 16px;
`

const DashboardGorrionSVG = () => (
    <svg width="121" height="56" viewBox="0 0 121 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M67.2846 6.53259C67.2846 6.53259 70.1971 4.52948 70.6298 3.19942C71.0624 1.86935 70.8581 1 70.8581 1"
            stroke="black"
            stroke-width="1.20187"
            stroke-miterlimit="10"
            stroke-linecap="round"
        />
        <path
            d="M70.0365 6.30857C70.0365 6.30857 71.002 5.09469 72.8449 3.93288C73.7983 3.33195 74.9481 3.13965 74.9481 3.13965"
            stroke="black"
            stroke-width="1.20187"
            stroke-miterlimit="10"
            stroke-linecap="round"
        />
        <path
            d="M17.1576 38.2574C16.7333 44.5876 16.8901 51.5093 17.062 58.4006C17.2944 67.647 20.884 75.9198 25.0264 76.1922C28.3596 76.4126 47.1367 77.294 60.1449 77.7066C75.7612 78.2034 88.4168 77.8628 92.8237 77.5343C97.2305 77.2018 99.6543 68.4282 100.852 55.9608C101.854 45.5286 102.33 26.7475 95.2955 18.2222C86.8264 7.95427 72.7605 6.48398 59.2636 6.37181C43.3669 6.2356 34.469 7.32529 25.0664 15.7103C23.1221 17.4434 21.6252 19.6533 20.4776 22.2325M20.4776 22.2325C20.3604 22.4957 20.2469 22.7629 20.1369 23.0337L20.4776 22.2325Z"
            stroke="black"
            stroke-width="1.20187"
            stroke-miterlimit="10"
        />
        <path
            d="M48.3157 25.0361L96.5774 27.4403"
            stroke="black"
            stroke-width="2.00311"
            stroke-miterlimit="10"
            stroke-linecap="round"
        />
        <path
            d="M1.02475 21.2305L36.0834 24.2353"
            stroke="black"
            stroke-width="2.00311"
            stroke-miterlimit="10"
            stroke-linecap="round"
        />
        <path
            d="M94.9749 27.6415L89.5668 46.2726L48.7037 41.8658"
            stroke="black"
            stroke-width="1.20187"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M27.4619 38.8585L1.22432 36.0544L2.02616 22.0326"
            stroke="black"
            stroke-width="1.20187"
            stroke-miterlimit="10"
            stroke-linecap="round"
        />
        <path
            d="M40.0893 33.2495C37.2853 32.2478 34.8903 32.5934 32.6777 34.6514C32.8784 42.664 38.6874 46.2696 38.6874 46.2696C38.6874 46.2696 41.8924 42.4637 45.2967 37.4558"
            stroke="black"
            stroke-width="1.20187"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M56.5155 15.8232L120.415 19.0282"
            stroke="black"
            stroke-width="0.400622"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.1978 28.9035C17.6894 29.1804 19.8507 27.6104 20.104 25.9418C20.4946 23.3684 18.7597 23.1869 16.5311 22.9538C16.3451 22.9343 16.1557 22.9145 15.9638 22.8929C15.7342 22.867 15.5078 22.8388 15.2858 22.8112C13.8831 22.6364 12.655 22.4834 11.8882 23.0863C12 23.052 12.1187 23.0335 12.2418 23.0335C12.9055 23.0335 13.4436 23.5716 13.4436 24.2354C13.4436 24.8991 12.9055 25.4372 12.2418 25.4372C11.7284 25.4372 11.2902 25.1154 11.118 24.6625C11.105 24.7496 11.094 24.8403 11.0849 24.9347C10.9222 26.6183 12.7062 28.6266 15.1978 28.9035Z"
            fill="black"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M67.2465 32.9704C70.4366 33.2884 73.2015 31.5071 73.5235 29.611C74.0247 26.6823 71.7889 26.4743 68.931 26.2084C68.6975 26.1866 68.4598 26.1645 68.2191 26.1405C67.9251 26.1108 67.6353 26.0785 67.3511 26.0468C65.0684 25.792 63.1469 25.5775 62.3421 27.1171C62.797 27.2883 63.1207 27.7275 63.1207 28.2423C63.1207 28.8535 62.6645 29.3581 62.074 29.4342C62.5595 31.0766 64.5896 32.7056 67.2465 32.9704Z"
            fill="black"
        />
    </svg>
)

const DashboardGorrionIcon = props => <SuperIcon component={DashboardGorrionSVG} {...props} />

export default DashboardGorrionIcon

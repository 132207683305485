import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    padding-right: 2px;

    .color-button {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const ColorIconSvg = () => (
    <svg id="color_lens-24px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="Path_358" data-name="Path 358" d="M0,0H24V24H0Z" fill="none" />
        <path
            id="Path_359"
            data-name="Path 359"
            className="color-button"
            d="M12,3a9,9,0,0,0,0,18,1.5,1.5,0,0,0,1.11-2.51A1.494,1.494,0,0,1,14.23,16H16a5,5,0,0,0,5-5C21,6.58,16.97,3,12,3ZM6.5,12A1.5,1.5,0,1,1,8,10.5,1.5,1.5,0,0,1,6.5,12Zm3-4A1.5,1.5,0,1,1,11,6.5,1.5,1.5,0,0,1,9.5,8Zm5,0A1.5,1.5,0,1,1,16,6.5,1.5,1.5,0,0,1,14.5,8Zm3,4A1.5,1.5,0,1,1,19,10.5,1.5,1.5,0,0,1,17.5,12Z"
        />
    </svg>
)

const ColorIcon = props => <SuperIcon component={ColorIconSvg} {...props} />

export default ColorIcon

import theme from '../Common/theme'
import { ButtonBlank } from '../Common/components/buttons'
import styled, { css } from 'styled-components'
import { Layout } from 'antd'

export const { Sider } = Layout

export const LogoIconsWrapper = styled.div`
    height: 40px;

    @media only screen and (max-width: 1199px) {
        margin-bottom: 15px;
    }
`

export const scrollableStyles = css`
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 16px, black 16px);
    mask-size: 100% 20000px;
    mask-position: left bottom;
    -webkit-mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 16px, black 16px);
    -webkit-mask-size: 100% 20000px;
    -webkit-mask-position: left bottom;
    transition: mask-position 0.3s, -webkit-mask-position 0.3s;

    &:hover {
        -webkit-mask-position: left top;
        mask-position: left top;
    }
    // -ms-overflow-style: none !important;
    // scrollbar-width: none !important;
    // &:-webkit-scrollbar {
    //     display: none !important;
    // }
`

export const SiderWrapper = styled(Sider)`
    && {
        width: ${props =>
            props.collapsed ? (props.scrollable ? '87px' : '70px') : props.scrollable ? '273px' : '256px'} !important;
        flex: 0 0
            ${props => (props.collapsed ? (props.scrollable ? '87px' : '70px') : props.scrollable ? '273px' : '256px')} !important;
        max-width: 304px !important;
        min-width: 70px !important;
        box-sizing: content-box;

        @media (min-width: 1199px) {
            padding-right: 16px;
        }
        & > div {
            z-index: 101;
            display: flex;
            flex-direction: column;
        }

        .ant-layout-sider-children {
            box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.16);
            background-color: ${theme.colors.white};
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            max-height: 100%;
            bottom: 0;
            height: 100vh;
            align-items: flex-start;
            justify-content: space-between;
            ${props => props.scrollable && { scrollableStyles }}
        }

        @media (max-width: 1199px) {
            width: ${props => (props.collapsed ? '0px' : '304px')} !important;
            flex: 0 0 ${props => (props.collapsed ? '0px' : '304px')} !important;
            min-width: 0px !important;
            position: absolute;
            z-index: 101;

            & > div {
                width: 100% !important;
                background-color: ${theme.colors.white} !important;

                .icon-external {
                    margin-left: 102px !important;
                }

                & > button,
                ul,
                div {
                    background-color: ${theme.colors.white} !important;
                    transition: opacity 0.1s ease-in 0.2s;
                    opacity: ${props => (props.collapsed ? '0' : '1')} !important;
                    visibility: ${props => (props.collapsed ? 'hidden' : 'visible')} !important;
                }
            }
        }
    }
`

export const SiderContentWrapper = styled.div`
    display: block;
    width: 100%;
`

export const CloseButtonMobile = styled(ButtonBlank)`
    && {
        background-color: transparent;
        margin-top: 16px;
        margin-left: 16px;
        padding: 0px;

        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
        }
    }
`

export const ToggleMenuButton = styled(ButtonBlank)`
    && {
        float: left;
        background-color: transparent;
        margin-top: 60px;
        margin-right: 24px;
        padding: 0px;
        margin-bottom: 16px;

        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
        }

        > span {
            color: black !important;
            margin-left: 24px !important;
        }
    }
`

export const PageWrapper = styled(Layout)`
    && {
        min-height: 100%;
        background-color: ${theme.colors.alabaster};
    }
`

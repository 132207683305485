import React, { useRef, useEffect, useState, useContext } from 'react'
import moment from 'moment'
import {
    Day,
    DayHeader,
    DayHeaderMobile,
    DayNrMobile,
    DayNameMobile,
    InputWrapper,
    TodayMarker,
    BlueDot,
    BlueLine,
} from '../Common/components/calendar.js'
import OKInputs from './OKInputs'
import styled from 'styled-components'
import Media from 'react-media'
import { OKSystemContext } from './contexts/OKSystemContext.js'
import theme from '../Common/theme'

const DayCellHeader = styled(DayHeader)`
    && {
        margin-bottom: 20px;
        text-transform: capitalize;
        color: ${props => (props.holiday ? theme.colors.white : theme.colors.primary)} !important;
    }
`

const DayCellHeaderMobile = styled(DayHeaderMobile)`
    && {
        text-transform: capitalize;
    }
`

const OKInputWrapper = styled(InputWrapper)`
    && {
        justify-content: flex-start !important;
        flex-direction: unset;
        padding-top: 0px;
        align-items: center;
        border: solid ${theme.borders.thin} ${props => (props.weekend ? theme.colors.border : theme.colors.white)};
        background-color: ${props => (props.weekend ? theme.colors.athensGray : theme.colors.white)} !important;
    }
`

const OKCalendar = ({ daysArray, isMonthClosed, bankHolidays, ...props }) => {
    const ref = useRef(null)
    const [pageLoaded, setPageLoaded] = useState(false)
    const { loading } = useContext(OKSystemContext)
    const currentMonthDays = daysArray.map(day => {
        //TODO: wycign do osobnego pliku
        const checkFirst = day.dayNo === 1
        let isWeekend = false

        const checkIfWeekend =
            moment(day.date).format('dddd') === 'sobota' || moment(day.date).format('dddd') === 'niedziela'
        if (checkIfWeekend) {
            isWeekend = true
        }

        let isBankHoliday = false
        const checkIfBankHoliday = bankHolidays?.includes(day.date)
        if (checkIfBankHoliday) {
            isBankHoliday = true
        }

        let isToday = false
        const checkIfToday = moment(day.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        if (checkIfToday) {
            isToday = true
        }
        //TODO: Do tego momentu
        const codes = props.codes.filter(el => el.date === day.date)
        return (
            <Media
                queries={{
                    small: '(max-width: 599px)',
                    medium: '(min-width: 600px) and (max-width: 1199px)',
                    large: '(min-width: 1200px)',
                }}>
                {matches => (
                    <>
                        {(matches.small || matches.medium) && (
                            <>
                                {checkIfToday && (
                                    <TodayMarker ref={ref}>
                                        <BlueDot />
                                        <BlueLine />
                                    </TodayMarker>
                                )}

                                <Day key={day.date} bgColor={isWeekend || isBankHoliday} today={isToday}>
                                    <DayCellHeaderMobile>
                                        <DayNrMobile holiday={isBankHoliday}>{day.dayNo}</DayNrMobile>
                                        <DayNameMobile holiday={isBankHoliday}>
                                            {moment(day.date).format('ddd')}
                                        </DayNameMobile>
                                    </DayCellHeaderMobile>
                                    <OKInputWrapper weekend={isWeekend || isBankHoliday}>
                                        <OKInputs isClosed={isMonthClosed} dateValue={day.date} codes={codes} />
                                    </OKInputWrapper>
                                </Day>
                            </>
                        )}
                        {matches.large && (
                            <>
                                {checkIfToday && <BlueLine ref={ref} />}
                                <Day key={day.date} bgColor={isWeekend || isBankHoliday} today={isToday}>
                                    <DayCellHeader holiday={isBankHoliday}>
                                        {checkFirst ? (
                                            <span>{moment(day.date).format('D MMM')}</span>
                                        ) : (
                                            <span>{day.dayNo}</span>
                                        )}
                                    </DayCellHeader>
                                    <div>
                                        <OKInputs isClosed={isMonthClosed} dateValue={day.date} codes={codes} />
                                    </div>
                                </Day>
                            </>
                        )}
                    </>
                )}
            </Media>
        )
    })

    useEffect(() => {
        if (!ref.current || loading) return
        if (pageLoaded === false) {
            ref.current.scrollIntoView({ behavior: 'auto', block: 'center' })
            setPageLoaded(true)
        }
    }, [ref, pageLoaded, loading])

    return <>{currentMonthDays}</>
}

export default OKCalendar

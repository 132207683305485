import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    padding-right: 2px;

    .cls-1 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
    .cls-2 {
        fill: none;
    }
`

const CalendarArrowSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" width="24" height="24" data-name="Layer 2" viewBox="0 0 24 24">
        <g id="Layer_2-2" data-name="Layer 2">
            <path
                id="Path_213"
                d="M18 3h-1V2a1 1 0 1 0-2 0v1H9V2a1 1 0 1 0-2 0v1H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zm1 15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1v1a1 1 0 0 0 2 0V5h6v1a1 1 0 0 0 2 0V5h1a1 1 0 0 1 1 1z"
                class="cls-1"
                data-name="Path 213"
            />
            <path
                id="Path_214"
                d="M17 13a1 1 0 0 1-1 1h-5.59l1.3 1.29a1 1 0 1 1-1.42 1.42l-3-3a1 1 0 0 1-.21-.32.37.37 0 0 1 0-.14.8.8 0 0 1 0-.5.37.37 0 0 1 0-.14 1 1 0 0 1 .21-.32l3-3a1 1 0 0 1 1.42 1.42L10.41 12H16a1 1 0 0 1 1 1z"
                class="cls-1"
                data-name="Path 214"
            />
            <path id="Rectangle_436" d="M0 0h24v24H0z" class="cls-2" data-name="Rectangle 436" />
        </g>
    </svg>
)

const CalendarIconArrow = props => <SuperIcon component={CalendarArrowSvg} {...props} />

export default CalendarIconArrow

import React from 'react'
import Icon from '@ant-design/icons'

const PlanningPokerSvg = () => (
    <svg width="24" height="24" viewBox="0 0 333 289" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_2319_4456)">
            <rect
                x="16.5744"
                y="38.201"
                width="149.174"
                height="230.097"
                rx="7.3797"
                transform="rotate(-10.3406 16.5744 38.201)"
                fill="white"
                stroke="#212529"
                stroke-width="1.69948"
            />
            <rect
                x="47.9903"
                y="59.9218"
                width="95.1605"
                height="176.083"
                rx="5.06143"
                transform="rotate(-10.3406 47.9903 59.9218)"
                fill="#212529"
                stroke="#212529"
                stroke-width="0.849741"
            />
            <path
                d="M94.3211 160.836L95.4154 160.637L96.2084 152.198L93.8526 144.452L89.8739 147.44L87.4077 148.903L84.9661 149.985L83.2023 150.778L81.9486 151.784L81.4168 153.13L88.5813 162.567L90.2768 161.786L92.588 161.153L94.3211 160.836Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.42487"
            />
            <path
                d="M128.436 127.208L126.567 128.597L123.759 112.618L126.203 112.172L131.288 110.895L134.078 109.258L139.033 119.043L139.041 120.116L138.512 121.341L137.605 121.963L136.319 122.573L133.737 123.877L130.595 125.498L128.436 127.208Z"
                fill="white"
            />
            <rect
                x="133.412"
                y="104.248"
                width="4.64019"
                height="13.7359"
                rx="0.571564"
                transform="rotate(-11.5486 133.412 104.248)"
                fill="white"
                stroke="#212529"
                stroke-width="0.381043"
                stroke-linejoin="round"
            />
            <rect
                x="132.104"
                y="101.557"
                width="6.23008"
                height="1.28072"
                rx="0.571564"
                transform="rotate(-11.5486 132.104 101.557)"
                fill="white"
                stroke="#212529"
                stroke-width="0.381043"
                stroke-linejoin="round"
            />
            <rect
                x="133.717"
                y="105.742"
                width="4.64019"
                height="1.54614"
                transform="rotate(-11.5486 133.717 105.742)"
                fill="#212529"
                stroke="#212529"
                stroke-width="0.381043"
            />
            <rect
                x="133.81"
                y="102.672"
                width="3.40912"
                height="1.28922"
                transform="rotate(-11.5486 133.81 102.672)"
                fill="#212529"
                stroke="#212529"
                stroke-width="0.279949"
            />
            <g filter="url(#filter1_d_2319_4456)">
                <path
                    d="M120.9 40.1478C120.696 39.1514 121.339 38.1787 122.335 37.9751V37.9751C123.332 37.7715 124.304 38.4142 124.508 39.4105L136.963 100.362L133.355 101.1L120.9 40.1478Z"
                    fill="white"
                />
            </g>
            <g filter="url(#filter2_d_2319_4456)">
                <path
                    d="M120.9 40.1478C120.696 39.1514 121.339 38.1787 122.335 37.9751V37.9751C123.332 37.7715 124.304 38.4142 124.508 39.4105L136.963 100.362L133.355 101.1L120.9 40.1478Z"
                    fill="white"
                />
            </g>
            <path
                d="M138.494 114.364C138.494 114.364 140.036 114.181 140.821 113.29C140.821 113.29 141.067 112.428 140.739 111.246C140.419 110.085 139.865 109.549 139.865 109.549C138.072 111.45 134.804 112.524 134.804 112.524L134.391 114.883L138.494 114.364Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M140.465 113.738C140.465 113.738 141.357 113.842 141.903 115.534C142.252 116.612 141.883 117.413 141.883 117.413C141.883 117.413 140.789 116.957 138.178 116.979C136.069 116.996 134.806 117.227 134.806 117.227C133.289 117.233 132.207 115.191 132.207 115.191C132.207 115.191 136.275 114.632 138.026 114.45C139.616 114.286 140.465 113.738 140.465 113.738Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M141.633 117.772C141.633 117.772 141.9 118.431 141.429 119.048C140.665 120.045 139.844 120.312 139.844 120.312C137.723 118.697 135.182 118.749 135.182 118.749C135.182 118.749 133.558 118.026 134.021 117.373L134.808 117.229C134.809 117.227 140.175 116.362 141.633 117.772Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M127.034 128.346C134.312 121.845 138.513 123.358 139.018 119.885"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M122.424 112.433C122.424 112.433 125.003 113.026 131.62 110.653"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M131.732 109.939C131.732 109.939 132.399 109.865 132.957 109.665C133.615 109.43 134.19 109.083 134.19 109.083C135.201 111.514 135.788 114.554 136.036 116.942C136.036 116.942 135.134 117.057 134.45 116.386C132.253 114.232 131.272 110.324 131.732 109.939Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M115.869 134.212C115.869 134.212 116.376 135.13 117.419 135.126C118.461 135.12 121.052 134.672 121.967 134.306C122.72 134.004 122.841 132.818 122.841 132.818"
                fill="white"
            />
            <path
                d="M115.869 134.212C115.869 134.212 116.376 135.13 117.419 135.126C118.461 135.12 121.052 134.672 121.967 134.306C122.72 134.004 122.841 132.818 122.841 132.818"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M97.2031 137.027C97.2031 137.027 97.907 138.189 98.9497 138.182C99.9924 138.176 102.593 137.779 103.508 137.413C104.26 137.111 104.347 135.983 104.347 135.983"
                fill="white"
            />
            <path
                d="M97.2031 137.027C97.2031 137.027 97.907 138.189 98.9497 138.182C99.9924 138.176 102.593 137.779 103.508 137.413C104.26 137.111 104.347 135.983 104.347 135.983"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M109.715 135.088C103.594 136.005 94.7345 137.196 93.1563 137.377C91.195 137.603 88.8095 134.039 87.9115 129.733C86.229 121.658 84.575 113.485 88.0121 109.078C91.6952 104.352 95.765 103.083 103.214 101.79C109.538 100.69 116.244 100.177 121.082 104.258C125.101 107.646 126.482 116.473 126.904 121.439C127.407 127.374 127.022 131.686 124.989 132.218C122.955 132.747 117.064 133.987 109.715 135.088Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
            />
            <path
                d="M106.98 101.182C106.98 101.182 108.172 99.9964 108.261 99.3372C108.349 98.678 108.18 98.2887 108.18 98.2887"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M108.247 100.84C108.247 100.84 108.595 100.19 109.358 99.4888C109.753 99.1263 110.274 98.9382 110.274 98.9382"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M108.285 110.074L127.992 107.143"
                stroke="#212529"
                stroke-width="0.952606"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M83.0391 112.744L104.281 110.805"
                stroke="#212529"
                stroke-width="0.952606"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M127.302 107.246L128.299 115.395L110.543 117.735"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M102.367 118.714L83.9818 120.963L83.0398 112.743"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M95.6979 112.8C95.7175 113.714 94.586 114.794 93.0676 114.932C91.5492 115.069 90.2764 114.207 90.201 113.295C90.0712 111.735 91.3905 111.79 92.9093 111.655C94.428 111.519 95.665 111.272 95.6979 112.8Z"
                fill="#212529"
            />
            <path
                d="M120.811 109.505C120.831 110.419 119.699 111.499 118.181 111.637C116.662 111.774 115.39 110.912 115.314 110C115.184 108.44 116.504 108.495 118.023 108.36C119.539 108.222 120.78 107.977 120.811 109.505Z"
                fill="#212529"
            />
            <path
                d="M106.785 113.931C106.046 113.927 104.851 114.094 103.944 114.558C103.221 114.926 102.837 115.451 102.837 115.451C104.754 119.509 106.801 120.122 106.801 120.122C106.801 120.122 109.618 117.392 108.926 114.766"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect
                x="87.1111"
                y="168.719"
                width="4.64019"
                height="13.7359"
                rx="0.571564"
                transform="rotate(168.451 87.1111 168.719)"
                fill="white"
                stroke="#212529"
                stroke-width="0.381043"
                stroke-linejoin="round"
            />
            <rect
                x="88.4197"
                y="171.408"
                width="6.23008"
                height="1.28072"
                rx="0.571564"
                transform="rotate(168.451 88.4197 171.408)"
                fill="white"
                stroke="#212529"
                stroke-width="0.381043"
                stroke-linejoin="round"
            />
            <rect
                x="86.8064"
                y="167.226"
                width="4.64019"
                height="1.54614"
                transform="rotate(168.451 86.8064 167.226)"
                fill="#212529"
                stroke="#212529"
                stroke-width="0.381043"
            />
            <rect
                x="86.7137"
                y="170.295"
                width="3.40912"
                height="1.28922"
                transform="rotate(168.451 86.7137 170.295)"
                fill="#212529"
                stroke="#212529"
                stroke-width="0.279949"
            />
            <g filter="url(#filter3_d_2319_4456)">
                <path
                    d="M99.6236 232.819C99.8271 233.815 99.1845 234.788 98.1882 234.992V234.992C97.1918 235.195 96.2191 234.553 96.0155 233.556L83.5609 172.605L87.1689 171.867L99.6236 232.819Z"
                    fill="white"
                />
            </g>
            <g filter="url(#filter4_d_2319_4456)">
                <path
                    d="M99.6236 232.819C99.8271 233.815 99.1845 234.788 98.1882 234.992V234.992C97.1918 235.195 96.2191 234.553 96.0155 233.556L83.5609 172.605L87.1689 171.867L99.6236 232.819Z"
                    fill="white"
                />
            </g>
            <path
                d="M82.0338 158.601C82.0338 158.601 80.4912 158.784 79.7063 159.675C79.7063 159.675 79.4604 160.536 79.7881 161.719C80.1085 162.88 80.6628 163.416 80.6628 163.416C82.4552 161.514 85.7233 160.441 85.7233 160.441L86.1368 158.082L82.0338 158.601Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M80.0626 159.227C80.0626 159.227 79.1702 159.123 78.6239 157.431C78.2755 156.352 78.6444 155.551 78.6444 155.551C78.6444 155.551 79.7388 156.008 82.3496 155.986C84.4581 155.968 85.7217 155.738 85.7217 155.738C87.2381 155.732 88.3203 157.773 88.3203 157.773C88.3203 157.773 84.2523 158.333 82.5009 158.515C80.9117 158.679 80.0626 159.227 80.0626 159.227Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M78.894 155.193C78.894 155.193 78.6273 154.534 79.0988 153.917C79.8625 152.92 80.6836 152.653 80.6836 152.653C82.8041 154.268 85.3456 154.216 85.3456 154.216C85.3456 154.216 86.9693 154.939 86.5062 155.592L85.7194 155.736C85.718 155.738 80.3519 156.603 78.894 155.193Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M93.4896 144.619C86.2114 151.119 82.0101 149.607 81.5053 153.08"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M98.1031 160.532C98.1031 160.532 95.5244 159.939 88.9073 162.312"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M88.7956 163.026C88.7956 163.026 88.1286 163.1 87.5708 163.3C86.9127 163.535 86.3369 163.881 86.3369 163.881C85.3267 161.451 84.7393 158.411 84.4912 156.023C84.4912 156.023 85.3936 155.908 86.0775 156.579C88.274 158.733 89.2558 162.641 88.7956 163.026Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.508357"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M104.658 138.755C104.658 138.755 104.152 137.836 103.109 137.841C102.066 137.847 99.4749 138.295 98.5599 138.661C97.8074 138.963 97.6868 140.149 97.6868 140.149"
                fill="white"
            />
            <path
                d="M104.658 138.755C104.658 138.755 104.152 137.836 103.109 137.841C102.066 137.847 99.4749 138.295 98.5599 138.661C97.8074 138.963 97.6868 140.149 97.6868 140.149"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M123.324 135.939C123.324 135.939 122.62 134.778 121.578 134.784C120.535 134.791 117.935 135.188 117.02 135.554C116.267 135.856 116.181 136.983 116.181 136.983"
                fill="white"
            />
            <path
                d="M123.324 135.939C123.324 135.939 122.62 134.778 121.578 134.784C120.535 134.791 117.935 135.188 117.02 135.554C116.267 135.856 116.181 136.983 116.181 136.983"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M110.812 137.879C116.933 136.962 125.793 135.771 127.371 135.59C129.332 135.364 131.718 138.928 132.616 143.233C134.298 151.308 135.952 159.481 132.515 163.889C128.832 168.614 124.762 169.884 117.314 171.177C110.99 172.277 104.284 172.79 99.4449 168.709C95.426 165.321 94.0458 156.494 93.6238 151.527C93.12 145.593 93.505 141.281 95.5383 140.749C97.5719 140.219 103.464 138.98 110.812 137.879Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
            />
            <path
                d="M113.547 171.785C113.547 171.785 112.356 172.97 112.267 173.63C112.178 174.289 112.348 174.678 112.348 174.678"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M112.28 172.127C112.28 172.127 111.932 172.777 111.169 173.478C110.774 173.841 110.253 174.029 110.253 174.029"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M112.242 162.893L92.535 165.824"
                stroke="#212529"
                stroke-width="0.952606"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M137.488 160.223L116.246 162.162"
                stroke="#212529"
                stroke-width="0.952606"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M93.2252 165.721L92.2282 157.572L109.985 155.232"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M118.16 154.253L136.546 152.004L137.488 160.223"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M124.829 160.167C124.81 159.253 125.941 158.173 127.46 158.035C128.978 157.898 130.251 158.76 130.326 159.672C130.456 161.232 129.137 161.177 127.618 161.312C126.099 161.448 124.862 161.695 124.829 160.167Z"
                fill="#212529"
            />
            <path
                d="M99.7162 163.462C99.6965 162.548 100.828 161.468 102.346 161.33C103.865 161.193 105.138 162.055 105.213 162.966C105.343 164.527 104.024 164.471 102.505 164.607C100.988 164.744 99.7471 164.99 99.7162 163.462Z"
                fill="#212529"
            />
            <path
                d="M113.742 159.036C114.481 159.04 115.676 158.873 116.583 158.409C117.306 158.041 117.691 157.515 117.691 157.515C115.773 153.457 113.726 152.845 113.726 152.845C113.726 152.845 110.909 155.575 111.601 158.2"
                stroke="#212529"
                stroke-width="0.571564"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M39.9532 82.6733C44.8296 84.9672 48.7863 77.9034 43.8165 74.1566C43.8165 74.1566 36.6128 69.6632 36.4138 68.5721C36.6141 69.6704 31.4603 76.4112 31.4603 76.4112C28.1449 81.658 34.2918 86.8506 38.0471 83.0508C38.2137 84.7457 38.3132 86.3614 38.2148 87.2533L41.3254 86.6858C40.8951 86.0102 40.4011 84.4062 39.9532 82.6733Z"
                fill="#212529"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M181.246 193.311C176.37 191.017 172.413 198.081 177.383 201.828C177.383 201.828 184.586 206.321 184.785 207.412C184.585 206.314 189.739 199.573 189.739 199.573C193.054 194.326 186.907 189.134 183.152 192.934C182.986 191.239 182.886 189.623 182.984 188.731L179.874 189.299C180.304 189.974 180.798 191.578 181.246 193.311Z"
                fill="#212529"
            />
            <path
                d="M38.2419 42.4891L35.1386 43.0554L30.4531 52.2198L28.9858 44.178L26.4761 44.636L29.8687 63.2293L32.3783 62.7714L30.9307 54.8375L39.7455 61.4271L43.1188 60.8116L32.7845 53.0771L38.2419 42.4891Z"
                fill="#212529"
            />
            <path
                d="M182.961 233.495L186.065 232.929L190.75 223.765L192.217 231.806L194.727 231.348L191.334 212.755L188.825 213.213L190.272 221.147L181.458 214.557L178.084 215.173L188.419 222.907L182.961 233.495Z"
                fill="#212529"
            />
        </g>
        <g filter="url(#filter5_d_2319_4456)">
            <rect
                x="174.799"
                y="24.359"
                width="149.174"
                height="230.097"
                rx="7.3797"
                transform="rotate(10.4596 174.799 24.359)"
                fill="white"
                stroke="#212529"
                stroke-width="1.69948"
            />
            <rect
                x="196.454"
                y="55.8211"
                width="95.1605"
                height="176.083"
                rx="5.06143"
                transform="rotate(10.4596 196.454 55.8211)"
                fill="#212529"
                stroke="#212529"
                stroke-width="0.849741"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M184.56 67.1009C185.235 66.4978 185.722 65.6746 185.899 64.7159C186.323 62.4176 184.804 60.2105 182.506 59.7862C180.207 59.3619 178 60.8811 177.576 63.1794C177.401 64.1297 177.558 65.0644 177.964 65.864C177.787 65.8059 177.603 65.759 177.414 65.7241C175.116 65.2999 172.909 66.819 172.484 69.1173C172.06 71.4156 173.579 73.6226 175.878 74.0469C177.07 74.267 178.238 73.9641 179.145 73.3005C178.654 75.1654 178.103 77.0063 177.644 77.905L180.763 78.4809C180.583 77.6145 180.732 75.665 180.968 73.6855C181.58 74.6012 182.548 75.2782 183.717 75.494C186.015 75.9182 188.222 74.3991 188.646 72.1008C189.07 69.8025 187.551 67.5955 185.253 67.1712C185.02 67.1283 184.789 67.1052 184.56 67.1009Z"
                fill="#212529"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M269.96 234.971C269.285 235.574 268.798 236.398 268.621 237.356C268.196 239.655 269.716 241.862 272.014 242.286C274.312 242.71 276.519 241.191 276.943 238.893C277.119 237.943 276.962 237.008 276.555 236.208C276.733 236.266 276.917 236.313 277.106 236.348C279.404 236.772 281.611 235.253 282.035 232.955C282.459 230.657 280.94 228.45 278.642 228.025C277.45 227.805 276.282 228.108 275.375 228.772C275.866 226.907 276.416 225.066 276.876 224.167L273.757 223.591C273.936 224.458 273.787 226.407 273.552 228.387C272.939 227.471 271.972 226.794 270.803 226.578C268.505 226.154 266.298 227.673 265.873 229.971C265.449 232.27 266.968 234.477 269.266 234.901C269.499 234.944 269.731 234.967 269.96 234.971Z"
                fill="#212529"
            />
            <path
                d="M193.533 36.0616L190.431 35.4889L182.796 42.3922L184.28 34.3535L181.772 33.8904L178.341 52.4766L180.849 52.9397L182.313 45.0089L188.214 54.2993L191.586 54.9218L184.671 44.0216L193.533 36.0616Z"
                fill="#212529"
            />
            <path
                d="M260.986 266.011L264.089 266.583L271.723 259.68L270.239 267.719L272.748 268.182L276.179 249.596L273.67 249.133L272.206 257.063L266.306 247.773L262.934 247.151L269.848 258.051L260.986 266.011Z"
                fill="#212529"
            />
            <rect
                x="263.853"
                y="119.208"
                width="4.29015"
                height="12.6997"
                rx="0.528447"
                transform="rotate(9.25153 263.853 119.208)"
                fill="white"
                stroke="#212529"
                stroke-width="0.352298"
                stroke-linejoin="round"
            />
            <path
                d="M251.669 139.764L247.904 141.124L248.625 119.521L251.024 121.13L253.25 122.359L256.206 123.162L258.711 123.458L260.025 123.474L263.75 135.299L262.956 136.438L261.663 137.194L259.317 137.835L255.442 138.644L251.669 139.764Z"
                fill="white"
            />
            <path
                d="M203.138 180.67L205.588 182.567L208.631 160.337L200.345 163.053L196.509 164.026L193.64 164.557L191.777 165.54L190.724 166.938L194.152 178.392L198.591 178.946L201.167 179.687L203.138 180.67Z"
                fill="white"
            />
            <rect
                x="263.606"
                y="116.452"
                width="5.7601"
                height="1.1841"
                rx="0.528447"
                transform="rotate(9.25153 263.606 116.452)"
                fill="white"
                stroke="#212529"
                stroke-width="0.352298"
                stroke-linejoin="round"
            />
            <rect
                x="263.626"
                y="120.599"
                width="4.29015"
                height="1.4295"
                transform="rotate(9.25153 263.626 120.599)"
                fill="#212529"
                stroke="#212529"
                stroke-width="0.352298"
            />
            <rect
                x="264.716"
                y="117.975"
                width="3.15194"
                height="1.19197"
                transform="rotate(9.25153 264.716 117.975)"
                fill="#212529"
                stroke="#212529"
                stroke-width="0.258831"
            />
            <g filter="url(#filter6_d_2319_4456)">
                <path
                    d="M274.086 59.6959C274.237 58.7679 275.112 58.1382 276.04 58.2893V58.2893C276.968 58.4405 277.597 59.3153 277.446 60.2433L268.199 117.013L264.839 116.466L274.086 59.6959Z"
                    fill="white"
                />
            </g>
            <g filter="url(#filter7_d_2319_4456)">
                <path
                    d="M274.086 59.6959C274.237 58.7679 275.112 58.1382 276.04 58.2893V58.2893C276.968 58.4405 277.597 59.3153 277.446 60.2433L268.199 117.013L264.839 116.466L274.086 59.6959Z"
                    fill="white"
                />
            </g>
            <path
                d="M238.738 102.799C234.329 100.487 230.116 101.548 230.116 101.548C230.116 101.548 236.117 104.086 238.985 108.838"
                stroke="#212529"
                stroke-width="0.267885"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M236.509 99.8101C237.968 100.273 241.863 101.924 244.007 104.474C245.704 106.489 249.415 112.063 249.937 120.155C250.538 129.5 249.597 137.39 248.265 141.912C247.201 145.52 247.227 148.94 241.367 151.13C237.172 152.697 218.883 146.815 215.806 144.346C212.27 141.507 212.072 140.739 212.004 139.876C211.936 139.011 211.467 129.558 214.681 120.5C217.159 113.513 222.044 104.681 225.776 101.258C229.127 98.1824 233.862 98.9725 236.509 99.8101Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.528064"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M225.055 118.301C224.482 123.174 225.951 126.387 225.951 126.387C225.951 126.387 231.62 124.014 233.031 120.728"
                stroke="#212529"
                stroke-width="0.528064"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M229.214 118.031C230.328 118.172 231.276 118.771 231.735 119.572"
                stroke="#212529"
                stroke-width="0.528064"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M225.038 128.977C225.038 128.977 222.924 125.18 220.989 123.613C220.625 123.318 217.677 127.928 218.407 128.168C221.348 129.139 225.038 128.977 225.038 128.977Z"
                stroke="#212529"
                stroke-width="0.528064"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M221.825 128.632L224.344 127.799"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path
                d="M219.737 128.424L223.579 127.026"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path
                d="M218.417 127.838L223.027 126.089"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path
                d="M218.555 126.608L222.455 125.049"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path d="M219.763 124.936L221.658 124.23" stroke="#212529" stroke-width="0.176021" stroke-miterlimit="10" />
            <path
                d="M225.993 128.991C225.993 128.991 230.604 127.484 233.329 126.754C233.836 126.619 232.887 132.236 232.099 132.059C228.933 131.344 225.993 128.991 225.993 128.991Z"
                stroke="#212529"
                stroke-width="0.528064"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M227.697 129.94L229.842 127.933" stroke="#212529" stroke-width="0.176021" stroke-miterlimit="10" />
            <path d="M228.47 130.469L233.141 127.065" stroke="#212529" stroke-width="0.176021" stroke-miterlimit="10" />
            <path d="M229.573 131.17L233.33 128.673" stroke="#212529" stroke-width="0.176021" stroke-miterlimit="10" />
            <path
                d="M230.966 131.443L233.017 130.228"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path
                d="M237.864 100.381L229.262 97.8477"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M221.888 105.681L227.873 99.0027"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M236.721 114.108C235.692 114.134 235.18 115.49 235.176 116.402C235.168 117.987 236.183 118.872 237.129 119.047C238.348 119.272 238.987 118.746 240.019 117.473C240.754 116.565 240.097 115.558 239.651 115.2C239.285 114.906 238.347 114.066 236.721 114.108Z"
                stroke="#212529"
                stroke-width="0.352042"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M236.88 116.307C236.82 116.439 236.694 116.531 236.548 116.532C236.341 116.533 236.172 116.354 236.17 116.131C236.169 116.005 236.221 115.892 236.304 115.817C236.209 115.788 236.112 115.778 236.014 115.792C235.495 115.865 235.163 116.56 235.274 117.343C235.384 118.127 235.894 118.703 236.413 118.63C236.932 118.557 237.263 117.863 237.153 117.079C237.112 116.785 237.014 116.52 236.88 116.307Z"
                fill="#212529"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M225.005 113.821C224.945 113.953 224.819 114.044 224.673 114.045C224.466 114.047 224.297 113.868 224.295 113.644C224.294 113.518 224.346 113.406 224.429 113.331C224.334 113.301 224.237 113.292 224.139 113.306C223.62 113.379 223.288 114.073 223.399 114.857C223.509 115.641 224.019 116.217 224.538 116.144C225.057 116.071 225.388 115.376 225.278 114.593C225.237 114.298 225.139 114.033 225.005 113.821Z"
                fill="#212529"
            />
            <path
                d="M236.336 111.306C236.336 111.306 238.457 110.533 240.097 112.518"
                stroke="#212529"
                stroke-width="0.267885"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M225.464 108.264C225.464 108.264 228.983 107.418 230.054 111.067"
                stroke="#212529"
                stroke-width="0.267885"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M226.98 111.641C227.589 112.058 228.157 112.696 228.039 113.335C227.715 115.09 226.002 116.102 224.71 116.022C223.417 115.942 223.303 114.233 223.416 113.257C223.536 112.219 224.056 111.489 224.719 111.223C225.492 110.914 226.421 111.258 226.98 111.641Z"
                stroke="#212529"
                stroke-width="0.352042"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M244.141 111.256C247.758 112.47 249.136 115.626 249.556 117.204C250.427 120.472 244.627 127.309 243.559 133.094"
                stroke="#212529"
                stroke-width="0.267885"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M265.209 129.732C265.209 129.732 266.791 130.128 267.894 129.546C267.894 129.546 268.456 128.792 268.575 127.51C268.694 126.251 268.35 125.518 268.35 125.518C265.881 126.716 262.273 126.551 262.273 126.551L260.988 128.713L265.209 129.732Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M267.374 129.852C267.374 129.852 268.211 130.287 268.116 132.151C268.056 133.339 267.396 133.988 267.396 133.988C267.396 133.988 266.492 133.132 263.921 132.18C261.845 131.411 260.518 131.166 260.518 131.166C259.028 130.607 258.727 128.199 258.727 128.199C258.727 128.199 262.928 129.167 264.715 129.641C266.336 130.073 267.374 129.852 267.374 129.852Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M267.015 134.247C267.015 134.247 267.031 134.994 266.338 135.423C265.217 136.117 264.311 136.073 264.311 136.073C262.832 133.698 260.318 132.801 260.318 132.801C260.318 132.801 258.994 131.486 259.692 131.017L260.517 131.17C260.52 131.169 266.109 132.32 267.015 134.247Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M248.858 140.781C258.426 137.114 261.876 138.568 263.666 135.347"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M250.043 120.246C250.043 120.246 252.463 123.387 259.843 123.524"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M260.216 122.867C260.216 122.867 260.899 123.043 261.521 123.055C262.254 123.069 262.949 122.944 262.949 122.944C263.034 125.706 262.477 128.909 261.83 131.346C261.83 131.346 260.902 131.122 260.48 130.209C259.127 127.276 259.621 123.073 260.216 122.867Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M223.379 150.605C221.095 150.595 220.098 150.382 219.249 149.756C218.649 149.314 218.547 148.451 220.118 148.512C222.359 148.6 223.884 149.065 224.827 149.453C225.93 149.905 226.793 150.412 225.987 150.703"
                fill="white"
            />
            <path
                d="M223.379 150.605C221.095 150.595 220.098 150.382 219.249 149.756C218.649 149.314 218.547 148.451 220.118 148.512C222.359 148.6 223.884 149.065 224.827 149.453C225.93 149.905 226.793 150.412 225.987 150.703"
                stroke="#212529"
                stroke-width="0.352042"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M233.169 153.223C231.441 153.286 229.602 152.651 228.762 151.973C228.298 151.599 227.879 150.94 229.294 151.006C230.63 151.068 232.38 151.585 233.405 151.901C234.246 152.16 234.899 152.367 235.535 152.965C235.863 153.275 236.136 154.061 235.263 154.032"
                fill="white"
            />
            <path
                d="M233.169 153.223C231.441 153.286 229.602 152.651 228.762 151.973C228.298 151.599 227.879 150.94 229.294 151.006C230.63 151.068 232.38 151.585 233.405 151.901C234.246 152.16 234.899 152.367 235.535 152.965C235.863 153.275 236.136 154.061 235.263 154.032"
                stroke="#212529"
                stroke-width="0.352042"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect
                x="190.702"
                y="182.872"
                width="4.29015"
                height="12.6997"
                rx="0.528447"
                transform="rotate(-170.748 190.702 182.872)"
                fill="white"
                stroke="#212529"
                stroke-width="0.352298"
                stroke-linejoin="round"
            />
            <rect
                x="190.952"
                y="185.628"
                width="5.7601"
                height="1.1841"
                rx="0.528447"
                transform="rotate(-170.748 190.952 185.628)"
                fill="white"
                stroke="#212529"
                stroke-width="0.352298"
                stroke-linejoin="round"
            />
            <rect
                x="190.929"
                y="181.481"
                width="4.29015"
                height="1.4295"
                transform="rotate(-170.748 190.929 181.481)"
                fill="#212529"
                stroke="#212529"
                stroke-width="0.352298"
            />
            <rect
                x="189.842"
                y="184.105"
                width="3.15194"
                height="1.19197"
                transform="rotate(-170.748 189.842 184.105)"
                fill="#212529"
                stroke="#212529"
                stroke-width="0.258831"
            />
            <g filter="url(#filter8_d_2319_4456)">
                <path
                    d="M180.473 242.384C180.322 243.312 179.447 243.942 178.519 243.791V243.791C177.591 243.64 176.961 242.765 177.112 241.837L186.36 185.067L189.72 185.614L180.473 242.384Z"
                    fill="white"
                />
            </g>
            <g filter="url(#filter9_d_2319_4456)">
                <path
                    d="M180.473 242.384C180.322 243.312 179.447 243.942 178.519 243.791V243.791C177.591 243.64 176.961 242.765 177.112 241.837L186.36 185.067L189.72 185.614L180.473 242.384Z"
                    fill="white"
                />
            </g>
            <path
                d="M215.821 199.283C220.229 201.595 224.443 200.534 224.443 200.534C224.443 200.534 218.442 197.996 215.573 193.244"
                stroke="#212529"
                stroke-width="0.267885"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M218.05 202.272C216.59 201.809 212.696 200.158 210.552 197.608C208.854 195.593 205.144 190.02 204.622 181.927C204.02 172.582 204.961 164.692 206.294 160.17C207.357 156.562 207.332 153.142 213.192 150.952C217.387 149.385 235.675 155.267 238.753 157.736C242.288 160.575 242.487 161.343 242.555 162.206C242.622 163.071 243.091 172.524 239.878 181.582C237.4 188.569 232.515 197.401 228.783 200.824C225.432 203.9 220.696 203.11 218.05 202.272Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.528064"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M229.504 183.781C230.077 178.908 228.608 175.695 228.608 175.695C228.608 175.695 222.938 178.068 221.528 181.354"
                stroke="#212529"
                stroke-width="0.528064"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M225.344 184.051C224.231 183.91 223.283 183.311 222.823 182.51"
                stroke="#212529"
                stroke-width="0.528064"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M229.521 173.107C229.521 173.107 231.635 176.904 233.569 178.471C233.933 178.766 236.881 174.156 236.152 173.916C233.21 172.945 229.521 173.107 229.521 173.107Z"
                stroke="#212529"
                stroke-width="0.528064"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M232.734 173.454L230.215 174.287"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path d="M234.822 173.66L230.979 175.057" stroke="#212529" stroke-width="0.176021" stroke-miterlimit="10" />
            <path
                d="M236.146 174.246L231.536 175.995"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path
                d="M236.004 175.476L232.104 177.035"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path d="M234.796 177.146L232.9 177.852" stroke="#212529" stroke-width="0.176021" stroke-miterlimit="10" />
            <path
                d="M228.565 173.091C228.565 173.091 223.955 174.598 221.23 175.328C220.723 175.463 221.671 169.846 222.459 170.023C225.625 170.738 228.565 173.091 228.565 173.091Z"
                stroke="#212529"
                stroke-width="0.528064"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M226.858 172.142L224.713 174.149"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path
                d="M226.084 171.613L221.413 175.017"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path
                d="M224.982 170.912L221.225 173.409"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path
                d="M223.593 170.639L221.542 171.855"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
            />
            <path
                d="M216.695 201.701L225.297 204.234"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M232.671 196.402L226.686 203.079"
                stroke="#212529"
                stroke-width="0.176021"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M217.837 187.974C218.866 187.948 219.379 186.592 219.383 185.68C219.39 184.095 218.376 183.21 217.43 183.035C216.211 182.81 215.571 183.336 214.54 184.609C213.805 185.517 214.462 186.524 214.908 186.882C215.273 187.176 216.211 188.016 217.837 187.974Z"
                stroke="#212529"
                stroke-width="0.352042"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M217.678 185.775C217.739 185.643 217.865 185.552 218.01 185.551C218.217 185.549 218.386 185.729 218.388 185.952C218.389 186.077 218.337 186.19 218.255 186.265C218.349 186.294 218.447 186.304 218.545 186.29C219.064 186.217 219.395 185.522 219.285 184.739C219.175 183.955 218.665 183.379 218.146 183.452C217.627 183.525 217.295 184.219 217.406 185.003C217.447 185.297 217.545 185.562 217.678 185.775Z"
                fill="#212529"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M229.553 188.261C229.614 188.129 229.74 188.038 229.885 188.037C230.092 188.035 230.261 188.215 230.263 188.438C230.264 188.564 230.212 188.676 230.13 188.751C230.224 188.781 230.322 188.79 230.42 188.776C230.939 188.703 231.27 188.009 231.16 187.225C231.05 186.441 230.54 185.865 230.021 185.938C229.502 186.011 229.17 186.706 229.281 187.489C229.322 187.784 229.42 188.049 229.553 188.261Z"
                fill="#212529"
            />
            <path
                d="M218.223 190.777C218.223 190.777 216.101 191.549 214.462 189.564"
                stroke="#212529"
                stroke-width="0.267885"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M229.094 193.818C229.094 193.818 225.576 194.664 224.504 191.016"
                stroke="#212529"
                stroke-width="0.267885"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M227.579 190.441C226.97 190.024 226.401 189.386 226.519 188.747C226.843 186.992 228.556 185.98 229.849 186.06C231.141 186.14 231.256 187.849 231.143 188.825C231.023 189.863 230.503 190.593 229.839 190.859C229.067 191.168 228.138 190.824 227.579 190.441Z"
                stroke="#212529"
                stroke-width="0.352042"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M210.418 190.826C206.801 189.612 205.423 186.456 205.003 184.878C204.131 181.61 209.932 174.773 211 168.988"
                stroke="#212529"
                stroke-width="0.267885"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M189.35 172.35C189.35 172.35 187.767 171.954 186.665 172.536C186.665 172.536 186.102 173.29 185.983 174.572C185.865 175.831 186.209 176.564 186.209 176.564C188.677 175.366 192.285 175.531 192.285 175.531L193.571 173.37L189.35 172.35Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M187.181 172.23C187.181 172.23 186.344 171.795 186.438 169.931C186.498 168.743 187.159 168.094 187.159 168.094C187.159 168.094 188.063 168.95 190.634 169.902C192.71 170.671 194.036 170.916 194.036 170.916C195.527 171.475 195.828 173.883 195.828 173.883C195.828 173.883 191.626 172.915 189.84 172.441C188.218 172.009 187.181 172.23 187.181 172.23Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M187.544 167.835C187.544 167.835 187.528 167.088 188.221 166.659C189.342 165.965 190.248 166.009 190.248 166.009C191.727 168.384 194.241 169.281 194.241 169.281C194.241 169.281 195.565 170.596 194.867 171.065L194.041 170.912C194.039 170.914 188.449 169.762 187.544 167.835Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M205.7 161.301C196.132 164.968 192.683 163.514 190.892 166.735"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M204.516 181.836C204.516 181.836 202.096 178.695 194.716 178.558"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M194.342 179.216C194.342 179.216 193.66 179.039 193.038 179.027C192.304 179.013 191.61 179.138 191.61 179.138C191.525 176.376 192.081 173.173 192.729 170.736C192.729 170.736 193.657 170.96 194.078 171.874C195.431 174.806 194.938 179.009 194.342 179.216Z"
                fill="white"
                stroke="#212529"
                stroke-width="0.533771"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_2319_4456"
                x="0.359739"
                y="3.7048"
                width="206.888"
                height="271.974"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="-6.79793" dy="1.69948" />
                <feGaussianBlur stdDeviation="4.88601" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2319_4456" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2319_4456" result="shape" />
            </filter>
            <filter
                id="filter1_d_2319_4456"
                x="113.713"
                y="30.7868"
                width="30.399"
                height="77.4635"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3.57534" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.286275 0 0 0 0 0.313726 0 0 0 0 0.341176 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2319_4456" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2319_4456" result="shape" />
            </filter>
            <filter
                id="filter2_d_2319_4456"
                x="113.713"
                y="30.7868"
                width="30.399"
                height="77.4635"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3.57534" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.286275 0 0 0 0 0.313726 0 0 0 0 0.341176 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2319_4456" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2319_4456" result="shape" />
            </filter>
            <filter
                id="filter3_d_2319_4456"
                x="76.4118"
                y="164.717"
                width="30.399"
                height="77.4635"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3.57534" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.286275 0 0 0 0 0.313726 0 0 0 0 0.341176 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2319_4456" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2319_4456" result="shape" />
            </filter>
            <filter
                id="filter4_d_2319_4456"
                x="76.4118"
                y="164.717"
                width="30.399"
                height="77.4635"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3.57534" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.286275 0 0 0 0 0.313726 0 0 0 0 0.341176 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2319_4456" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2319_4456" result="shape" />
            </filter>
            <filter
                id="filter5_d_2319_4456"
                x="116.821"
                y="16.6521"
                width="207.282"
                height="272.167"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="-6.79793" dy="1.69948" />
                <feGaussianBlur stdDeviation="4.88601" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2319_4456" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2319_4456" result="shape" />
            </filter>
            <filter
                id="filter6_d_2319_4456"
                x="258.229"
                y="51.6563"
                width="25.8514"
                height="71.9686"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3.30563" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.286275 0 0 0 0 0.313726 0 0 0 0 0.341176 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2319_4456" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2319_4456" result="shape" />
            </filter>
            <filter
                id="filter7_d_2319_4456"
                x="258.229"
                y="51.6563"
                width="25.8514"
                height="71.9686"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3.30563" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.286275 0 0 0 0 0.313726 0 0 0 0 0.341176 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2319_4456" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2319_4456" result="shape" />
            </filter>
            <filter
                id="filter8_d_2319_4456"
                x="170.479"
                y="178.455"
                width="25.8514"
                height="71.9686"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3.30563" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.286275 0 0 0 0 0.313726 0 0 0 0 0.341176 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2319_4456" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2319_4456" result="shape" />
            </filter>
            <filter
                id="filter9_d_2319_4456"
                x="170.479"
                y="178.455"
                width="25.8514"
                height="71.9686"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3.30563" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.286275 0 0 0 0 0.313726 0 0 0 0 0.341176 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2319_4456" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2319_4456" result="shape" />
            </filter>
        </defs>
    </svg>
)

const PlanningPokerIcon = props => <Icon component={PlanningPokerSvg} {...props} />

export default PlanningPokerIcon

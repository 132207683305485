import React, { Suspense } from 'react'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import Auth from './components/Authorization/Login'
import PasswordLink from './components/Authorization/PasswordLink'
import moment from 'moment'
import '../node_modules/moment/locale/pl'
import { ROUTES } from './Routes'
import PrivateRoute from './components/Common/PrivateRoute'
import PasswordReset from './components/Authorization/PasswordReset'
import UserContextProvider from './components/Common/contexts/userContext'
import GlobalStyles from './globalStyles'
import Layout from './components/Layout/Layout'
import ScreenLoader from './ScreenLoader'

function App() {
    moment.locale('pl')
    return (
        <UserContextProvider>
            <GlobalStyles />
            <BrowserRouter>
                <Suspense fallback={ScreenLoader}>
                    <Switch>
                        <Route path={ROUTES.LOGIN} exact component={Auth} />
                        <Route path={`${ROUTES.PASSWORD_RESET}`} exact component={PasswordReset} />
                        <Route path={ROUTES.PASSWORD_LINK} exact component={PasswordLink} />
                        <PrivateRoute path="/" component={Layout}></PrivateRoute>
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </UserContextProvider>
    )
}

export default App

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 16px;
    height: 16px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.inactive)};
    }
`

const ExternalSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <path
                    className="cls-2"
                    d="M16,22H5a3,3,0,0,1-3-3V8A3,3,0,0,1,5,5h6a1,1,0,0,1,0,2H5A1,1,0,0,0,4,8V19a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V13a1,1,0,0,1,2,0v6A3,3,0,0,1,16,22Z"
                />
                <path
                    className="cls-2"
                    d="M22,3V9a1,1,0,0,1-2,0V5.42l-9.29,9.29a1,1,0,0,1-1.42,0,1,1,0,0,1,0-1.42L18.58,4H15a1,1,0,0,1,0-2h6A1,1,0,0,1,22,3Z"
                />
            </g>
        </g>
    </svg>
)

const ExternalIcon = props => <SuperIcon component={ExternalSvg} {...props} />

export default ExternalIcon

import React from 'react'
import { format } from 'date-fns'
import styled from 'styled-components'
import { Tabs } from 'antd'
import theme from '../../Common/theme'
import { DashboardBox, BoxTitle, TinyTitleDiv } from '../../Common/components/dashboard'
import CalendarIcon from '../../../assets/icons/CalendarIcon'
import ArrowRightIcon from '../../../assets/icons/ArrowRightIcon'

export const LeaveWrapper = styled(DashboardBox)`
    && {
        min-height: 260px;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
`

export const NumberOfLeaveTaken = styled.div`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.big};
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    color: ${theme.colors.primary};
`
export const OutOfNumberOfDays = styled.div`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumSmall};
    line-height: 1.67;
    text-align: left;
    color: ${theme.colors.inactive};
    align-self: flex-end;
`

export const DaysTakenWrapper = styled.div`
    display: flex;
    height: 32px;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 16px;
    padding-left: 32px;

    @media (max-width: 599px) {
        padding-left: 16px;
    }
`

export const NoPlannedOrTakenLeaveWrapper = styled.div`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumSmall};
    line-height: 1.67;
    height: 20px;
    text-align: left;
    color: ${theme.colors.inactive};
    padding-left: 32px;
    margin-top: 16px;
    @media (max-width: 599px) {
        padding-left: 16px;
        margin-top: 0px;
    }
`

export const PlannedOrTakenLeaveWrapper = styled.div`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumSmall};
    line-height: 1.67;
    height: 20px;
    text-align: left;
    color: ${theme.colors.inactive};
    margin-top: 16px;
    margin-bottom: 8px;
    padding-left: 32px;

    @media (max-width: 599px) {
        margin-top: 0px;
        padding-left: 16px;
    }
`
const StartDateSpan = styled.span`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumSmall};
    text-transform: capitalize;
    line-height: 1.67;
    text-align: left;
    color: ${theme.colors.secondary};
    padding-left: 6px;
    padding-right: 14px;
`

const EndDateSpan = styled.span`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumSmall};
    text-transform: capitalize;
    line-height: 1.67;
    text-align: left;
    color: ${theme.colors.secondary};
    padding-left: 14px;
    margin-right: 4px;
`

const ResultsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding-left: 32px;

    @media (max-width: 599px) {
        padding-left: 16px;
    }
`

const LeaveSum = styled.span`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.mediumSmall};
    line-height: 1.67;
    text-align: left;
    color: ${theme.colors.inactive};
`
export const LeaveBoxTitle = styled(BoxTitle)`
    padding-left: 32px;
    padding-top: 32px;

    @media (max-width: 599px) {
        padding-left: 16px;
        padding-top: 16px;
    }
`

export const DaysOffThatWereTaken = styled(TinyTitleDiv)`
    padding-left: 32px;

    @media (max-width: 599px) {
        padding-left: 16px;
    }
`

export const TabsWrapper = styled(Tabs)`
    && {
        & > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap {
            padding-left: 16px !important;
        }

        z-index: 0;
        .ant-tabs-ink-bar {
            background: ${theme.colors.blue};
        }
        .ant-tabs-nav .ant-tabs-tab:hover {
            color: ${theme.colors.blue};
        }

        &.ant-tabs-top > .ant-tabs-nav::before {
            border-bottom: ${theme.borders.thin} solid ${theme.colors.border};
        }

        .ant-tabs-nav .ant-tabs-tab-active {
            color: ${theme.colors.blue};
            font-weight: 500;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: ${theme.colors.blue};
            font-weight: 500;
        }

        .ant-tabs-nav-list {
            margin: 0 0 0 32px;
        }

        @media all and (max-width: 599px) {
            .ant-tabs-nav-list {
                margin: 0 0 0 16px;
            }
        }

        & > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav {
            color: ${theme.colors.primary} !important;

            & > .ant-tabs-tab:hover {
                color: blue !important;
            }
            & > .ant-tabs-tab {
                &:hover {
                    color: blue !important;
                }
            }

            &:active {
                color: blue !important;
            }

            &:focus {
                color: blue !important;
            }
        }
    }
`

export const Result = ({ period }) => (
    <ResultsWrapper>
        <CalendarIcon />
        {period.duration === 1 ? (
            <StartDateSpan>{format(period.startDate, 'dd MMM yyyy')}</StartDateSpan>
        ) : (
            <>
                <StartDateSpan>{format(period.startDate, 'dd MMM yyyy')}</StartDateSpan>
                <ArrowRightIcon />
                <EndDateSpan>{format(period.endDate, 'dd MMM yyyy')}</EndDateSpan>
                <LeaveSum>({period.duration} dni)</LeaveSum>
            </>
        )}
    </ResultsWrapper>
)

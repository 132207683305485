import { useState, useEffect, useMemo } from 'react'
import { JWT_TOKEN } from '../../../Constants'

export default function useConfig() {
    const [appConfig, setAppConfig] = useState([])

    let config = useMemo(
        () => ({
            headers: { Authorization: `Bearer ${JWT_TOKEN()}` },
        }),
        [],
    )

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/hr-config`, config)
            await response
                .json()
                .then(res => {
                    setAppConfig(res)
                })
                .catch(err => console.log(err))
        }
        fetchData()
    }, [config])
    return appConfig
}

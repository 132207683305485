import React, { useState, useContext, useEffect, useRef, createRef } from 'react'
import moment from 'moment'
import Inputs from './LogsInputBox/LogsInputBox'
import {
    Day,
    BlueDot,
    BlueLine,
    TodayMarker,
    DayWrapper,
    HolidayDay,
    DayCellHeaderWrapper,
    DayCellContentWrapper,
    FridayFooterLabel,
    FridayFooter,
    HolidayDayWrapper,
} from '../Common/components/calendar'
import {
    DayCellHeader,
    DailyHourSum,
    Hour,
    InputsWrapper,
    CommentButton,
    CommentButtonContainer,
    ErrorAlert,
} from './WorkLogs.components'
import CommentIcon from '../../assets/icons/CommentIcon'
import ErrorIcon from '../../assets/icons/ErrorIcon'
import { WorkLogsContext } from './context/WorkLogsContext'
import './LogsStyles.css'
import theme from '../Common/theme'
import convertNumberToTime from '../../shared/convertNumberToTime'
import Media from 'react-media'
import WorkLogsMobile from './WorkLogsMobile'
import { format, isAfter, isToday, isWeekend, parseISO } from 'date-fns'
import FridayStarsIcon from '../../assets/icons/FridayStarsIcon'
import HolidayIcon from '../../assets/icons/HolidayIcon'

const icon = <ErrorIcon />

const WorkLogs = ({ isMonthClosed, daysArray, firstDay, bankHolidays, ...props }) => {
    const [closedMonthError, setClosedMonthError] = useState(false)
    const {
        worklogsForCurrentMonth: logs,
        projectsForCurrentMonth,
        invalidValueError,
        setInvalidValueError,
        setShowComments,
        showComments,
        showProjects,
        setCurrentDate,
        pageChanged,
        holidays,
    } = useContext(WorkLogsContext)
    const ref = useRef(null)
    const dayRefs = useRef([])
    if (dayRefs.current.length !== daysArray.length) {
        dayRefs.current = Array(daysArray.length)
            .fill()
            .map((_, i) => dayRefs.current[i] || createRef())
    }

    const checkIfDisabled = () => {
        if (isMonthClosed) {
            setClosedMonthError(true)

            setTimeout(() => {
                setClosedMonthError(false)
            }, 5000)
        } else return
    }

    const currentMonthDays = daysArray.map((day, i) => {
        const isFridayToday = parseISO(day.date).getDay() === 5 && isToday(parseISO(day.date))

        const checkFirst = day.dayNo === 1
        let isVisible = true

        let isBankHoliday = bankHolidays?.includes(day.date)

        const projectsForDay = projectsForCurrentMonth.filter(
            p => p.employeeStartDate <= day.date && p.employeeEndDate >= day.date,
        )
        const workLogsForDay = logs.filter(wl => wl.date === day.date)
        let totalHoursWorkedInDay = workLogsForDay.reduce((pv, cv) => (pv += parseFloat(cv.workedHours, 10)), 0)

        let isHoliday = false
        const foundHoliday = holidays && holidays.find(h => format(parseISO(h.date), 'yyyy-MM-dd') === day.date)
        if (foundHoliday) {
            isHoliday = true
            if (foundHoliday.isPaid) totalHoursWorkedInDay += foundHoliday.hours
        }

        let hourSumColor = theme.colors.inactive

        let checkForZeroHours = totalHoursWorkedInDay === 0 && !isWeekend(parseISO(day.date))
        if (format(parseISO(day.date), 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')) {
            checkForZeroHours = false
        }
        if (checkForZeroHours && !isHoliday && !isBankHoliday) {
            hourSumColor = theme.colors.error
        }
        const checkIfItsFuture = isAfter(parseISO(day.date), new Date())
        if (checkIfItsFuture && totalHoursWorkedInDay === 0) {
            isVisible = false
        }
        let showCommentButton = false
        const foundComment = workLogsForDay.find(wl => wl.description)
        if (foundComment && foundComment.description !== 'OPTIONAL DESC') {
            showCommentButton = true
        }

        const openCommentDrawer = () => {
            setShowComments(true)
            setCurrentDate(day.date)
        }

        return (
            <Media
                key={`${day.date}-${day.dayNo}`}
                queries={{
                    small: '(max-width: 599px)',
                    medium: '(min-width: 600px) and (max-width: 1199px)',
                    large: '(min-width: 1200px)',
                }}>
                {matches => (
                    <>
                        {(matches.small || matches.medium) && (
                            <>
                                {isToday(parseISO(day.date)) && (
                                    <TodayMarker ref={dayRefs.current[i]}>
                                        <BlueDot />
                                        <BlueLine />
                                    </TodayMarker>
                                )}
                                <WorkLogsMobile
                                    today={isToday(parseISO(day.date))}
                                    weekend={isWeekend(parseISO(day.date))}
                                    holiday={isBankHoliday}
                                    isClosed={isMonthClosed}
                                    dateValue={day.date}
                                    projects={projectsForDay}
                                    logs={workLogsForDay}
                                    index={i}
                                    data={day}
                                    bgColor={isWeekend(parseISO(day.date)) || isBankHoliday}
                                    hide={!isVisible}
                                    isHoliday={isHoliday}
                                    sumColor={hourSumColor}
                                    hours={totalHoursWorkedInDay}
                                    openCommentDrawer={openCommentDrawer}
                                />
                            </>
                        )}
                        {matches.large && (
                            <DayWrapper
                                bgColor={isWeekend(parseISO(day.date)) || isBankHoliday}
                                isMonday={(firstDay + day.dayNo - 1) % 7 === 1}>
                                {isToday(parseISO(day.date)) && <BlueLine ref={ref} />}
                                {isHoliday && !isBankHoliday ? (
                                    <HolidayDayWrapper
                                        today={isToday(parseISO(day.date))}
                                        isCurrentlyFriday={isFridayToday}>
                                        <HolidayDay
                                            today={isToday(parseISO(day.date))}
                                            onClick={() => checkIfDisabled()}
                                            key={day.date}>
                                            <DayCellContentWrapper>
                                                <DayCellHeaderWrapper>
                                                    <DayCellHeader bankHoliday={isBankHoliday} isHoliday={isHoliday}>
                                                        {checkFirst ? (
                                                            <span>{moment(day.date).format('D MMM')}</span>
                                                        ) : (
                                                            <span>{day.dayNo}</span>
                                                        )}
                                                        {isHoliday && <HolidayIcon />}
                                                    </DayCellHeader>
                                                    <DailyHourSum
                                                        hide={
                                                            !isVisible ||
                                                            (totalHoursWorkedInDay === 0 &&
                                                                (isHoliday || isBankHoliday))
                                                        }
                                                        sumColor={hourSumColor}>
                                                        {convertNumberToTime(totalHoursWorkedInDay)}
                                                        <Hour sumColor={hourSumColor}>h</Hour>
                                                    </DailyHourSum>
                                                </DayCellHeaderWrapper>
                                                <InputsWrapper
                                                    isFuture={checkIfItsFuture}
                                                    tabIndex={-1}
                                                    ref={dayRefs.current[i]}>
                                                    <Inputs
                                                        key={`input-boxes-${day.date}`}
                                                        isClosed={isMonthClosed}
                                                        dateValue={day.date}
                                                        projects={projectsForDay}
                                                        logs={workLogsForDay}
                                                    />
                                                </InputsWrapper>
                                            </DayCellContentWrapper>
                                            <CommentButtonContainer>
                                                <CommentButton
                                                    onClick={openCommentDrawer}
                                                    visible={showCommentButton ? 1 : 0}>
                                                    <CommentIcon></CommentIcon>
                                                </CommentButton>
                                            </CommentButtonContainer>
                                        </HolidayDay>
                                    </HolidayDayWrapper>
                                ) : (
                                    <Day
                                        isCurrentlyFriday={isFridayToday}
                                        today={isToday(parseISO(day.date))}
                                        onClick={() => checkIfDisabled()}
                                        key={day.date}>
                                        <DayCellContentWrapper>
                                            <DayCellHeader bankHoliday={isBankHoliday}>
                                                {checkFirst ? (
                                                    <span>{moment(day.date).format('D MMM')}</span>
                                                ) : (
                                                    <span>{day.dayNo}</span>
                                                )}

                                                <DailyHourSum hide={!isVisible} sumColor={hourSumColor}>
                                                    {convertNumberToTime(totalHoursWorkedInDay)}
                                                    <Hour sumColor={hourSumColor}>h</Hour>
                                                </DailyHourSum>
                                            </DayCellHeader>
                                            <InputsWrapper
                                                isFuture={checkIfItsFuture}
                                                tabIndex={-1}
                                                ref={dayRefs.current[i]}>
                                                <Inputs
                                                    key={`input-boxes-${day.date}`}
                                                    isClosed={isMonthClosed}
                                                    dateValue={day.date}
                                                    projects={projectsForDay}
                                                    logs={workLogsForDay}
                                                />
                                            </InputsWrapper>
                                        </DayCellContentWrapper>
                                        <CommentButtonContainer>
                                            <CommentButton
                                                onClick={openCommentDrawer}
                                                visible={showCommentButton ? 1 : 0}>
                                                <CommentIcon></CommentIcon>
                                            </CommentButton>
                                        </CommentButtonContainer>
                                    </Day>
                                )}
                                {isFridayToday && (
                                    <FridayFooter>
                                        <FridayFooterLabel>Piąteczek, Piątunio!</FridayFooterLabel>
                                        <FridayStarsIcon />
                                    </FridayFooter>
                                )}
                            </DayWrapper>
                        )}
                    </>
                )}
            </Media>
        )
    })

    //focus cell and "scroll to today" for web and mobile, needs cleaning
    useEffect(() => {
        const dayIndex = daysArray.findIndex(el => el.date === format(new Date(), 'yyyy-MM-dd'))
        if (dayRefs.current[dayIndex] && dayRefs.current[dayIndex].current && !showComments && !showProjects) {
            if (!pageChanged) {
                dayRefs.current[dayIndex].current.focus()
                dayRefs.current[dayIndex].current.scrollIntoView({ behavior: 'auto', block: 'center' })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [daysArray, pageChanged])

    const closedMonthAlert = (
        <ErrorAlert
            icon={icon}
            description="Ten miesiąc jest już zablokowany"
            type="error"
            showIcon
            closable="true"
            onClose={() => {
                setClosedMonthError(false)
            }}
            className="error-alert"
            data-cy="closed-month-error"
        />
    )

    const invalidValueAlert = (
        <ErrorAlert
            icon={icon}
            description="Coś za dużo. Tyle nie wolno. 16 godzin to maks. Na spokojnie."
            type="error"
            showIcon
            closable="true"
            onClose={() => {
                setInvalidValueError(false)
            }}
            className="error-alert"
            data-cy="closed-month-error"
        />
    )

    return (
        <>
            {currentMonthDays}
            {closedMonthError && closedMonthAlert}
            {invalidValueError && invalidValueAlert}
        </>
    )
}

export default WorkLogs

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const DocumentIconSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" fill="none" width="24" height="24" />
                <path
                    className="cls-2"
                    d="M19,20H5a3,3,0,0,1-3-3V7A3,3,0,0,1,5,4h5a1,1,0,0,1,.71.29L12.41,6H19a3,3,0,0,1,3,3v8A3,3,0,0,1,19,20ZM5,6A1,1,0,0,0,4,7V17a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1H12a1,1,0,0,1-.71-.29L9.59,6Z"
                />
            </g>
        </g>
    </svg>
)

const DocumentIcon = props => <SuperIcon collapsed={props.collapsed} component={DocumentIconSvg} {...props} />

export default DocumentIcon

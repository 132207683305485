import React from 'react'
import styled from 'styled-components'
import theme from '../../Common/theme'
import FilterOption from './FilterOption'
import Calendar from './Calendar'
import CalendarIcon from '../../../assets/icons/CalendarIcon'
import CalendarIconArrow from '../../../assets/icons/CalendarIconArrow'
import AlwaysIcon from '../../../assets/icons/AlwaysIcon'

const ModalWrapper = styled.div`
    flex: 1;
    position: absolute;
    top: 56px;
    width: 316px;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(32, 32, 35, 0.16);
    background-color: ${theme.colors.white};
    z-index: 1;
`

const OptionsWrapper = styled.div`
    padding: 8px 0px 8px 0px;
    border-bottom: solid ${theme.borders.thin} ${theme.colors.disabled};
`

const FilterModal = ({ opened, onChange, value }) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const filterOptions = [
        {
            text: 'Ostatnie 7 dni',
            icon: <CalendarIcon color={theme.colors.inactive} />,
            startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
            endDate: today,
        },
        {
            text: 'Ostatnie 14 dni',
            icon: <CalendarIconArrow color={theme.colors.inactive} />,
            startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14),
            endDate: today,
        },
        {
            text: 'Zawsze',
            icon: <AlwaysIcon color={theme.colors.inactive} />,
            startDate: new Date(
                today.getFullYear() -
                    5 /* tu mozna zmienic, nie mam pojecia ile lat ma 6k, a pobieranie przerwszej odnotowanej daty jest bez sensu imo */,
                today.getMonth(),
                today.getDate(),
            ),
            endDate: today,
        },
    ]
    return (
        opened && (
            <ModalWrapper>
                <OptionsWrapper>
                    {filterOptions.map(option => (
                        <FilterOption option={option} key={option.name} onClick={onChange} />
                    ))}
                </OptionsWrapper>
                <Calendar onSet={onChange} value={value} />
            </ModalWrapper>
        )
    )
}

export default FilterModal

import { Menu } from 'antd'
import styled from 'styled-components'
import theme from '../Common/theme'

export const { SubMenu } = Menu

export const SubMenuItem = styled(Menu.Item)`
    &.ant-menu-submenu {
        margin-bottom: 0;
    }
`

export const StyledTitle = styled.span`
    color: ${props => (props.highlight ? theme.colors.blue : theme.colors.secondary)} !important;
    flex: 1;
    margin-left: 8px;
    margin-top: 4px;
`

export const ExternalLinkWrapper = styled.a`
    display: flex;
    flex: 1;

    align-items: center;
`

export const ExternalLinkContentWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    &&& {
        background-color: rgba(0, 0, 0, 0) !important;
    }
`

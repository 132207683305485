import React, { useContext } from 'react'
import locale from 'antd/es/date-picker/locale/pl_PL'
import moment from 'moment'
import { PickDate } from './CalendarNavigationMobile.components'
import '../../legend.css'

export const PickDateMenu = ({ context, ...props }) => {
    const { dateValue, changeDate, pending } = useContext(context)

    return (
        <PickDate
            picker="month"
            monthCellRender={date => {
                return moment(date).format('MMMM').charAt(0).toUpperCase() + moment(date).format('MMMM').slice(1)
            }}
            mode="month"
            defaultPickerValue={moment()}
            defaultValue={moment()}
            format="MMMM YYYY"
            placeholder=""
            isPending={pending}
            locale={locale}
            value={dateValue}
            open={props.shouldOpen}
            onChange={!pending && changeDate}
            allowClear={false}
            data-cy="current-month"
        />
    )
}

export default PickDateMenu

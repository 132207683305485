import React from 'react'
import Icon from '@ant-design/icons'

const PassboltLogoSvg = () => (
    <svg width="24" height="24" viewBox="0 0 256 236" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
        <path
            d="M249.242 102.85L159.614 7.79c-9.4-9.969-25.1-10.431-35.069-1.032L58.17 69.34c12.508 8.279 22.01 20.714 26.566 35.332h94.898c13.242 0 13.242 31.837 0 31.837h-9.345v19.57c0 12.073-41.768 12.073-41.768 0v-19.57H86.495a65.752 65.752 0 0 1-21.049 36.999l51.23 54.334c9.398 9.968 25.1 10.43 35.068 1.031l96.466-90.954c9.97-9.399 10.431-25.1 1.032-35.069zm-222.37-6.064C12.032 96.785 0 108.816 0 123.656 0 138.5 12.031 150.53 26.872 150.53s26.872-12.031 26.872-26.873c0-14.84-12.03-26.871-26.872-26.871z"
            fill="#212529"
        />
    </svg>
)

const PassboltLogoIcon = props => <Icon className="icon" component={PassboltLogoSvg} {...props} />

export default PassboltLogoIcon

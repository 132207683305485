import { ROUTES } from '../Routes'

const API_URL = `${process.env.REACT_APP_API_URL}`

const APIAddress = url => {
    return url.indexOf('https://') > -1 ? url : API_URL + url
}

const fetchAPI = async (url, config) => {
    const authorizationRequired = !!config.headers && !!config.headers.Authorization
    const res = await fetch(APIAddress(url), config)
    if (authorizationRequired && res.status === 401) {
        localStorage.removeItem('token')
        window.location.href = ROUTES.LOGIN
    } else if (res.ok) {
        const resJSON = await res.json()
        return resJSON
    } else {
        return res
    }
}

export default fetchAPI

import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { JWT_TOKEN } from '../../../Constants'
import moment from 'moment'
import styled from 'styled-components'
import { UserContext } from '../../Common/contexts/userContext'
import fetchAPI from '../../../services/fetchAPI'
import ErrorModal from '../../Common/components/error-modal'
import Loader from '../../Common/components/spinner'
import useCalendarNavigation from '../../../services/useCalendarNavigation'

export const OKSystemContext = createContext([])

const Content = styled.div`
    flex: 1;
    // @media only screen and (min-width: 1200px){
    //   margin-left: 256px !important;
    // }
`

let config = () => {
    return {
        headers: { Authorization: `Bearer ${JWT_TOKEN()}` },
    }
}

let configPOST = body => {
    return {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JWT_TOKEN()}`,
        },
        body: JSON.stringify(body),
    }
}

const OKSystemContextProvider = props => {
    const user = useContext(UserContext)
    const [pending, setPending] = useState(false)
    const [loading, setLoading] = useState(true)
    const [currentMonthCodes, setCurrentMonthCodes] = useState([])
    const [error, setError] = useState('')
    let oldCodes = currentMonthCodes
    const codesChanged = (dayCodes, date) => {
        addCode(dayCodes, date).then(
            res => {
                setCurrentMonthCodes(res)
                setPending(false)
            },
            error => {
                const errorCodes = oldCodes
                oldCodes = currentMonthCodes
                setCurrentMonthCodes(errorCodes)
                setError('ADD')
            },
        )
    }

    const { dateValue, goForwardOneMonth, goBackOneMonth, changeDate, goToToday } = useCalendarNavigation()

    const state = {
        codes: currentMonthCodes,
        codesChanged,
        dateValue,
        goForwardOneMonth,
        goBackOneMonth,
        changeDate,
        goToToday,
        pending,
        loading,
    }

    const addCode = async (dayCodes, date) => {
        const currentMonth = moment(date).format('YYYY-MM')
        setPending(true)
        dayCodes = dayCodes.filter(el => !!el && (el.length > 0 || el.code))
        let codesFromMonth = currentMonthCodes || []
        codesFromMonth = codesFromMonth.filter(el => el.date !== date)
        codesFromMonth = codesFromMonth.concat(
            dayCodes.map(el => {
                return { date: date, code: el }
            }),
        )
        oldCodes = currentMonthCodes

        setCurrentMonthCodes(codesFromMonth)
        return sendCodes(currentMonth, codesFromMonth)
    }

    const getData = useCallback(() => {
        const currentMonth = moment(dateValue).format('YYYY-MM')
        return fetchAPI(`/employees/${user.details.id}/oksystem-logs/${currentMonth}`, config())
    }, [user, dateValue])

    const sendCodes = (currentMonth, codesFromMonth) => {
        return fetchAPI(`/employees/${user.details.id}/oksystem-logs/${currentMonth}`, configPOST(codesFromMonth))
    }

    useEffect(() => {
        getData().then(
            res => {
                if (res.errors) {
                    alert(res.errors[0].msg)
                } else {
                    setCurrentMonthCodes(!!res.okSystemLogs ? res.okSystemLogs : [])
                    setLoading(false)
                }
            },
            error => {
                setError('FETCH')
            },
        )
    }, [getData])

    const handleOk = () => {
        if (error === 'ADD') {
            setError('')
            sendCodes(moment().format('YYYY-MM'), oldCodes[moment().format('YYYY-MM')]).then(
                res => {
                    setCurrentMonthCodes(res)
                    setPending(false)
                },
                error => {
                    const errorCodes = oldCodes
                    oldCodes = currentMonthCodes
                    setCurrentMonthCodes(errorCodes)
                    setError('ADD')
                },
            )
        }

        if (error === 'FETCH') {
            getData().then(
                res => {
                    setError('')
                    if (res.errors) {
                        alert(res.errors[0].msg)
                    } else {
                        setCurrentMonthCodes(!!res.okSystemLogs ? res.okSystemLogs : [])
                    }
                },
                error => {
                    setError('')
                    setError('FETCH')
                },
            )
        }
    }

    const handleCancel = () => {
        setError('')
        setPending(false)
    }

    const handleExit = useCallback(e => {
        e.preventDefault()
        e.returnValue = ''
    }, [])

    useMemo(() => {
        if (pending) {
            window.addEventListener('beforeunload', handleExit)
        } else {
            window.removeEventListener('beforeunload', handleExit)
        }

        return () => window.removeEventListener('beforeunload', handleExit)
    }, [handleExit, pending])

    return (
        <OKSystemContext.Provider value={state}>
            <ErrorModal error={error} handleOk={handleOk} handleCancel={handleCancel} afterClose={handleCancel}>
                Wygląda na to, że nie udało się
                {error === 'FETCH' ? ' pobrać' : ' zapisać'} kodów. Spróbuj ponownie, a&nbsp;jeśli&nbsp;problem dalej
                występuje, sprawdź swoje połączenie z&nbsp;Internetem.
            </ErrorModal>
            {loading && <Loader />}
            <Content hidden={loading}>{props.children}</Content>
        </OKSystemContext.Provider>
    )
}

export default OKSystemContextProvider

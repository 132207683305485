import styled from 'styled-components'
import { Button } from 'antd'
import theme from '../theme'

export const ButtonBlank = styled(Button)`
    && {
        display: flex;
        height: 36px;
        justify-content: center;
        align-items: center;
        border: none;
        box-shadow: none;
        &::after {
            animation-duration: 0s !important;
        }

        > span {
            display: inline-block;
            color: black;
            text-align: center;
            border: none;
        }

        &:hover,
        &:active,
        &:focus {
            > span {
                color: blue;
            }
        }
    }
`

export const SpecialHeaderMobileButton = styled(ButtonBlank)`
    && {
        margin-top: 20px;
        margin-right: 16px;
        padding: 0;
    }
`

export const GoToTopButton = styled.div`
    position: absolute;
    bottom: 16px;
    width: 56px;
    height: 56px;
    right: 16px;
    border-radius: 50%;
    z-index: 120;
    margin-right: 0px !important;
    visibility: ${props => (!props.sidebarShown && props.ratio < 0.5 ? 'visible' : 'hidden')};
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
    background-color: ${theme.colors.yellow};
    justify-content: center;
    align-items: center;
    display: flex;
`

export const ButtonPrimary = styled(ButtonBlank)`
    && {
        margin: 0;
        background-color: ${theme.colors.blue};
        border-color: ${theme.colors.blue};
        border-radius: 4px;

        > span {
            color: white;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: ${theme.colors.blueHover};
            border-color: ${theme.colors.blueHover};
            > span {
                color: white;
            }
        }
    }
`

export const ButtonRed = styled(ButtonPrimary)`
    && {
        background-color: red;
        border-color: red;

        &:hover,
        &:active,
        &:focus {
            background-color: red;
            border-color: red;
        }
    }
`

export const ButtonNormal = styled(Button)`
    width: 71px;
    height: 36px;
    border: solid ${theme.borders.thin} ${theme.colors.border};
    background-color: ${theme.colors.background};

    &:hover,
    &:active,
    &:focus {
        border-color: blue !important;
        border-left-color: blue !important;
        background-color: ${theme.colors.hover} !important;
        color: rgba(0, 0, 0, 0.65) !important;
    }
`

export const CloseButtonMobile = styled(ButtonBlank)`
    && {
        background-color: transparent;
        margin-top: 16px;
        margin-left: 16px;
        padding: 0px;
    }
`

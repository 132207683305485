import React from 'react'
import styled from 'styled-components'
import theme from '../../Common/theme'

const OptionWrapper = styled.div`
    display: flex;
    font-family: 'SofiaProRegular';
    height: 36px;
    font-size: ${theme.fontSizes.medium};
    line-height: 1.71;
    padding-left: 32px;
    align-items: center;
    color: ${theme.colors.inactive};
    &:hover {
        background-color: ${theme.colors.hover};
    }
`

const OptionText = styled.span`
    padding-left: 16px;
`

const FilterOption = ({ option, onClick }) => {
    return (
        <OptionWrapper
            onClick={() =>
                onClick({
                    startDate: option.startDate,
                    endDate: option.endDate,
                })
            }>
            {option.icon}
            <OptionText>{option.text}</OptionText>
        </OptionWrapper>
    )
}

export default FilterOption

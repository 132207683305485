import React, { useContext, useRef, useState } from 'react'
import locale from 'antd/es/date-picker/locale/pl_PL'
import moment from 'moment'
import '../legend.css'
import { Space } from 'antd'
import ChevronRightIcon from '../../../../assets/icons/ChevronRightIcon'
import ChevronLeftIcon from '../../../../assets/icons/ChevronLeftIcon'
import ChevronUpIcon from '../../../../assets/icons/ChevronDownIcon'
import {
    PickDate,
    NavigationWrapper,
    TodayButton,
    StyledLabel,
    PreviousMonthButton,
    NextMonthButton,
} from './CalendarNavigation.components'

const CalendarNavigation = ({ context, ...props }) => {
    const { dateValue, goToToday, goBackOneMonth, goForwardOneMonth, changeDate, pending } = useContext(context)
    const pickerRef = useRef(null)

    const [showPicker, setShowPicker] = useState(false)

    const togglePicker = () => {
        setShowPicker(!showPicker)
    }

    const close = () => {
        setShowPicker(false)
    }

    // TODO zachowanie przy wybieraniu daty z kalendarza
    const menu = (
        <PickDate
            picker="month"
            monthCellRender={date => {
                return moment(date).format('MMMM').charAt(0).toUpperCase() + moment(date).format('MMMM').slice(1)
            }}
            defaultPickerValue={moment()}
            defaultValue={moment()}
            format="MMMM YYYY"
            placeholder="Wybierz"
            ispending={pending ? 1 : 0}
            mode="month"
            inputReadOnly={true}
            locale={locale}
            value={dateValue}
            onChange={date => {
                !pending && changeDate(date)
                close()
            }}
            allowClear={false}
            suffixIcon={<ChevronUpIcon collapsed={showPicker} menuCollapsed={true} />}
            ref={pickerRef}
            tabIndex={-1}
            open={showPicker}
            onClick={togglePicker}
            data-cy="current-month"
        />
    )

    return (
        <NavigationWrapper>
            <Space>
                <TodayButton
                    onClick={!pending && goToToday}
                    data-cy="today"
                    ispending={pending ? 1 : 0}
                    type="link"
                    tabIndex={-1}>
                    <StyledLabel>Dzisiaj</StyledLabel>
                </TodayButton>
                <PreviousMonthButton
                    onClick={!pending && goBackOneMonth}
                    value={dateValue}
                    tabIndex={-1}
                    data-cy="prev-month"
                    ispending={pending ? 1 : 0}>
                    <ChevronLeftIcon />
                </PreviousMonthButton>
                <NextMonthButton
                    onClick={!pending && goForwardOneMonth}
                    value={dateValue}
                    tabIndex={-1}
                    data-cy="next-month"
                    ispending={pending ? 1 : 0}>
                    <ChevronRightIcon />
                </NextMonthButton>
                {menu}
            </Space>
        </NavigationWrapper>
    )
}

export default CalendarNavigation

import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import ErrorIcon from '../../../assets/icons/ErrorIcon'
import PhoneIcon from '../../../assets/icons/PhoneIcon'
import { ButtonBlank, ButtonRed, ButtonNormal } from './buttons'
import { BasicFormInput } from './form'
import Clipboard from 'react-clipboard.js'
import Media from 'react-media'
import theme from '../../Common/theme'

const ModalHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
`

const ModalErrorIcon = styled(ErrorIcon)`
    && {
        & > svg > g > g > .cls-2 {
            fill: red;
        }
    }
`

const ModalErrorWrapper = styled.div`
    && {
        height: 40px;
        border-radius: 50%;
        background-color: rgba(255, 1, 0, 0.08);
        padding: 8px;
        margin-right: 16px;
    }
`

const ModalHeader = styled.span`
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.big};
    color: ${theme.colors.primary};
    margin-top: 4px;
`

const ModalInfo = styled.p`
    margin-right: 6px;
    margin-bottom: 32px;
    font-family: SofiaProRegular;
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.secondary};
`

const ModalFooter = styled.div`
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-end;

    .ant-input-affix-wrapper-focused {
        border-color: ${theme.colors.border} !important;
        outline: none !important;
    }
`

const CancelButton = styled(ButtonBlank)`
    margin-right: 8px !important;
`

const ModalBody = styled(Modal)`
    && {
        width: 402px !important;
        height: 266px;

        @media (max-width: 599px) {
            width: 300px;
            height: 150px;
        }
    }
`
const PhoneNumber = styled(BasicFormInput)`
    && {
        padding-bottom: 0px;
        padding-top: 0px;
        z-index: 10;
        line-height: 36px;
        align-items: center;
        &:hover,
        &:focus,
        &:active {
            border-color: ${theme.colors.border} !important;
            box-shadow: none !important;
        }

        & > input {
            padding-left: 35px !important;
        }
    }
`

const CopyButton = styled(ButtonNormal)`
    && {
        width: 71px;
        height: 38px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-right: -12px;
        @media (max-width: 599px) {
            padding-left: 12px;
        }
    }
`

// const CallButton = styled(CopyButton)`
//     &&{
//         margin-right: 0px;
//         width: 64px;
//         background-color: blue;
//         color: white;
//         padding: 0;
//     }
// `

const CustomPhoneIcon = styled(PhoneIcon)`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        & > svg {
            width: 18px;
            height: 18px;
        }
        .cls-2 {
            fill: ${theme.colors.inactive} !important;
        }
    }
`

const PhoneModal = props => {
    const [showNumber, setShowNumber] = useState(false)

    const handleNumberDisplay = () => {
        setShowNumber(true)
    }
    return (
        <ModalBody
            title="Ups... coś poszło nie tak."
            visible={!!props.error}
            onCancel={() => props.handleCancel()}
            afterClose={() => props.handleCancel()}
            maskClosable={true}
            closable={false}
            centered>
            <ModalHeaderWrapper>
                <ModalErrorWrapper>
                    <ModalErrorIcon />
                </ModalErrorWrapper>
                <ModalHeader>Zastanów się</ModalHeader>
            </ModalHeaderWrapper>
            <ModalInfo>
                Podane numery telefonów są numerami prywatnymi. <br />
                Czy na pewno chcesz wyświetlić ten numer?
            </ModalInfo>
            <ModalFooter>
                {!showNumber ? (
                    <>
                        <CancelButton onClick={props.handleCancel} type="button">
                            Anuluj
                        </CancelButton>
                        <ButtonRed onClick={handleNumberDisplay} type="button">
                            Pokaż
                        </ButtonRed>
                    </>
                ) : (
                    <PhoneNumber
                        placeholder={props.phoneNo}
                        value={props.phoneNo.replace(/(\d{3})/g, '$1 ').replace(/(^\s+|\s+$)/, '')}
                        prefix={<CustomPhoneIcon />}
                        suffix={
                            <Media
                                queries={{
                                    small: '(max-width: 599px)',
                                    other: '(min-width: 600px)',
                                }}>
                                {matches => (
                                    <>
                                        {matches.small && (
                                            <a href={`tel: ${props.phoneNo}`}>
                                                <CopyButton onClick={() => props.handleCancel()}>Dzwoń</CopyButton>
                                            </a>
                                        )}
                                        {matches.other && (
                                            <>
                                                <Clipboard
                                                    onClick={() => {
                                                        props.handleCancel()
                                                        props.clickedCopy()
                                                    }}
                                                    component={CopyButton}
                                                    data-clipboard-text={props.phoneNo}>
                                                    Kopiuj
                                                </Clipboard>
                                            </>
                                        )}
                                    </>
                                )}
                            </Media>
                        }
                    />
                )}
            </ModalFooter>
        </ModalBody>
    )
}

export default PhoneModal

import styled from 'styled-components'
import holidayPattern from '../../../assets/icons/holiday-pattern.svg'
import theme from '../../Common/theme'

export const CalendarContent = styled.div`
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 46px auto;
    min-height: 690px;
    height: 100%;
    padding: 0;

    ${props =>
        props.lastMonday &&
        `& > div:nth-child(${props.lastMonday}) {
        border-bottom-left-radius: 8px;
    };`}
    & > div:last-child {
        border-bottom-right-radius: 8px;
    }
`

export const CalendarContentMobile = styled.div`
    min-height: 690px;
    height: 100%;
    padding: 0;
    width: 100%;
    background-color: ${theme.colors.background};

    @media (min-width: 768px) and (max-width: 1199px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

export const DayCellHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
`

export const DayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    user-select: none;
    justify-content: space-between;
    border-top: ${theme.borders.thin} solid ${theme.colors.border} !important;
    ${props => !props.isMonday && `border-left: ${theme.borders.thin} solid ${theme.colors.border} !important;`}
    max-height: 600px;
    min-width: 145px;
    min-height: 163px;
    background-color: ${props => (props.bgColor ? theme.colors.background : theme.colors.white)};

    @media only screen and (max-width: 1199px) {
        display: flex;
        flex-direction: row;
    }
`

export const DayCellContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Day = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 16px 16px 16px;
    ${props => props.today && `border: ${theme.borders.thin} solid ${theme.colors.blue};`}
    text-transform: ${props => (props.isFirstDay ? 'capitalize' : 'none')};

    &:hover {
        > div > button {
            display: inline-block;
        }
    }
`

export const HolidayDayWrapper = styled.div`
    display: flex;
    flex: 1;
    border: ${props => props.today && `${theme.borders.thin} solid ${theme.colors.blue}`} !important;
`

export const HolidayDay = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin: 8px 8px 8px 8px;
    padding: 8px 8px 8px 8px;
    text-transform: ${props => (props.isFirstDay ? 'capitalize' : 'none')};
    background-color: ${theme.colors.white};
    background-image: url(${holidayPattern});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &:hover {
        > div > button {
            display: inline-block;
        }
    }
`

export const FridayFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-size: 100%;
    background-color: ${theme.colors.blue};
    height: 24px;
`

export const FridayFooterLabel = styled.p`
    font-size: ${theme.fontSizes.small};
    font-family: SofiaProMedium;
    line-height: 20px;
    text-align: center;
    color: ${theme.colors.white};
    margin: 0px 6px 0px 0px;
`

export const DayHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    line-height: 1;
`

export const DayHeaderMobile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    margin-right: 20px;
    line-height: 1;
    width: 34px;
    margin-top: -1px;
    flex-shrink: 0 !important;
`

export const DayNrMobile = styled.span`
    width: 100%;
    font-size: ${theme.fontSizes.big};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    text-align: center;
    color: ${props => (props.holiday ? theme.colors.white : theme.colors.primary)} !important;
`

export const DayNameMobile = styled.span`
    width: 100%;
    font-size: ${theme.fontSizes.mediumSmall};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: ${props => (props.holiday ? theme.colors.white : theme.colors.primary)} !important;
`

export const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: ${props => (!props.collapsed ? '64px' : '220px')};
    transition: height 0.2s ease-in;
    flex-direction: ${props => (!props.collapsed ? 'unset' : 'column-reverse')};
    padding-left: 16px;
    padding-bottom: ${props => (!props.collapsed ? 'unset' : '24px')};
    width: 100%;
    padding-top: 19px;
    margin-right: 16px;
    border-radius: 4px;
    background-color: ${props => (props.bgColor ? theme.colors.background : theme.colors.white)} !important;
`

export const HeaderDay = styled(Day)`
    text-transform: capitalize;
    text-align: left;
    padding-bottom: 16px;
    color: ${props => props.fontColor};
    ${props => props.day !== 'poniedziałek' && `border-left: ${theme.borders.thin} solid ${theme.colors.border};`}
`

export const FirstDayOfMonth = styled(Day)`
    && {
        text-transform: capitalize;

        @media only screen and(max-width: 1199px) {
            min-height: ${props => (props.isFocused ? '220px' : '64px')};
            height: ${props => (props.isFocused ? '220px' : '64px')};
        }
    }
`

export const BlueLine = styled.div`
    height: 0px;
    width: 100%;
    border: solid ${theme.borders.thin} ${theme.colors.blue};
    margin-right: 16px;

    @media (min-width: 1200px) {
        width: 0px;
        height: 0px;
        display: none;
    }
`

export const BlueDot = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${theme.colors.blue};
    border-radius: 50%;
`

export const TodayMarker = styled.div`
    margin-top: 16px;
    margin-bottom: 13px;
    margin-left: 54px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: 768px) and (max-width: 1199px) {
        width: 100%;
    }
`

import React from 'react'
import Icon from '@ant-design/icons'

const GorrionBoxSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="9.14 141.8 573.65 573.65">
        <path class="st3" d="M582.79 549.77L295.96 384.1V207.27l286.83 165.68z" fill="#495057" />
        <path class="st0" d="M9.14 549.77L295.96 384.1V207.27L9.14 372.95z" fill="#495057" />
        <path
            d="M295.96 141.8c109.56 0 198.41 88.85 198.41 198.41s-88.85 198.41-198.41 198.41S97.55 449.77 97.55 340.21 186.4 141.8 295.96 141.8"
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="#ccc"
        />
        <path
            d="M295.96 141.8c109.6 0 198.48 88.85 198.48 198.41s-88.88 198.41-198.48 198.41c-62.91-42.34-88.94-127.64-88.94-198.3s26.03-156.1 88.94-198.52"
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="#e5e5e5"
        />
        <path class="st3" d="M582.79 372.95L295.96 538.62v176.83l286.83-165.68z" fill="#212529" />
        <path class="st0" d="M9.14 372.95l286.82 165.67v176.83L9.14 549.77z" fill="#212529" />
    </svg>
)

const GorrionBoxIcon = props => <Icon className="icon" component={GorrionBoxSvg} {...props} />

export default GorrionBoxIcon

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'

const SuperIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    padding-right: 2px;
`

const ArrowUpSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Layer_2" data-name="Layer 2" transform="translate(0 24) rotate(-90)">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect id="Rectangle_396" data-name="Rectangle 396" width="24" height="24" fill="none" />
                <path
                    id="Path_204"
                    data-name="Path 204"
                    d="M19.71,12.71l-6,6a1,1,0,1,1-1.42-1.42L16.59,13H5a1,1,0,0,1,0-2H16.59l-4.3-4.29a1,1,0,0,1,1.42-1.42l6,6a1,1,0,0,1,0,1.42Z"
                    fill="#fff"
                />
            </g>
        </g>
    </svg>
)

const ArrowUpIcon = props => <SuperIcon component={ArrowUpSvg} {...props} />

export default ArrowUpIcon

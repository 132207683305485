import React from 'react'
import styled from 'styled-components'
import Icon from '@ant-design/icons'

const SuperIcon = styled(Icon)`
    height: ${props => (props.height ? props.height : '245px')};
    width: ${props => (props.width ? props.width : '368px')};
    background-size: contain;
    background-repeat: no-repeat;
    right: 0px;
    bottom: 0px;
    position: absolute;

    @media only screen and (max-height: 669px) {
        height: 80px;
        width: 125px;
    }

    @media (min-height: 670px) and (max-height: 899px) {
        height: 150px;
        width: 225px;
    }

    @media (min-width: 1024px) and (min-height: 900px) {
        height: 245px;
        width: 368px;
    }
`

const LoginGorrionSVG = () => (
    <svg viewBox="0 0 326 217" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M255.683 4.49459C264.316 5.57491 287.783 10.7863 302.397 22.8198C313.96 32.3326 340.408 59.6505 351.712 104.334C364.715 155.939 367.596 200.992 364.815 227.65C362.595 248.906 366.266 267.992 335.807 286.267C313.98 299.351 205.709 285.347 185.963 274.723C163.276 262.51 161.376 258.419 160.105 253.657C158.835 248.896 146.461 196.551 155.104 142.625C161.746 101.023 179.931 46.5968 197.246 23.6501C212.741 3.01406 240.029 2.54402 255.683 4.49459Z"
            stroke="#212529"
            stroke-width="3.06149"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M260.625 170.283C260.625 170.283 282.351 158.159 295.365 151.818C297.786 150.637 298.526 179.826 294.365 179.736C277.77 179.246 260.625 170.283 260.625 170.283Z"
            stroke="#212529"
            stroke-width="3.06149"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M254.462 170.692C254.462 170.692 232.096 160.219 217.981 159.428C215.321 159.278 217.061 190.327 221.222 189.007C237.987 183.695 254.462 170.692 254.462 170.692Z"
            stroke="#212529"
            stroke-width="3.06149"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M233.046 112.636C240.818 138.174 255.352 150.647 255.352 150.647C255.352 150.647 270.967 131.502 275.248 113.416"
            stroke="#212529"
            stroke-width="3.06149"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M240.62 107.34C248.502 102.638 259.625 102.788 266.427 107.08"
            stroke="#212529"
            stroke-width="3.06149"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <g opacity="0.2">
            <path
                opacity="0.2"
                d="M261.651 185.761C262.085 185.882 262.451 186.238 262.664 186.791C262.878 187.344 262.92 188.062 262.713 188.808C262.505 189.554 262.097 190.147 261.629 190.51C261.16 190.874 260.663 190.989 260.229 190.868C259.795 190.747 259.428 190.391 259.215 189.838C259.002 189.285 258.959 188.567 259.167 187.821C259.374 187.075 259.782 186.482 260.251 186.119C260.719 185.755 261.217 185.64 261.651 185.761Z"
                fill="#212529"
                stroke="#212529"
                stroke-width="1.0205"
            />
            <path
                opacity="0.2"
                d="M253.531 205.805C254.364 206.284 254.68 207.512 254.052 208.605C253.423 209.698 252.203 210.043 251.37 209.564C250.538 209.086 250.221 207.858 250.849 206.765C251.477 205.672 252.698 205.326 253.531 205.805Z"
                fill="#212529"
                stroke="#212529"
                stroke-width="1.02401"
            />
        </g>
        <path
            d="M272.863 98.3106C267.98 73.0087 298.453 66.9589 307.576 88.597C310.183 94.782 308.453 98.1785 303.462 97.8559C291.146 94.5538 280.772 95.7877 276.414 97.611"
            stroke="#212529"
            stroke-width="2.04099"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M233.644 96.9996C237.873 71.5803 207.254 66.3175 198.692 88.1834C196.245 94.4335 198.062 97.7843 203.043 97.3332C215.27 93.715 225.673 94.6812 230.076 96.3917"
            stroke="#212529"
            stroke-width="2.04099"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M288.773 85.7678C291.019 86.0944 292.652 88.5186 292.244 91.3248C291.836 94.131 289.58 95.9897 287.334 95.6632C285.088 95.3366 283.455 92.9124 283.863 90.1062C284.271 87.3 286.527 85.4413 288.773 85.7678Z"
            fill="#212529"
            stroke="#212529"
            stroke-width="1.02376"
        />
        <path
            d="M218.014 84.5622C220.228 85.0599 221.671 87.6021 221.049 90.3688C220.427 93.1355 218.035 94.8159 215.821 94.3182C213.607 93.8206 212.164 91.2783 212.786 88.5117C213.408 85.745 215.799 84.0645 218.014 84.5622Z"
            fill="#212529"
            stroke="#212529"
            stroke-width="1.02376"
        />
        <path d="M270.17 173.392L279.013 161.179" stroke="#212529" stroke-width="1.0205" stroke-miterlimit="10" />
        <path d="M238.499 179.786L247.342 167.562" stroke="#212529" stroke-width="1.0205" stroke-miterlimit="10" />
        <path d="M228.181 185.765L241.245 166.549" stroke="#212529" stroke-width="1.0205" stroke-miterlimit="10" />
        <path d="M220.146 187.454L235.591 164.067" stroke="#212529" stroke-width="1.0205" stroke-miterlimit="10" />
        <path d="M216.71 181.324L229.493 161.179" stroke="#212529" stroke-width="1.0205" stroke-miterlimit="10" />
        <path d="M216.71 169.594L223.101 160.042" stroke="#212529" stroke-width="1.0205" stroke-miterlimit="10" />
        <path d="M274.591 175.287L294.757 153.551" stroke="#212529" stroke-width="1.0205" stroke-miterlimit="10" />
        <path d="M280.82 177.744L297.295 161.469" stroke="#212529" stroke-width="1.0205" stroke-miterlimit="10" />
        <path d="M288.104 177.738L297.247 169.596" stroke="#212529" stroke-width="1.0205" stroke-miterlimit="10" />
        <path
            d="M263.846 6.28157L213.161 1"
            stroke="#212529"
            stroke-width="1.0205"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M180.033 52.3867L206.591 8.89404"
            stroke="#212529"
            stroke-width="1.0205"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M73.2808 99.8887C73.2808 99.8887 35.799 74.797 22.8548 59.2507C18.2694 53.3018 18.0674 39.7665 25.2803 37.9023C47.0455 55.7477 87.5422 86.4777 96.8893 90.409L98.2623 105.125L73.2808 99.8887Z"
            stroke="#212529"
            stroke-width="3.13792"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M61.4599 95.0621C61.4599 95.0621 55.9089 95.2465 51.7117 105.398C50.1011 109.045 49.8147 113.14 50.9023 116.976C50.9023 116.976 57.8547 114.708 73.871 116.13C80.8244 116.658 87.7261 117.727 94.513 119.329C103.829 120.13 111.519 108.096 111.519 108.096C111.519 108.096 86.7615 102.626 76.0637 100.633C66.3895 98.9031 61.4599 95.0621 61.4599 95.0621Z"
            stroke="#212529"
            stroke-width="3.13792"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M52.2766 119.293C51.7171 120.567 51.4814 121.96 51.5904 123.348C51.6994 124.736 52.1499 126.075 52.9016 127.246C57.0999 133.783 62.026 135.815 62.026 135.815C75.8795 126.933 91.4916 128.529 91.4916 128.529C91.4916 128.529 101.845 124.885 99.3224 120.626L94.5513 119.351C94.5513 119.351 61.9578 111.354 52.2766 119.293Z"
            stroke="#212529"
            stroke-width="3.13792"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M117.098 76.0274C114.56 75.5128 112.081 74.7448 109.697 73.7351C107.147 72.5536 104.707 71.151 102.402 69.5436C94.9729 84.0083 89.845 102.424 87.122 116.988C88.8854 117.275 90.6898 117.19 92.4184 116.738C94.147 116.287 95.7617 115.478 97.1587 114.364C111.751 102.192 119.738 78.6284 117.098 76.0274Z"
            fill="white"
            stroke="#212529"
            stroke-width="3.13792"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M68.7035 133.147C89.041 162.047 117.549 220.063 155.06 233.067"
            stroke="#212529"
            stroke-width="3.06149"
        />
        <path
            d="M116.216 85.6338C134.222 107.64 164.731 102.639 164.731 102.639"
            stroke="#212529"
            stroke-width="3.06149"
        />
    </svg>
)

const LoginGorrionIcon = props => <SuperIcon component={LoginGorrionSVG} {...props} />

export default LoginGorrionIcon

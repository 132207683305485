import React, { useState, useContext, useEffect } from 'react'
import { WorkLogsContext } from '../../WorkLogs/context/WorkLogsContext'
import moment from 'moment'
import useConfig from '../hooks/useConfig'
import { CalendarContent, HeaderDay, CalendarContentMobile, DayWrapper } from '../../Common/components/calendar'
import WorkLogs from '../../WorkLogs/WorkLogs'
import { ROUTES } from '../../../Routes'
import OKCalendar from '../../OKSystem/OKCalendar'
import { OKSystemContext } from '../../OKSystem/contexts/OKSystemContext'
import Media from 'react-media'
import theme from '../../Common/theme'

const RenderCalendar = props => {
    const weekDays = moment.weekdays(true)
    const appConfig = useConfig()
    const isWorkLogs = props.location && props.location.pathname.includes(ROUTES.WORKLOGS)
    const { dateValue } = useContext(isWorkLogs ? WorkLogsContext : OKSystemContext)
    const [date, setDate] = useState({
        month: moment(dateValue).month(),
        year: moment(dateValue).year(),
    })
    const [daysInMonth, setDaysInMonth] = useState(moment().daysInMonth())
    const [startDaysAmount, setStartDaysAmount] = useState(moment(new Date(date.year, date.month, 1)).day())
    const [endDaysAmount, setEndDaysAmount] = useState(moment(new Date(date.year, date.month + 1, 1)).day())

    useEffect(() => {
        setDate({
            month: moment(dateValue).month(),
            year: moment(dateValue).year(),
        })
        setDaysInMonth(moment(dateValue).daysInMonth())
    }, [dateValue])

    const renderCalendar = (month, year) => {
        const daysArray = []
        let firstDay = new Date(year, month, 1)
        firstDay = moment(firstDay).day()
        let lastDay = new Date(year, month + 1, 0)
        lastDay = moment(lastDay).day()

        setStartDaysAmount(firstDay === 0 ? 7 : firstDay)
        setEndDaysAmount(lastDay === 0 ? 6 : lastDay - 1)
        const emptyStartDays = weekDays.slice(0, firstDay - 1).map(day => {
            return (
                <DayWrapper
                    key={`${day}+${dateValue}`}
                    bgColor={day === 'sobota' || day === 'niedziela'}
                    isMonday={day === 'poniedziałek'}
                />
            )
        })
        const emptyEndDays = (!lastDay ? [] : weekDays.slice(lastDay, 7)).map(day => {
            return (
                <DayWrapper
                    key={`${day}+${dateValue}`}
                    bgColor={day === 'sobota' || day === 'niedziela'}
                    isMonday={day === 'poniedziałek'}
                />
            )
        })

        for (var i = 1; i <= daysInMonth; i++) {
            const date = moment(new Date(year, month, i)).format('YYYY-MM-DD')

            daysArray.push({ dayNo: i, date: date })
        }

        let isMonthClosed = moment(appConfig.workHoursLockPeriod).isSameOrAfter(
            moment(new Date(date.year, date.month, 1)).add(1, 'month'),
        )

        return (
            <Media
                queries={{
                    small: '(max-width: 599px)',
                    medium: '(min-width: 600px) and (max-width: 1199px)',
                    large: '(min-width: 1200px)',
                }}>
                {matches => (
                    <>
                        {(matches.small || matches.medium) && (
                            <>
                                {isWorkLogs ? (
                                    <WorkLogs
                                        key={`wl-${daysArray.date}`}
                                        daysArray={daysArray}
                                        isMonthClosed={isMonthClosed}
                                        bankHolidays={appConfig.bankHolidays}
                                    />
                                ) : (
                                    <OKCalendar
                                        key={`ok-${daysArray.date}`}
                                        daysArray={daysArray}
                                        isMonthClosed={isMonthClosed}
                                        codes={props.codes}
                                        bankHolidays={appConfig.bankHolidays}
                                    />
                                )}
                            </>
                        )}
                        {matches.large && (
                            <>
                                {emptyStartDays}
                                {isWorkLogs ? (
                                    <WorkLogs
                                        key={`wl-${daysArray.date}`}
                                        daysArray={daysArray}
                                        isMonthClosed={isMonthClosed}
                                        firstDay={firstDay}
                                        bankHolidays={appConfig.bankHolidays}
                                    />
                                ) : (
                                    <OKCalendar
                                        key={`ok-${daysArray.date}`}
                                        daysArray={daysArray}
                                        isMonthClosed={isMonthClosed}
                                        bankHolidays={appConfig.bankHolidays}
                                        codes={props.codes}
                                    />
                                )}
                                {emptyEndDays}
                            </>
                        )}
                    </>
                )}
            </Media>
        )
    }

    let headerColor
    const daysRender = weekDays.map(day => {
        if (day === moment().format('dddd')) headerColor = theme.colors.blue
        else headerColor = theme.colors.primary

        return (
            <HeaderDay key={day} day={day} fontColor={headerColor}>
                {day}
            </HeaderDay>
        )
    })

    return (
        <Media
            queries={{
                small: '(max-width: 599px)',
                medium: '(min-width: 600px) and (max-width: 1199px)',
                large: '(min-width: 1200px)',
            }}>
            {matches => (
                <>
                    {(matches.small || matches.medium) && (
                        <CalendarContentMobile>{renderCalendar(date.month, date.year)}</CalendarContentMobile>
                    )}
                    {matches.large && (
                        <CalendarContent lastMonday={daysInMonth + 6 + startDaysAmount - endDaysAmount}>
                            {daysRender}
                            {renderCalendar(date.month, date.year)}
                        </CalendarContent>
                    )}
                </>
            )}
        </Media>
    )
}

export default RenderCalendar

import React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'
import theme from '../../components/Common/theme'

const SuperIcon = styled(Icon)`
    width: ${props => (props.width ? props.width : '24px')};
    height: ${props => (props.height ? props.height : '24px')};

    .cls-2 {
        fill: ${props => (props.color ? props.color : theme.colors.primary)};
    }
`

const ChevronLeftSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs></defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2-2" data-name="Layer 2">
                <rect className="cls-1" fill="none" width="24" height="24" />
                <path
                    className="cls-2"
                    d="M15,19a1,1,0,0,1-.71-.29l-6-6a1,1,0,0,1,0-1.42l6-6a1,1,0,1,1,1.42,1.42L10.41,12l5.3,5.29a1,1,0,0,1,0,1.42A1,1,0,0,1,15,19Z"
                />
            </g>
        </g>
    </svg>
)

const ChevronLeftIcon = props => <SuperIcon component={ChevronLeftSvg} {...props} />

export default ChevronLeftIcon

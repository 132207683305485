import moment from 'moment'

function countWeekdaysUntilToday(dateValue, bankHolidays) {
    let numberOfHours = 0
    const startDate = moment(dateValue).startOf('month')
    let endDate
    let datesBetween = []

    if (dateValue.isSame(new Date(), 'month')) {
        endDate = dateValue
    } else {
        endDate = moment(dateValue).endOf('month')
    }

    let day = startDate
    while (day <= endDate) {
        datesBetween.push(day)
        day = day.clone().add(1, 'd')
    }

    datesBetween.forEach(date => {
        if (
            moment(date).isoWeekday() !== 6 &&
            date.isoWeekday() !== 7 &&
            !bankHolidays?.includes(date.format('YYYY-MM-DD'))
        ) {
            numberOfHours += 8
        }
    })

    return numberOfHours
}

export default countWeekdaysUntilToday
